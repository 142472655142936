import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs'
import { HandleModalService } from '../../../services';
import { ButtonModalOptions, TextModalModel } from '../../../interfaces';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss']
})
export class InformationModalComponent implements OnInit, OnDestroy {

  public modalID: string | number |undefined;

  public iconSrc: string | undefined;
  public iconClass: string | undefined;
  public iconDimension: { width: string, height: string } | undefined;

  public title: string | undefined;
  public texts: TextModalModel[] = [];

  public actionButtonOptions: ButtonModalOptions | undefined;
  public cancelButtonOptions: ButtonModalOptions | undefined;

  public closeValue: any;
  public timeout: number = 0;
  public autoClose: Subscription | undefined;

  public modalEl: NgbModalRef | undefined;

  constructor(
    private handleModalService: HandleModalService
  ) {
  }

  ngOnInit(): void {
    if (this.timeout) {
      this.autoClose = timer(this.timeout)
        .subscribe(() => {
          this.handleModalService.closeModal(this.modalEl, { type: 'close', value: this.closeValue });
          this.clearAutoClose();
        });
    }
  }

  ngOnDestroy(): void {
    this.clearAutoClose();
  }

  public modalElement(): NgbModalRef | undefined {
    return this.modalEl;
  }

  public hasModal(): boolean {
    if (this.modalEl) return true;
    return false;
  }

  public onClose(type: string): void {
    this.handleModalService.closeModal(this.modalEl, { type: type, value: this.closeValue });
    this.clearAutoClose();
  }

  public clearAutoClose(): void {
    if (this.autoClose) {
      this.autoClose.unsubscribe();
      this.autoClose = undefined;
    }
  }

}
