import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../../../service/http-client.service';
import { Subject, map, takeUntil } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { SettingConfigurationModel } from '../../setting.interface';
import { HandleModalService, HandleTranslateService, UtilitiesService } from '../../../../shared/services';
import { LanguageType } from '../../../../shared/interfaces';

@Component({
  selector: 'app-setting-configuration',
  templateUrl: './setting-configuration.component.html',
  styleUrl: './setting-configuration.component.scss'
})
export class SettingConfigurationComponent implements OnInit {

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public setting: number | undefined;

  public alreadySetting: number | undefined;

  public isLoading: boolean = false;

  constructor(
    private httpClientService: HttpClientService,
    private handleModalService: HandleModalService,
    private handleTranslateService: HandleTranslateService,
    private utilitiesService: UtilitiesService
  ) {
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.loadingSetting();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  loadingSetting() {
    this.isLoading = true;
    this.httpClientService
      .get(`${ environment.apiURL }/api/company_setting`)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(x => x as SettingConfigurationModel)
      )
      .subscribe({
        next: (res) => {
          this.alreadySetting = res.service_provider_option;
          this.setting = res.service_provider_option;
          this.isLoading = false;
        },
        error: (err) => {
          console.error(err);
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  onSave() {
    if (this.setting !== this.alreadySetting) {
      this.handleModalService
        .confirmModal({
          title: 'NEW-TRANSLATE.COMMON.CONFIRMATION',
          texts: [ { text: 'NEW-TRANSLATE.SETTING.ARE-YOU-SURE-TO-CHANGE-THE-SETTING' } ],
          cancelButton: {
            show: true,
            id: 'cancelModalButton',
            name: 'NEW-TRANSLATE.MODAL-TEXT.NO'
          },
          confirmButton: {
            show: true,
            id: 'confirmModalButton',
            name: 'NEW-TRANSLATE.COMMON.YES'
          }
        })
        .result
        .then(
          () => {
            this.isLoading = true;
            this.httpClientService
              .put(`${ environment.apiURL }/api/company_setting/update`, { service_provider_option: this.setting })
              .pipe(
                takeUntil(this.unsubscribe$)
              )
              .subscribe({
                next: () => {
                  this.handleModalService.successModal({
                    title: 'NEW-TRANSLATE.COMMON.SUCCESSFULLY',
                    cancelButton: {
                      id: 'closeModalButton',
                      show: true,
                      name: 'NEW-TRANSLATE.COMMON.CLOSE',
                      class: 'btn-dv btn-wide'
                    }
                  });
                  this.alreadySetting = this.setting;
                  this.isLoading = false;
                },
                error: (err) => {
                  console.error(err);
                  this.isLoading = false;
                  if (!this.handleModalService.hasModal('failedModal')) {
                    const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
                    this.handleModalService.connectFailedModal(errorMessage);
                  }
                }
              });

          }
        );
    }
  }

}
