<div class="row justify-content-center mt-2 mb-1">
  <div class="col-md-6">
    <ul id="progressbar">
      <li [ngClass]="{ 'active': step === 0 }">
        {{ 'NEW-TRANSLATE.COMMON.CHOOSE-TYPE-OF-DOCUMENT' | translate }}
      </li>
      <li [ngClass]="{ 'active': step === 1 }">
        {{ 'NEW-TRANSLATE.COMMON.FILL-INFORMATION' | translate }}
      </li>
      <li [ngClass]="{ 'active': step === 2 }">
        {{ 'NEW-TRANSLATE.COMMON.CREATE-FILE' | translate }}
      </li>
    </ul>
  </div>
</div>

@if (step === 0) {
  <div class="row justify-content-center">
    <div class="col">
      <h4 class="row header">
        {{ 'NEW-TRANSLATE.KEYIN.CHOOSE-TYPE-OF-DOCUMENT-TO-FILL-INFORMATION' | translate }}
      </h4>
      <div class="row box-parent text-center px-2 px-sm-5">
        <div class="col-md-6" *ngFor="let documentType of documentTypeList">
          <div
            class="type-option"
            [ngClass]="{ 'selected-type': form.controls['doc_type'].value?.type === documentType.type }"
            (click)="onSelectDocumentType(documentType)"
          >
            <img src="../../../../assets/images/csv.png" class="me-4" alt="File Icon">
            {{ documentType.name_en }}
            <br>
            {{ documentType.name_th }}
          </div>
        </div>
      </div>
    </div>
  </div>
}
  
@if (step === 1) {
  <div class="keyin-fill-information-container row justify-content-center">
    <div class="header row justify-content-between">
      <h4 class="m-0 p-0">
        {{ 'NEW-TRANSLATE.KEYIN.FILL-INFORMATION-TO-CREATE-DOCUMENT' | translate }}
      </h4>
      <h3 class="m-0 p-0">
        {{ language === 'th' ? form.controls['doc_type'].value?.name_th : form.controls['doc_type'].value?.name_en }}
      </h3>
    </div>
    <app-keyin-section-1
      [form]="form"
      [submitted]="submitted"
      [fullTaxInvoiceOption]="fullTaxInvoiceOption"
      [replacementOption]="replacementOption"
      [cnAndDnOption]="cnAndDnOption"
      [documentType]="form.controls['doc_type'].value?.type"
    />
    <app-keyin-section-2
      [form]="form"
      [submitted]="submitted"
    />
    <app-keyin-section-3
      [form]="form"
      [submitted]="submitted"
    />
    <app-keyin-section-4
      [form]="form"
      [submitted]="submitted"
      [defaultLineItemFormGroup]="getDefaultLineItemsFormGroup()"
    />
    <app-keyin-section-5
      [form]="form"
      [submitted]="submitted"
      [endOfBillOption]="endOfBillOption"
    />
  </div>
  <div class="m-t-30 text-center">
    <button
      class="btn btn-dv btn-clean btn-wide m-r-5"
      data-testid="backStepButton"
      id="backStepButton"
      (click)="nextStep(0)"
      [disabled]="isLoading || isLoadingPreview"
      [title]="'NEW-TRANSLATE.COMMON.BACK' | translate"
    >
      <fa-icon [icon]="['fas', 'chevron-left']" class="m-r-5"></fa-icon>
      {{ 'NEW-TRANSLATE.COMMON.BACK' | translate }}
    </button>
    <button
      class="btn btn-dv btn-wide"
      data-testid="createDocumentButton"
      id="createDocumentButton"
      (click)="onGenerate()"
      [disabled]="isLoading || isLoadingPreview"
      [title]="'NEW-TRANSLATE.COMMON.CREATE' | translate"
    >
      @if (isLoadingPreview) {
        <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
      }
      {{ 'NEW-TRANSLATE.COMMON.CREATE' | translate }}
    </button>
  </div>
}

@if (step === 2) {
  <div class="box-parent success-container">
    <div class="mb-3">
      <img src="assets/images/successful_icon.png" alt="Successful Icon">
    </div>
    <h1 class="mb-4">{{ 'NEW-TRANSLATE.COMMON.SUCCESS' | translate }}!</h1>
    <div class="mb-4">
      <h5>{{ 'NEW-TRANSLATE.KEYIN.THE-DOCUMENT-NO-{{value}}-WAS-GENERATED-TOXML/PDF-A-3=file' | translate: { value: form.value['doc_no'] } }}</h5>
      <h5>{{ 'NEW-TRANSLATE.KEYIN.YOU-CAN-SEARCH-AND-VIEW-THE-DOCUMENT-DETAIL-IN-MENU-TAB' | translate }}</h5>
      <h5>{{ 'NEW-TRANSLATE.KEYIN.DOCUMENT-SEARCH' | translate }}</h5>
    </div>
    <button
      class="btn btn-dv btn-wide"
      (click)="onClose()"
      data-testid="closeButton"
      id="closeButton"
      [disabled]="isLoading"
      [title]="'NEW-TRANSLATE.COMMON.CLOSE' | translate"
    >
      {{ 'NEW-TRANSLATE.COMMON.CLOSE' | translate }}
    </button>
  </div>
}
  