import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { MessageModule } from 'primeng/message';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileListComponent } from './profile-list/profile-list.component';
import { ProfileListDetailComponent } from './profile-list-detail/profile-list-detail.component';
import { ProfilePasswordComponent } from './profile-password/profile-password.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [
    ProfileListComponent,
    ProfileListDetailComponent,
    ProfilePasswordComponent
  ],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    FormsModule,
    DropdownModule,
    NgbModule,
    CoreModule,
    TranslateModule,
    RecaptchaModule,
    FontAwesomeModule
  ],
  providers: [
    MessageModule
  ],
  exports: [
    ProfileListComponent,
    ProfileListDetailComponent
  ]
})
export class ProfileModule { }
