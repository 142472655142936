import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequireResetPasswordGuard } from '../../core/guards';
import { FeeEngineComponent } from './fee-engine.component';

export const routes: Routes = [
  {
    path: 'fee-engine',
    canActivate : [ RequireResetPasswordGuard ],
    canActivateChild : [ RequireResetPasswordGuard ],
    component: FeeEngineComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeeEngineRoutingModule { }
