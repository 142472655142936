<div class="header">
  <h3 id="pageTitle">{{'PROFILE.LIST.TITLE' | translate}}</h3>
</div>

<div class="box-filter position-relative z-2">
  <app-filter-header [loading]="loading" (submit)="search()" (clear)="clearSearch()"></app-filter-header>

  <div class="row">
    <div class="col">
      <div class="form-label-group">
        <input class="form-control" type="text" id="username" [(ngModel)]="searchUsername" placeholder="{{'PROFILE.LIST.FILTER.USERNAME' | translate}}">
        <label for="username">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</label>
      </div>
    </div>
    <div class="col">
      <div class="dropdown-label-group">
        <p-dropdown
          class="dv-dropdown"
          selectId="group"
          [options]="groups"
          [(ngModel)]="searchGroup"
          [autoDisplayFirst]="false"
          placeholder="{{'PROFILE.LIST.FILTER.LICENSE' | translate}}"
        >
        </p-dropdown>
        <label for="group">{{'PROFILE.LIST.FILTER.LICENSE' | translate}}</label>
      </div>
    </div>
  </div>
</div>

<div class="box-parent position-relative z-1">
  <app-custom-pagination
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    [totalItems]="totalItems"
    (pageChange)="pageChanged()"
    (optionChange)="optionChanged()"
  >
    <button type="button" class="btn btn-dv btn-wide" style="display:none" (click)="openModal(null, -1)">เพิ่มผู้ใช้งาน</button>
  </app-custom-pagination>

  <div class="scrollable m-t-30">
    <table class="table text-center" *ngIf="!loading" aria-describedby="pageTitle">
      <thead>
      <tr>
        <th scope="col">{{'COMMON.TABLE.ORDER' | translate}}</th>
        <th scope="col">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</th>
        <th scope="col">{{'TEXT.ROLE' | translate}}</th>
        <th scope="col">{{'TAX-B2P.STATUS' | translate}}</th>
        <th scope="col">{{'PROFILE.LIST.MODAL.MODIFY' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let res of list; let index = index" >
        <td>{{((currentPage - 1) * itemsPerPage) + index + 1}}</td>
        <td>{{res.username}}</td>
        <td>{{res.group.name}}</td>
        <td>
          <div *ngIf="res.is_active">{{'PROFILE.LIST.TABLE.STATUS.ENABLED' | translate}}</div>
          <div *ngIf="!res.is_active">{{'PROFILE.LIST.TABLE.STATUS.SUSPENDED' | translate}}</div>
        </td>
        <td>
          <fa-icon 
            [icon]="['fas', 'pen-to-square']"
            class="hover"
            (click)="openModal(res, index)"
            style="cursor: pointer;"
        ></fa-icon>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="row m-t-20" *ngIf="loading">
    <div class="col-12 text-center ">
      <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']" size="2xl"></fa-icon>
    </div>
  </div>
</div>

<app-profile-list-detail #detailModal></app-profile-list-detail>

<ng-template #addRole let-modal let-c="close">
  <div class="modal-header"style="background-color:#633ba5; color: #ffffff; ">
    <h4 class="modal-title" id="modal-basic-title">{{'TEXT.ROLE_DETAIL' | translate}}</h4>

  </div>
  <div class="modal-body" style="background: #EEF1F5;">
    <input class=" form-control m-b-10" [(ngModel)]="username" placeholder="{{'PROFILE.LIST.FILTER.USERNAME' | translate}}">

    <div class="dropdown-label-group m-b-10">
      <p-dropdown
        class="dv-dropdown "
        selectId="group"
        [options]="groups"
        [(ngModel)]="group"
        [autoDisplayFirst]="false"
        placeholder="{{'PROFILE.LIST.FILTER.LICENSE' | translate}}"
      >
      </p-dropdown>
      <label for="group">{{'PROFILE.LIST.FILTER.LICENSE' | translate}}</label>
    </div>
    <input class="form-control m-b-10" [(ngModel)]="password" placeholder="{{'PROFILE.LIST.MODAL.PASSWORD' | translate}}">
    <div *ngIf="(!username || !group || !password) && saved " style="font-size: 13px;color:#CD2400;">{{'TEXT.FILL_INPUT' | translate}}</div>
  </div>
  <div class="modal-footer-alternative">
    <button class="btn btn-dv btn-clean btn-wide me-2" (click)="c();clearAccount()">{{'TEXT.CANCEL' | translate}}</button>
    <button class="btn btn-dv btn-wide me-2" (click)="onSaveClicked()">{{'TEXT.SAVE' | translate}}</button>
  </div>
</ng-template>
