import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';

import { TaxReportRoutingModule } from './tax-report-routing.module';
import { TaxReportComponent } from './tax-report.component';
import { SharedComponentModule } from '../../shared/components/shared-component.module';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    TaxReportComponent
  ],
  imports: [
    CommonModule,
    TaxReportRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CoreModule,
    TranslateModule,
    SharedComponentModule,
    DropdownModule
  ],
  providers: [
  ]
})
export class TaxReportModule { }
