import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProfileListDetailComponent } from '../profile-list-detail/profile-list-detail.component';
import { ApiService } from '../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';
import {
  forEach as lodashForEach
} from 'lodash';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrl: './profile-list.component.scss'
})
export class ProfileListComponent implements OnInit {

  public itemsPerPage: number = 40;

  public searchUsername: any = '';
  public searchGroup: any;
  public groups: any[] = [];
  public totalItems: number | undefined;
  public currentPage: number = 1;
  public loading: boolean = false;
  public list: any[] = [];

  public group: any;
  public username: any;
  public password: any;
  public modal: NgbModalRef | undefined;
  public saved: boolean = false;

  @ViewChild('detailModal') public detailModal: ProfileListDetailComponent | undefined;

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.initialSetting();
  }

  initialSetting(): void {
    this.pageChanged();
    this.translateService.get('COMMON.ALL').subscribe((res: string) => {
      this.loadGroups();
    });
  }

  reload(): void {
    this.pageChanged();
  }

  pageChanged(): void {
    this.loadData();
  }

  optionChanged(): void {
    this.search();
  }

  openModal(data: any, index: any): void {
    this.detailModal?.open(this, data);
  }

  open(event: any, addRole: any): void {
    this.modal = this.modalService.open(addRole);
  }

  search(): any {
    this.currentPage = 1;
    this.loadData();
  }

  loadData(): any {
    const data : { [key: string]: any } = {
      page: this.currentPage,
      items_per_page: this.itemsPerPage,
      username: this.searchUsername
    };

    if (this.searchGroup != null)
      data['group'] = this.searchGroup;

    this.loading = true;
    this.apiService
      .getUsers(data)
      .subscribe({
        next: (res: any) => {
          this.list = res.results;
          this.totalItems = res.count;
          this.loading = false;
          console.log(this.list);
        },
        error: (err) => {
          this.loading = false;
        }
      });
  }

  clearSearch() {
    this.searchGroup = null;
    this.searchUsername = '';
  }

  loadGroups() {
    this.groups = [{
      'label': this.translateService.instant('COMMON.ALL'),
      'value': null
    }];

    this.apiService
      .getGroups()
      .subscribe({
        next: (res: any) => {
          lodashForEach(res.results, (group) => {
            this.groups.push({
              'label': group['name'],
              'value': group['id']
            })
          })
        }
      });
  }

  onSaveClicked() {
    this.saved = true;
    const data = {
      username: this.username,
      group: this.group,
      password: this.password,
    };
    this.apiService
    .createUser(data)
    .subscribe({
      next: (res: any) => {
        this.modal?.close();
        // this.pageChange.emit();
        this.clearAccount();
        this.loadData();
      },
      error: (err) => {
        console.log(err.status);
      }
    });
  }

  clearAccount(): void {
    this.username = '';
    this.password = '';
    this.group = '';
    this.saved = false;
  }

}
