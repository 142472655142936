import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CoreModule } from '../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';

import { FeeEngineRoutingModule } from './fee-engine-routing.module';
import { FeeEngineComponent } from './fee-engine.component';

import { SharedComponentModule } from '../../shared/components/shared-component.module';

@NgModule({
  declarations: [
    FeeEngineComponent
  ],
  imports: [
    CommonModule,
    FeeEngineRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CoreModule,
    DropdownModule,
    SharedComponentModule
  ],
  providers: [
  ]
})
export class FeeEngineModule { }
