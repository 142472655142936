import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequireResetPasswordGuard } from '../../core/guards';
import { AnnouncementComponent } from './announcement.component';

export const routes: Routes = [
  {
    path: 'announcement',
    canActivate : [ RequireResetPasswordGuard ],
    component: AnnouncementComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnnouncementRoutingModule { }
