<div
  [id]="modalID ? modalID : 'resendEmailModal'"
  [attr.data-testid]="modalID ? modalID : 'resendEmailPdfModal'"
>
  <div class="modal-header">
    <h3 class="m-0">{{'EMAIL-DASHBOARD.SEND-EMAIL' | translate}}</h3>
    <button
      aria-label="Close"
      class="btn-close close mr-1"
      data-testid="closeModalButton"
      id="closeModalButton"
      type="button"
      [disabled]="isLoading"
      (click)="onDismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form" class="form-wrapper">
      @if (step === 0) {
        <label class="mb-2" for="emailsInput">{{ 'NEW-TRANSLATE.COMMON.PLEASE-FILL-IN-THE-BUYERS-EMAIL' | translate}}:</label>
        <input
          class="form-control"
          data-testid="emailsInput"
          id="emailsInput"
          formControlName="emails"
          mask="A*"
          [patterns]="customPatterns"
          [specialCharacters]="[]"
          type="text"
          (ngModelChange)="onChangeEmail()"
        >
      }
      @if (step === 1) {
        <h6>{{ 'NEW-TRANSLATE.COMMON.PLEASE-REVIEW-THE-BUYERS-EMAIL' | translate }}</h6>
        <div class="emails-text-wrapper ms-3 mt-2">
          @for (email of (form.controls['emails'].value | splitString: ','); track email) {
            <div class="mb-2 text-break">
              {{ email }}
            </div>
          }
        </div>
        <div class="emails-error-text-wrapper mt-3 text-danger">
          {{ errorMessage }}
        </div>
      }
      @if (step === 2) {
        <h3 class="text-center">{{ 'NEW-TRANSLATE.COMMON.SENT-EMAIL-SUCCESSFULLY' | translate }}</h3>
      }
    </form>
  
  </div>
  <div class="modal-footer justify-content-between gap-3">
    @if (step === 0) {
      <button
        data-testid="closeModalButton"
        id="closeModalButton"
        class="btn btn-dv btn-danger btn-wide"
        [title]="'NEW-TRANSLATE.COMMON.CLOSE'| translate"
        (click)="onClose()"
      >{{ 'NEW-TRANSLATE.COMMON.CLOSE' | translate }}</button>
      <button
        data-testid="sendEmailModalButton"
        id="sendEmailModalButton"
        class="btn btn-dv btn-wide"
        [title]="'NEW-TRANSLATE.COMMON.SEND-EMAIL'| translate"
        [disabled]="form.invalid"
        (click)="onNextStep()"
      >{{ 'NEW-TRANSLATE.COMMON.SEND-EMAIL' | translate }}</button>
    }
    @if (step === 1) {
      <button
        data-testid="previousStepModalButton"
        id="previousStepModalButton"
        class="btn btn-dv btn-danger btn-wide"
        [title]="'NEW-TRANSLATE.COMMON.BACK'| translate"
        (click)="onPreviousStep()"
        [disabled]="isLoading"
      >{{ 'NEW-TRANSLATE.COMMON.BACK' | translate }}</button>
      <button
        data-testid="confirmModalButton"
        id="sendEmailModalButton"
        class="btn btn-dv btn-wide"
        [title]="'NEW-TRANSLATE.COMMON.CONFIRM'| translate"
        [disabled]="isLoading"
        (click)="onConfirm()"
      >
        @if (isLoading) {
          <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
        }
        {{ 'NEW-TRANSLATE.COMMON.CONFIRM' | translate }}
      </button>
    }
    @if (step === 2) {
      <button
        data-testid="okModalButton"
        id="okModalButton"
        class="btn btn-dv btn-wide mx-auto"
        [title]="'NEW-TRANSLATE.COMMON.OK'| translate"
        (click)="onClose()"
      >{{ 'NEW-TRANSLATE.COMMON.OK' | translate }}</button>
    }
  </div>
</div>