import { Injectable } from '@angular/core';
import { HttpClientService } from './http-client.service';
import { API_CONSTANT } from './api.constant';

@Injectable({
  providedIn: 'root'
})
export class FeeEngineService {

  constructor(
    private httpClientService: HttpClientService
  ) { }

  getDropDownCompanyAndYear() {
    return this.httpClientService.get(API_CONSTANT.fee_engine + 'dropdown/');
  }

  getFeeEngineList(params?: any) {
    return this.httpClientService.get(API_CONSTANT.fee_engine, params);
  }

  downloadCSV(params?: any) {
    return this.httpClientService.getBlob(API_CONSTANT.fee_engine + 'csv/', params);
  }

  downloadAllSummaryCSV(params?: any) {
    return this.httpClientService.getBlob(API_CONSTANT.fee_engine + 'summary_csv/', params);
  }

}
