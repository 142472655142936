import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements OnInit, OnDestroy {

  @Input() public placeholder: string | undefined;
  @Input() public label: string | undefined;
  @Input() public format: string | undefined;

  @Input() public maxDate: Date | undefined;
  @Input() public minDate: Date | undefined;
  @Input() public inputId: string | undefined;
  
  @Input() public isLoading: boolean = false;
  @Input() public isDisabled: boolean = false;

  @Output() onChangeDate = new EventEmitter<Date | undefined>();
  private onTouched!: Function;
  private onChanged!: Function;
  
  @Input() public date: Date | undefined;
  @Input() public invalid: boolean = false;
  @Input() public clearable: boolean = false;

  private mutationObserver: MutationObserver | undefined;

  constructor(
    private elementRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.settingObservable();
  }

  ngOnDestroy(): void {
    this.mutationObserver?.disconnect();
  }

  private settingObservable(): void {
    this.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const currentEl = this.elementRef.nativeElement as HTMLElement;
        if (currentEl.classList.contains('is-invalid')) {
          this.invalid = true;
        } else {
          this.invalid = false;
        }
      });
    });

    this.mutationObserver.observe(this.elementRef.nativeElement, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  writeValue(date: Date | undefined): void {
    this.date = date;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public changeDate(event: Date | undefined): void {
    this.onChangeDate.emit(event);
    if(this.onTouched) {
      this.onTouched();
    }
    if(this.onChanged) {
      this.onChanged(this.date);
    }
  }

  public clearDate(): void {
    this.date = undefined;
    this.onChangeDate.emit(undefined);
    if(this.onTouched) {
      this.onTouched();
    }
    if(this.onChanged) {
      this.onChanged(undefined);
    }
  }

}
