import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../service/authentication.service';
import { DefaultLanguage } from '../../shared/services';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authenticationService.getCurrentToken();
    let httpHeaders = new HttpHeaders().set('Accept-Language', DefaultLanguage());
    if (token) {
      httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + token);
    }
    const newRequest = request.clone({ headers: httpHeaders });
    return next.handle(newRequest);
  }

}
