import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HandleModalService } from '../../../../shared/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PayloadSendEmailModel } from '../../email-dashboard.interface';
import { CUSTOM_PATTERN_CHARACTOR_AND_NUMBER } from '../../../../shared/services/shared.constant';

@Component({
  selector: 'app-email-dashboard-resend-email',
  templateUrl: './email-dashboard-resend-email.component.html',
  styleUrl: './email-dashboard-resend-email.component.scss'
})
export class EmailDashboardResendEmailComponent implements OnInit {

  public modalID: string | undefined;

  public value: any | undefined;

  public step: number = 0;

  public closeValue: any;

  public modalEl: NgbModalRef | undefined;

  public errorMessage: string | undefined;

  public form: FormGroup = new FormGroup({
    tax_data_id: new FormControl(undefined, [ Validators.required ]),
    email_dashboard_id: new FormControl(undefined, [ Validators.required ]),
    emails: new FormControl(undefined, [ Validators.required ])
  });

  public onSubmitEvent: EventEmitter<PayloadSendEmailModel> = new EventEmitter<PayloadSendEmailModel>();

  public customPatterns = CUSTOM_PATTERN_CHARACTOR_AND_NUMBER;

  public isLoading: boolean = false;

  constructor(
    private handleModalService: HandleModalService
  ) {

  }

  ngOnInit(): void {
    this.initialSetting();
  }

  private initialSetting(): void {
    if (this.value) {
      const formControl = this.form.controls;
            formControl['tax_data_id'].setValue(this.value?.tax_data_id);
            formControl['email_dashboard_id'].setValue(this.value?.email_dashboard_id);
    }
  }

  public onConfirm(): void {
    const data = this.getValue();
    this.onSubmitEvent.emit(data);
  }

  public onClose(): void {
    this.handleModalService.closeModal(this.modalEl, this.closeValue);
  }

  public onDismiss(): void {
    this.handleModalService.dismissModal(this.modalEl);
  }

  public onNextStep(): void {
    this.step += 1;
  }

  public onPreviousStep(): void {
    this.step -= 1;
  }

  private getValue(): PayloadSendEmailModel {
    const formValue = this.form.value;
    return {
      tax_data_id: formValue['tax_data_id'],
      email_dashboard_id: formValue['email_dashboard_id'],
      emails: formValue['emails']
    }
  }

  public onChangeEmail(): void {
    this.errorMessage = undefined;
  }

}
