@if (message) {
  <div class="announcement-message-container mt-3 mt-md-5">
    <div class="d-flex align-items-center gap-2 mb-3">
      <fa-icon
        class="announcement-icon"
        [icon]="['fas','circle-exclamation']"
        size="2xl"
      ></fa-icon>
      <h5 class="m-0 announce-title">Announcement</h5>
    </div>
    <div class="announcement-message-wrapper">
      <div class="announcement-message">
        {{ message }}
      </div>
    </div>
  </div>
}
