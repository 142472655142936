import { Pipe, PipeTransform } from '@angular/core';
import { DocumentTypeModel } from '../interfaces';
import { MasterDataService } from '../services';

@Pipe({
  name: 'documentType'
})
export class DocumentTypePipe implements PipeTransform {

  constructor(
    private masterDataService: MasterDataService
  ) {

  }

  transform(value: string | null | undefined, language: string | null | undefined, type?: 'name' | 'id'): string | null | DocumentTypeModel {
    const documentType = this.masterDataService.getDocumentType();
    const documentTypeValue: DocumentTypeModel | undefined = documentType.find(x => x.type === value || x.name_en === value || x.name_th === value);
    if(documentTypeValue) {
      if(type === 'name') return language === 'th' ? documentTypeValue.name_th : documentTypeValue.name_en;
      if(type === 'id') return documentTypeValue.type;
      return documentTypeValue;
    } else {
      if (value) return value;
      return '-';
    }
  }

}
