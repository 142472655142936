import { Component, EventEmitter, Input, Output} from '@angular/core';
import { ITEMS_PER_PAGE_OPTIONS, PAGINATION } from '../../service/constant';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrl: './custom-pagination.component.scss'
})
export class CustomPaginationComponent {

  @Output() currentPageChange = new EventEmitter();
  @Output() itemsPerPageChange = new EventEmitter();

  @Input() totalItems: any = 0;
  @Input() maxSize: any = PAGINATION.MAX_SIZE;
  @Input() hideItemsPerPage: boolean = false;
  @Input() rightSide: boolean = false;

  @Input() public isLoading: boolean = false;

  @Output() pageChange = new EventEmitter();
  @Output() optionChange = new EventEmitter();
  @Output() optionChangeValue = new EventEmitter();

  public itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
  public currentPageValue = 1;
  public itemsPerPageValue = PAGINATION.PAGE_SIZE;
  public modal: NgbModalRef | undefined | null;

  @Input()
  get currentPage() {
    return this.currentPageValue;
  }

  @Input()
  get itemsPerPage() {
    return this.itemsPerPageValue;
  }

  constructor(
    private modalService: NgbModal
  ) {
  }

  onOptionChange(event: any) {
    this.optionChangeValue.emit(event.value);
    this.optionChange.emit();
  }

  open(modal: any) {
    this.modal = this.modalService.open(modal, {backdrop: 'static', keyboard: false});
  }

  set currentPage(value) {
    this.currentPageValue = value;
    this.currentPageChange.emit(value);
  }

  set itemsPerPage(value) {
    this.itemsPerPageValue = value;
    this.itemsPerPageChange.emit(value);
  }

}
