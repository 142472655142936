import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../core/core.module';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { MessageModule } from 'primeng/message';

import { BillingRoutingModule } from './billing-routing.module';
import { BillingListComponent } from './billing-list/billing-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    BillingListComponent
  ],
  imports: [
    CommonModule,
    BillingRoutingModule,
    DropdownModule,
    FormsModule,
    NgbModule,
    CoreModule,
    CheckboxModule,
    TranslateModule,
    FontAwesomeModule
  ],
  providers: [
    MessageModule
  ]
})
export class BillingModule { }
