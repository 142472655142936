<div class="row align-items-center mb-3">
  <div class="col">
    <h5>{{ (title ?? 'FILTER.TITLE') | translate }}</h5>
  </div>
  <div class="col-auto">
    <div>
      <button class="btn btn-dv btn-clean btn-wide me-2" (click)="clear.emit()">
        <fa-icon [icon]="['fas', 'xmark']"></fa-icon>
        {{ (clearButtonName ?? 'FILTER.CLEAR') | translate }}
      </button>
      <button class="btn btn-dv btn-submit" (click)="submit.emit()">
        <fa-icon *ngIf="loading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
        <fa-icon *ngIf="!loading" [icon]="['fas', 'magnifying-glass']"></fa-icon>
        {{ (submitButtonName ?? 'FILTER.TITLE') | translate }}
      </button>
    </div>
  </div>
</div>
