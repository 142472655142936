<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.SETTING.TITLE' | translate }}</h3>
    </div>
  </div>
</div>

<div class="setting-container">
  @if (isViewerImpAndHelp()) {
    <app-setting-report/>
  }
  @if (isIT()) {
    <app-setting-configuration/>
  }
</div>