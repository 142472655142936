import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { HttpParams } from '@angular/common/http';
import {
  forEach as lodashForEach,
  toString as lodashToString
} from 'lodash';
import { HandleTranslateService } from '../../../shared/services';

@Component({
  selector: 'app-billing-list',
  templateUrl: './billing-list.component.html',
  styleUrl: './billing-list.component.scss'
})
export class BillingListComponent implements OnInit {

  public itemsPerPage: number = 10;

  public monthListDropdown: any[] = [];
  public yearList: any[] = [];
  public list: any[] = [];
  public selectedMonth: any;
  public selectedYear: any;
  public totalItems: number | undefined;
  public currentPage: number = 1;
  public loading: boolean = false;
  public title: string | null | undefined = '';
  public selectedDuplictedData: boolean = false;
  public includeInB2POnly: boolean = false;
  public loading_excel_report: boolean = false;

  constructor(
    private apiService: ApiService,
    private handleTranslateService: HandleTranslateService
  ) {

  }

  ngOnInit(): void {

  }

  initialSetting(): void {
    this.loadTexts();
    this.populateDropdown();
    this.loadData();
    this.handleTranslateService.language?.pipe().subscribe(() => this.loadTexts());
  }

  loadTexts() {
    this.monthListDropdown = [];
    this.handleTranslateService.get('COMMON.ALL').subscribe(() => {
      this.monthListDropdown.push(
        {label: this.handleTranslateService.translate('MONTH.JANUARY'), value: 1},
        {label: this.handleTranslateService.translate('MONTH.FEBRUARY'), value: 2},
        {label: this.handleTranslateService.translate('MONTH.MARCH'), value: 3},
        {label: this.handleTranslateService.translate('MONTH.APRIL'), value: 4},
        {label: this.handleTranslateService.translate('MONTH.MAY'), value: 5},
        {label: this.handleTranslateService.translate('MONTH.JUNE'), value: 6},
        {label: this.handleTranslateService.translate('MONTH.JULY'), value: 7},
        {label: this.handleTranslateService.translate('MONTH.AUGUST'), value: 8},
        {label: this.handleTranslateService.translate('MONTH.SEPTEMBER'), value: 9},
        {label: this.handleTranslateService.translate('MONTH.OCTOBER'), value: 10},
        {label: this.handleTranslateService.translate('MONTH.NOVEMBER'), value: 11},
        {label: this.handleTranslateService.translate('MONTH.DECEMBER'), value: 12}
      );
    });
  }

  populateDropdown(): void {
    const currentDate = new Date();
    // populate year list back within 10 years
    var thisYear = currentDate.getFullYear();
    for (let i = 0; i <= 10; i++) {
      this.yearList.push({label: (thisYear - i).toString(), value: thisYear - i});
    }

    // set current month and year
    this.selectedMonth = currentDate.getMonth() + 1;
    this.selectedYear = thisYear;
  }

  // search() {
  //   if (this.currentPage != 1) {
  //     this.currentPage = 1;
  //     this.pageChanged();
  //   }
  //   else {
  //     this.pageChanged();
  //   }
  // }

  pageChanged(): void {
    const params = new HttpParams().set('page', lodashToString(this.currentPage));
    this.loadData(params);
  }

  search(): void {
    this.currentPage = 1;
    this.loadData();
  }

  loadData(params?: any): void {
    const data = {
      month: this.selectedMonth,
      year: this.selectedYear,
      duplicate_only: this.selectedDuplictedData,
      included_in_b2p_only: this.includeInB2POnly
    };

    this.title = '';
    this.loading = true;
    this.apiService
      .sign_counter(data, params)
      .subscribe({
        next: (res: any) => {
          this.list = res.results;
          this.totalItems = res.count;
          // this.title = "พบการลงลายมือชื่อดิจิทัลทั้งหมด " + this.totalItems.toLocaleString() + " ครั้ง";
          this.loading = false;
        }
      });
  }

  clearSearch(): void {
    const currentDate = new Date();

    // set current month and year
    this.selectedMonth = currentDate.getMonth() + 1;
    this.selectedYear = currentDate.getFullYear();
    this.selectedDuplictedData = false;

    this.search();
  }

  humanFileSize(size: any): string {
    let i = Math.floor( Math.log(size) / Math.log(1024) );
    return (size / Math.pow(1024, i)).toFixed(0) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
  };

  DownloadExcelReport(): void {
    this.loading_excel_report = true;

    const params = {
      month: this.selectedMonth,
      year: this.selectedYear,
      duplicate_only: this.selectedDuplictedData,
      included_in_b2p_only: this.includeInB2POnly
    };
    this.apiService
      .getBillingExcelReport(params)
      .subscribe({
        next: (res: any) => {
          let filename = 'billing_report_' + this.selectedMonth + '_' + this.selectedYear  + '.csv';
          this.apiService.createDownloadFile(res, filename);
          this.loading_excel_report = false
        },
        error: (err) => {
          this.loading_excel_report = false
        }
      });
  }

}
