import { Component } from '@angular/core';
import { AuthenticationService } from '../../service/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrl: './setting.component.scss'
})
export class SettingComponent {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.checkPermission();
  }

  private checkPermission(): void {
    if (!this.authenticationService.isSettingPermission()) {
      this.router.navigate(['/']);
    }
  }

  public isViewerImpAndHelp(): boolean {
    return this.authenticationService.isViewerImpAndHelp();
  }
  
  public isIT(): boolean {
    return this.authenticationService.isIT();
  }

}
