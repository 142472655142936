import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_CONSTANT } from './api.constant';
import { HttpClientService } from './http-client.service';
import { Observable, catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient,
    private httpClientService: HttpClientService
  ) {
  }

  getErrorMessage(error: any): string {
    if (error != null && error.error != null && error.error.message != null && error.error.message.length > 0) return error.error.message.join(',');
    return "เกิดข้อผิดพลาด โปรดลองอีกครั้ง";
  }

  createDownloadFile(blob: Blob, filename: string): void {
    const navigator = window.navigator as any;
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const element = document.createElement('a');
      document.body.appendChild(element);
      const url = window.URL.createObjectURL(blob);
      element.setAttribute('href', url);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      element.click();
      document.body.removeChild(element);
    }
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable((observer) => {
      reader.onloadend = (event: ProgressEvent) => {
        try {
          observer.error({'error': JSON.parse(reader.result as string)});
        } catch(e) {
          observer.error({'error': null});
        }

        observer.complete();
      }
    });
    reader.readAsText(err.error);
    return obs;
  }

  updatePassword(newPassword: any): Observable<any> {
    return this.httpClientService.patch(API_CONSTANT.set_password, newPassword);
  }

  uploadCSV(document: any): Observable<any> {
    return this.httpClientService.post(API_CONSTANT.upload_csv, document);
  }

  signSignature(document: any): Observable<any> {
    return this.httpClientService.postBlob(API_CONSTANT.sign_docs, document).pipe(catchError(this.parseErrorBlob));
  }

  getBackgroundJobStatus(id: any): Observable<any> {
    return this.httpClientService.get(API_CONSTANT.background_jobs  + id + '/');
  }

  getTaxDataResult(data: any, params?: any): Observable<any> {
    let url = API_CONSTANT.tax_data_upload_result;

    if (params != null)
      url += '?' + params;

    return this.httpClientService.post(url, data);
  }

  postDownloadFile(data: any): Observable<any> {
    return this.httpClientService.post(API_CONSTANT.mark_download, data);
  }

  postSummary(data: any) {
    return this.httpClientService.post(API_CONSTANT.summary_csv, data);
  }

  getSalesTaxReport(data: any) {
    return this.httpClientService.postBlob(API_CONSTANT.sales_tax_report, data).pipe(catchError(this.parseErrorBlob));
  }

  getZip2MB(data: any) {
    return this.httpClientService.postBlob(API_CONSTANT.zip_file_two_megabyte, data).pipe(catchError(this.parseErrorBlob));
  }

  getZip(data: any) {
    return this.httpClientService.getBlob(API_CONSTANT.zip_file, data).pipe(catchError(this.parseErrorBlob));
  }

  getFileFullPath(full_path: any) {
    return this.httpClientService.getBlob(full_path).pipe(catchError(this.parseErrorBlob));
  }

  postSearch(data: any, params?: any) {
    let url = API_CONSTANT.search_files;

    if (params != null)
      url += '?' + params;

    return this.httpClientService.post(url, data);
  }

  postSearch1(data: any, params?: any) {  // dummy function to make old code working
    return this.postSearch(data, params);
  }

  sign_counter(data: any, params?: any) {
    return this.httpClientService.post(API_CONSTANT.sign_counter + '?' + params, data);
  }

  getUsers(params?: any) {
    return this.httpClientService.get(API_CONSTANT.users, params);
  }

  createUser(data: any) {
    return this.httpClientService.post(API_CONSTANT.users, data);
  }

  updateUser(data: any) {
    return this.httpClientService.patch(API_CONSTANT.users + data.id + '/', data);
  }

  deleteUser(data: any) {
    return this.httpClientService.delete(API_CONSTANT.users + data.id + '/');
  }

  getGroups(params?: any) {
    return this.httpClientService.get(API_CONSTANT.groups, params);
  }

  getMe(params?: any) {
    return this.httpClientService.get(API_CONSTANT.me, params);
  }

  getDashboardMonthlyData(data: any) {
    return this.httpClientService.get(API_CONSTANT.dashboard_monthly, data);
  }

  getDashboardSignCounterMinute(data: any) {
    return this.httpClientService.get(API_CONSTANT.dashboard_sign_counter_minute, data);
  }

  getDashboardLengthData(data: any) {
    return this.httpClientService.get(API_CONSTANT.dashboard_length, data);
  }

  getDashboardActivity(data: any) {
    return this.httpClientService.get(API_CONSTANT.dashboard_activity, data);
  }

  getCompany() {
    return this.httpClientService.get(API_CONSTANT.corporates)
  }

  getCompanyGroup() {
    return this.httpClientService.get(API_CONSTANT.group_corporates)
  }

  getBranch() {
    return this.httpClientService.get(API_CONSTANT.branches)
  }

  getDocumentType() {
    return this.httpClientService.get(API_CONSTANT.document_type)
  }

  postSaleTax(data: any, params?: any) {
    let url =API_CONSTANT.sales_tax_view;

    if (params != null)
      url += '?' + params;

    return this.httpClientService.post(url, data)
  }

  postSaleZipTax(data: any, params?: any) {
    let url =API_CONSTANT.sales_zip_tax;

    if (params != null)
      url += '?' + params;

    return this.httpClientService.post(url, data)
  }

  getMissingDoc(params: any) {
    return this.httpClientService.getBlob(API_CONSTANT.export_missing_excel, params).pipe(catchError(this.parseErrorBlob));
  }

  approveSale(params: any) {
    return this.httpClientService.post(API_CONSTANT.approve_sale_tax, params)
  }

  approveUploadSale(params: any) {
    return this.httpClientService.post(API_CONSTANT.approve_upload_sale, params)
  }

  getAllDocs(params: any) {
    return this.httpClientService.getBlob(API_CONSTANT.export_all_docs, params).pipe(catchError(this.parseErrorBlob));
  }

  downloadFileFromMediaPath(url: string) {
    return this.httpClientService.getBlob(API_CONSTANT.MEDIA_PATH_URL + url).pipe(catchError(this.parseErrorBlob));
  }

  getAllZipFile(params: any) {
    return this.httpClientService.postBlob(API_CONSTANT.export_zip_file, params).pipe(catchError(this.parseErrorBlob));
  }

  getExcelReport(params: any) {
    return this.httpClientService.getBlob(API_CONSTANT.export_monthly_excel_report, params).pipe(catchError(this.parseErrorBlob));
  }

  getBuyer(params?: any) {
    return this.httpClientService.get(API_CONSTANT.buyer, params);
  }

  postB2PDocumentSummary(data: any, params?: any) {
    let url = API_CONSTANT.b2p_document_summary;

    if (params != null) {
      url += '?' + params;
    }
    return this.httpClientService.post(url, data);
  }

  postB2PDocumentSummaryDetail(data: any, params?: any) {
    let url = API_CONSTANT.b2p_document_summary_detail;

    if (params != null) {
      url += '?' + params;
    }
    return this.httpClientService.post(url, data);
  }

  downloadB2PDocumentSummaryDetailExcel(data: any, params?: any) {
    let url = API_CONSTANT.b2p_document_summary_detail_excel;

    if (params != null) {
      url += '?' + params;
    }

    return this.httpClientService.postBlob(url, data).pipe(catchError(this.parseErrorBlob));
  }

  getBillingExcelReport(params: any) {
    return this.httpClientService.getBlob(API_CONSTANT.export_billing_monthly_excel_report, params).pipe(catchError(this.parseErrorBlob));
  }

  //User management
   getUserList(params?: any) {
    return  this.httpClientService.get(API_CONSTANT.scb_users, params);
  }

  createUserManagement(params: any) {
    return  this.httpClientService.post(API_CONSTANT.scb_users, params);
  }

  createUserWithUserRequest(data: any){
    return  this.httpClientService.post(`${API_CONSTANT.scb_users}create_user_with_user_request/`, data);
  }

  getRequestUserManagement(params?: any) {
    return this.httpClientService.get(API_CONSTANT.user_request, params);
  }

  createRequestUserManagement(params: any) {
    return this.httpClientService.post(API_CONSTANT.user_request, params);
  }

  updateRequestUserManagement(data: any) {
    return this.httpClientService.post(API_CONSTANT.user_request, data);
  }

  resetPassswordRequestUserManagement(data: any) {
    return this.httpClientService.post(API_CONSTANT.user_request, data);
  }

  rejectRequestUserManagement(data: any, id: any) {
    return this.httpClientService.patch(API_CONSTANT.user_request + id + '/', data);
  }

  approveRequestUserManagement(data: any) {
    return this.httpClientService.post(API_CONSTANT.bulk_update_user_request, data);
  }

  deleteUserManagement(data: any) {
    return this.httpClientService.post(API_CONSTANT.user_request, data);
  }

  getGroupsforUserManagement(params?: any) {
    return this.httpClientService.get(API_CONSTANT.groups, params);
  }

  downloadAllFileOfTaxImport(data: any){
    return this.httpClientService.post(API_CONSTANT.send_email_tax_data , data);
  }

  //Email dashboard
  getAllTransaction(params?: any) {
    return this.httpClientService.get(API_CONSTANT.email_report, params);
  }

  downloadZipFileEmailReport(data: any) {
    return this.httpClientService.post(API_CONSTANT.get_tax_data_zip_file_url_email_report, data);
  }

  downloadZipFile(data: any, params?: any) {
    return this.httpClientService.post(API_CONSTANT.get_tax_data_zip_file_url, data, params);
  }

  clearTransaction(data: any) {
    return this.httpClientService.post(API_CONSTANT.bulk_update_email_report, data);
  }

  getBuyerTaxID(params: any) {
    return this.httpClientService.get(API_CONSTANT.tax_data, params);
  }

  resendEmail(data: any) {
    return this.httpClientService.post(API_CONSTANT.resend_email , data);
  }

  //changePassword
  passwordDetail(header: any) {
    return this.httpClient.get(API_CONSTANT.setup_user, {headers: header});
  }

  putPasswordDetail(header: any, data: any) {
    return this.httpClient.put(API_CONSTANT.setup_user, data, {headers: header});
  }

  forgetPassword(data: any) {
    return this.httpClient.post(API_CONSTANT.forget_password, data);
  }

  // company
  getUserCompany(params?: any){
    return this.httpClientService.get(API_CONSTANT.company , params);
  }

  userLogout(params?: any){
    return this.httpClientService.get(API_CONSTANT.logout , params);
  }

  createAccountNumber(data: any) {
    return this.httpClientService.post(`${API_CONSTANT.company}create_account_number/`, data);
  }

  // fee-debit-account
  getRequestAccountNumber(params?: any) {
    return this.httpClientService.get(API_CONSTANT.account_number_request, params);
  }

  approveRequestAccountNumber(data: any) {
    return this.httpClientService.post(API_CONSTANT.update_account_number_request, data);
  }

  rejectRequestAccountNumber(data: any) {
    return this.httpClientService.post(API_CONSTANT.update_account_number_request, data);
  }

  // Logout
  logout() {
    return this.httpClientService.post(API_CONSTANT.logout, null);
  }

  logDownloadAllTaxReport() {
    return this.httpClientService.get(API_CONSTANT.download_all_tax_report);
  }

  // Approval History
  getApprovalHistory(data: any): Observable<Object> {
    return this.httpClientService.get(API_CONSTANT.approve_history , data);
  }

}
