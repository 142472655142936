import { Pipe, PipeTransform } from '@angular/core';
import { UtilitiesService } from '../services';

@Pipe({
  name: 'formatByte'
})
export class FormatBytePipe implements PipeTransform {

  constructor(
    private utilitiesService: UtilitiesService
  ) {

  }

  transform(total: number, decimal: number): string {
    return this.utilitiesService.formatBytes(total, decimal);
  }

}
