<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.ANNOUNCEMENT.TITLE' | translate }}</h3>
    </div>
  </div>
</div>

<div class="announcement-container">
  <form [formGroup]="form" class="form-wrapper">
    <div class="form-group">
      <textarea
        class="form-control announcement-textarea"
        data-testid="announcementTextarea"
        formControlName="login_announce_message"
        id="announcementTextarea"
        rows="5"
        [readonly]="isLoading || isLoadingSave"
        [ngClass]="{ 'is-invalid': submitted && form.controls['login_announce_message'].errors }"
      >
      </textarea>
    </div>
    <div class="form-group">
      <div class="row g-2">
        <div class="col-12 col-sm-6 col-md-5 col-lg-4">
          <app-date-picker
            [clearable]="true"
            formControlName="login_announce_start_date"
            [isDisabled]="isLoading || isLoadingSave"
            inputId="startDateDatePicker"
            label="NEW-TRANSLATE.COMMON.START-DATE"
            [maxDate]="startDateMaxDate"
            (onChangeDate)="onChangeStartDate($event)"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-5 col-lg-4">
          <app-time-picker
            [clearable]="true"
            formControlName="login_announce_start_time"
            [hours]="0"
            [isDisabled]="isLoading || isLoadingSave  || !form.controls['login_announce_start_date'].value"
            inputId="startTimeTimePicker"
            label="NEW-TRANSLATE.COMMON.START-TIME"
            [minutes]="0"
          />
        </div>
      </div>
      <div class="row g-2 mt-1">
        <div class="col-12 col-sm-6 col-md-5 col-lg-4">
          <app-date-picker
            [clearable]="true"
            formControlName="login_announce_end_date"
            inputId="endDateDatePicker"
            [isDisabled]="isLoading || isLoadingSave"
            label="NEW-TRANSLATE.COMMON.END-DATE"
            [minDate]="endDateMinDate"
            (onChangeDate)="onChangeEndDate($event)"
          />
        </div>
        <div class="col-12 col-sm-6 col-md-5 col-lg-4">
          <app-time-picker
            [clearable]="true"
            formControlName="login_announce_end_time"
            [hours]="0"
            inputId="endTimeTimePicker"
            [isDisabled]="isLoading || isLoadingSave || !form.controls['login_announce_end_date'].value"
            label="NEW-TRANSLATE.COMMON.END-TIME"
            [minutes]="0"
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="d-flex align-items-center gap-2">
        <p-inputSwitch
          class="p-input-switch-custom"
          data-testid="toggleSwtich"
          [disabled]="isLoading || isLoadingSave"
          formControlName="use_login_announcement"
          id="toggleSwtich"
          inputId="toggleSwtich"
        ></p-inputSwitch>
        <label for="toggleSwtich">
          {{ (form.controls['use_login_announcement'].value ? 'NEW-TRANSLATE.COMMON.ENABLE' : 'NEW-TRANSLATE.COMMON.DISABLE') | translate }}
        </label>
      </div>
    </div>
    <div class="text-center">
      <button
        id="saveButton"
        data-testid="saveButton"
        type="button"
        [title]="'TEXT.SAVE' | translate"
        class="btn btn-dv btn-wide"
        [disabled]="isLoading || isLoadingSave"
        (click)="onSave()"
      >
        @if (isLoadingSave) {
          <fa-icon class="spinner-border me-1" [icon]="['fas','spinner']"></fa-icon>
        }
        {{ 'NEW-TRANSLATE.COMMON.SAVE' | translate }}
      </button>
    </div>
  </form>
</div>