import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../../../service/http-client.service';
import { environment } from '../../../../environments/environment';
import { Subject, first, map, takeUntil } from 'rxjs';
import { HandleModalService, UtilitiesService } from '../../services';
import { ResponseMessageAnnouncementModel } from '../../interfaces';

@Component({
  selector: 'app-announcement-message',
  templateUrl: './announcement-message.component.html',
  styleUrl: './announcement-message.component.scss'
})
export class AnnouncementMessageComponent implements OnInit {

  private unsubscribe$: Subject<void> = new Subject();

  public message: string | null | undefined;

  constructor(
    private httpClientService: HttpClientService,
    private handleModalService: HandleModalService,
    private utilitiesService: UtilitiesService
  ) {
  }

  ngOnInit(): void {
    this.loadAnnounment();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private loadAnnounment(): void {
    this.httpClientService
      .get(`${ environment.apiURL }/api/announcement`)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(x => x as ResponseMessageAnnouncementModel)
      )
      .subscribe({
        next: (res) => {
          this.message =  res.use_login_announcement ? res.login_announce_message : undefined;
        },
        error: (err) => {
          console.error(err);
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

}
