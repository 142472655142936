import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { RadioButtonClickEvent } from 'primeng/radiobutton';
import { Subject, takeUntil } from 'rxjs';
import { KeyinCustomColumnModel, LineItemsModel } from '../../keyin.interface';
import { KEYIN_VALIDATORS, VAT_LIST } from '../../keyin-constant';
import { CUSTOM_PATTERN_CHARACTOR_AND_NUMBER } from '../../../../shared/services/shared.constant';
import { LanguageType } from '../../../../shared/interfaces';
import { HandleTranslateService } from '../../../../shared/services';

@Component({
  selector: 'app-keyin-section-4',
  templateUrl: './keyin-section-4.component.html',
  styleUrls: [ './keyin-section-4.component.scss', '../../keyin.component.scss' ]
})
export class KeyinSection4Component implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public validators = KEYIN_VALIDATORS;

  @Input() public form!: FormGroup;
  @Input() public defaultLineItemFormGroup!: FormGroup;
  @Input() public submitted: boolean = false;
  @Input() public isLoading: boolean = false;

  public vatTypes: { label: string, value: number }[] = [
    { value: 1, label: 'KEYIN.PER-HEADER' },
    { value: 2, label: 'KEYIN.PER-ITEM' }
  ];

  public vatList: string[] = VAT_LIST;

  public datatableReadonly: boolean = false;

  public columns: KeyinCustomColumnModel[] = [
    { id: 'no', label: 'NEW-TRANSLATE.COMMON.NO', frozen: true, alignFrozen: 'left', formControlName: 'id', type: 'number', width: '120px', inputClass: 'form-control text-center', maxLength: this.validators.line_items.id.maxLength },
    { id: 'productCode', label: 'NEW-TRANSLATE.COMMON.PRODUCT-CODE', frozen: true, alignFrozen: 'left', formControlName: 'product_code', type: 'id', width: '200px', inputClass: 'form-control text-center', maxLength: this.validators.line_items.product_code.maxLength },
    { id: 'description', label: 'NEW-TRANSLATE.COMMON.DESCRIPTION', formControlName: 'description', type: 'string', width: '330px', inputClass: 'form-control', maxLength: this.validators.line_items.description.maxLength },
    { id: 'qty', label: 'NEW-TRANSLATE.COMMON.QTY', formControlName: 'qty', type: 'number', width: '120px', inputClass: 'form-control text-center' },
    { id: 'unit', label: 'NEW-TRANSLATE.COMMON.UNIT', formControlName: 'unit', type: 'string', width: '150px', inputClass: 'form-control text-center', maxLength: this.validators.line_items.unit.maxLength },
    { id: 'unitPrice', label: 'NEW-TRANSLATE.COMMON.UNIT-PRICE', formControlName: 'unit_price', type: 'currency', width: '200px', inputClass: 'form-control text-end', maxLength: this.validators.line_items.unit_price.maxLength + this.validators.line_items.unit_price.decimal },
    { id: 'discount', label: 'NEW-TRANSLATE.COMMON.DISCOUNT', formControlName: 'discount', type: 'currency', width: '200px', inputClass: 'form-control text-end', eitherOrProp: 'charge', eitherOrPropValue: '0', maxLength: this.validators.line_items.discount.maxLength + this.validators.line_items.discount.decimal },
    { id: 'charge', label: 'NEW-TRANSLATE.COMMON.CHARGE', formControlName: 'charge', type: 'currency', width: '200px', inputClass: 'form-control text-end', eitherOrProp: 'discount', eitherOrPropValue: '0', maxLength: this.validators.line_items.charge.maxLength + this.validators.line_items.charge.decimal },
    { id: 'amountExclude', label: 'NEW-TRANSLATE.COMMON.AMOUNT-EXCLUDE', formControlName: 'amount_exclude', type: 'currency', width: '200px', inputClass: 'form-control text-end', maxLength: this.validators.line_items.amount_exclude.maxLength + this.validators.line_items.amount_exclude.decimal },
    { id: 'vatPercentage', label: 'NEW-TRANSLATE.COMMON.VAT-%', formControlName: 'vat_percentage', type: 'select-list', width: '120px', inputClass: 'form-control ng-select-vat-percentage', list: this.vatList, readonly: true },
    { id: 'totalVat', label: 'NEW-TRANSLATE.COMMON.TOTAL-VAT', formControlName: 'total_vat', type: 'currency', width: '200px', inputClass: 'form-control text-end', maxLength: this.validators.line_items.total_vat.maxLength + this.validators.line_items.total_vat.decimal },
    { id: 'totalInclude', label: 'NEW-TRANSLATE.COMMON.TOTAL-INCLUDE', frozen: true, alignFrozen: 'right', formControlName: 'total_include', type: 'currency', width: '200px', inputClass: 'form-control text-end', maxLength: this.validators.line_items.total_include.maxLength + this.validators.line_items.total_include.decimal },
    { id: 'delete', label: 'NEW-TRANSLATE.COMMON.DELETE', frozen: true, alignFrozen: 'right', formControlName: 'delete', type: 'action', width: '80px' }
  ];

  public customPatterns = CUSTOM_PATTERN_CHARACTOR_AND_NUMBER;

  constructor(
    private handleTranslateService: HandleTranslateService
  ) {
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private initialSetting(): void {
    this.setDatatableReadonly();
  }

  private setDatatableReadonly(): void {
    if (this.form.controls['vat_type'].value === 1) {
      this.datatableReadonly = true;
    } else {
      this.datatableReadonly = false;
    }
  }

  public onChangeVatType(event: RadioButtonClickEvent): void {
    if (event.value === 1) {
      this.datatableReadonly = true;
      const formArray = this.form.controls['line_items'] as FormArray;
            formArray.controls.forEach(x => {
              const vatPercentage = this.form.controls['vat_percentage'].value;
              const formGroup = x as FormGroup;
                    formGroup.controls['vat_percentage'].setValue(vatPercentage);
            });
    } else {
      this.datatableReadonly = false;
    }
  }

  public onChangeVatPercentage(event: string): void {
    const formControls = this.form.controls;
    const formArray = formControls['line_items'] as FormArray;
          formArray.controls.forEach(x => {
            const formGroup = x as FormGroup;
                  formGroup.controls['vat_percentage'].setValue(event);
          });
          formControls['vat_7_percentage'].setValue(0);
          formControls['base_amount_vat_0_percentage'].setValue(0);
          formControls['base_amount_non_vat'].setValue(0);
  }

  public addLineItem(): void {
    const formArray = this.form.controls['line_items'] as FormArray;
    const formGroup = this.defaultLineItemFormGroup;
          formGroup.controls['vat_percentage'].setValue(this.form.controls['vat_percentage'].value);
          formArray.push(formGroup);
  }

  public deleteLineItem(index: number): void {
    const formArray = this.form.controls['line_items'] as FormArray;
          formArray.removeAt(index);
  }

  public onChangeEitherOr(index: number, prop: string, value: any): void {
    const formArray = this.form.controls['line_items'] as FormArray;
    const formGroup = formArray.at(index) as FormGroup;
          formGroup.controls[prop]?.setValue(value, { emitEvent: false });
  }

  public onChangeSelectList(formControlName: string, event: any): void {
    if (formControlName === 'vat_percentage') {
      this.onChangeVatTypeLineItems();
    } 
  }

  private onChangeVatTypeLineItems(): void {
    const formControls = this.form.controls;
    const formArrayValue = (formControls['line_items'] as FormArray)?.value as LineItemsModel[];
    const isVat7 = formArrayValue?.some(x => x.vat_percentage === this.vatList[0]);
    const isVat0 = formArrayValue?.some(x => x.vat_percentage === this.vatList[1]);
    const isNonVat = formArrayValue?.some(x => x.vat_percentage === this.vatList[2]);
    if (!isVat7) {
      formControls['vat_7_percentage'].setValue(0);
    }
    if (isVat0) {
      formControls['base_amount_non_vat'].setValue(0);
    }
    if (isNonVat) {
      formControls['base_amount_non_vat'].setValue(0);
    }
  }

}
