import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from 'primeng/api';

import { FeeDebitAccountRoutingModule } from './fee-debit-account-routing.module';
import { FeeDebitAccountComponent } from './fee-debit-account.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { NgbDropdownModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../../core/core.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { RecaptchaModule } from 'ng-recaptcha';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [
    FeeDebitAccountComponent
  ],
  imports: [
    CommonModule,
    FeeDebitAccountRoutingModule,
    DropdownModule,
    FormsModule,
    CalendarModule,
    NgbDropdownModule,
    TranslateModule,
    CoreModule,
    AutoCompleteModule,
    TableModule,
    NgbPaginationModule,
    CheckboxModule,
    ToastModule,
    RecaptchaModule,
    FontAwesomeModule
  ],
  providers: [
    MessageService
  ]
})
export class FeeDebitAccountModule { }
