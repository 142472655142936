import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }


  getErrorString(errors: any): string {
    if(!errors || Object.keys(errors).length === 0 || errors instanceof ProgressEvent) {
      return '';
    }
    let errorList = [];
    if (this.isArrayOrDict(errors)) {
      for(const i in errors) {
        const tempError = errors[i];
        if (this.isArrayOrDict(tempError)) {
          const recursiveResult = this.getErrorString(tempError);
          errorList.push(recursiveResult);
        } else if(this.isString(tempError)) {
          errorList.push(tempError);
        }
      }
    } else if(this.isString(errors)) {
      errorList.push(errors);
    }
    return errorList.join(' , ')
  }

  isArrayOrDict(object: any): boolean {
    return this.isArray(object) || this.isDict(object);
  }

  isString(value: any): boolean {
    return Object.prototype.toString.call(value) === "[object String]"
  }

  isArray(object: any): boolean {
    return object ? object.constructor == Array : false ;
  }

  isDict(object: any): boolean  {
    return typeof object==='object' && object!==null && !(object instanceof Array) && !(object instanceof Date);
  }

  isUrlExists(url: any): boolean {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.open('HEAD' , url , false);
    xmlHttp.send();
    return !(xmlHttp.status === 404)
  }

  getHttpParams(params_key_value_dict: any, httpParams?: HttpParams){
    let result_http_params = httpParams || new HttpParams();

    if( !this.isDict(params_key_value_dict) ){
      throw new Error('params_key_value_dict is not Dictionary')
    }

    for(let dict_key in params_key_value_dict){
      result_http_params = result_http_params.append(
        dict_key,
        params_key_value_dict[dict_key]
      )
    }

    return result_http_params;
  }

}
