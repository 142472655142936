@if (isLoading) {
  <div class="loading-container d-flex justify-content-center align-items-center">
    <fa-icon
      class="spinner-border"
      [icon]="['fas','spinner']"
      size="2xl"
    ></fa-icon>
  </div>
} @else {
  <div class="main-container">
    <app-navbar></app-navbar>
    <div class="container">
      <router-outlet></router-outlet>
    </div>
  </div>
}

