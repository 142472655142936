import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, catchError, delay, fromEvent, retry, RepeatConfig, scan, switchMap, takeUntil, tap, throwError, timer, EMPTY, mergeMap, concatMap, filter, take, count, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor, OnDestroy {

  private destroy$ = new Subject<void>();

  private retryLimit = 3;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      takeUntil(this.destroy$),
      catchError((error) => {
        if (!navigator.onLine) {
          console.warn(`[${new Date().toLocaleTimeString()}] No internet connection. Waiting for reconnection...`);
        }
        return throwError(() => error);
      }),
      retry({
        count: this.retryLimit,
        delay: (retryAttempt, retryCount) => {
          if (!navigator.onLine) {
            console.warn(`Retrying request... Attempt ${retryCount}`);
            if (document.visibilityState === 'visible') {
              return timer(this.retryLimit === retryCount ? 0 : retryCount * 1000)
            } else {
              return timer(0)
                      .pipe(
                        concatMap(() => {
                          return fromEvent(document, 'visibilitychange')
                            .pipe(
                              filter(() => document.visibilityState === 'visible'),
                              take(1),
                              concatMap(() => {
                                if (!navigator.onLine) {
                                  console.warn(`[${new Date().toLocaleTimeString()}] Still no internet connection. Retrying...`);
                                }
                                return of(retryAttempt);
                              })
                            );
                        })
                      );
            }
          }
          return throwError(() => retryAttempt);
        }  
      })
    );
  }

  

}
