import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../core/core.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedPipeModule } from '../../shared/pipes/shared-pipe.module';
import { SharedComponentModule } from '../../shared/components/shared-component.module';
import { MessageModule } from 'primeng/message';

import { DataPurgeRoutingModule } from './data-purge-routing.module';

import {
  DataPurgeListComponent,
  DataPurgeResultComponent
} from './components';

@NgModule({
  declarations: [
    DataPurgeListComponent,
    DataPurgeResultComponent
  ],
  imports: [
    CommonModule,
    DataPurgeRoutingModule,
    TranslateModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CoreModule,
    TranslateModule,
    FontAwesomeModule,
    SharedPipeModule,
    SharedComponentModule
  ],
  providers: [
    MessageModule
  ]
})
export class DataPurgeModule { }
