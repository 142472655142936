<div
  [id]="modalID ? modalID : 'handleSellerBranchModal'"
  [attr.data-testid]="modalID ? modalID : 'handleSellerBranchModal'"
  class="handle-seller-branch-modal-container"
>
  <div class="modal-body">
    <h4 class="text-center mb-4">{{ 'NEW-TRANSLATE.COMMON.NEW-BRANCH' | translate }}</h4>
    <form [formGroup]="form">
      <div class="row g-2">
        <div class="col-12 col-lg-6">
          <label class="form-label required-field" for="internalBranchCodeInput">{{ 'NEW-TRANSLATE.COMMON.INTERNAL-BRANCH-CODE' | translate }}</label>
          <input
            autocomplete="off"
            class="form-control"
            data-testid="internalBranchCodeInput"
            formControlName="internal_branch_code"
            id="internalBranchCodeInput"
            mask="A*"
            [maxlength]="validators.seller_detail.internal_branch_code.maxLength"
            [patterns]="customPatterns"            
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.CHAR-5-DIGITS' | translate"
            [readonly]="isLoading"
            type="text"
            [specialCharacters]="[]"
            [ngClass]="{ 'is-invalid': submitted && form.controls['internal_branch_code'].errors }"
          >
        </div>
        <div class="col-12 col-lg-6">
          <label class="form-label required-field" for="branchCodeInput">{{ 'NEW-TRANSLATE.COMMON.BRANCH-CODE' | translate }}</label>
          <input
            autocomplete="off"
            class="form-control"
            data-testid="branchCodeInput"
            formControlName="branch_code"
            id="branchCodeInput"
            mask="0*"
            [maxlength]="validators.seller_detail.branch_code.maxLength"
            [minlength]="validators.seller_detail.branch_code.minLength"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.ENTER-BRANCH-CODE-5-DIGITS' | translate"
            [readonly]="isLoading"
            type="text"
            [ngClass]="{ 'is-invalid': submitted && form.controls['branch_code'].errors }"
          />
        </div>
        <div class="col-12">
          <label class="form-label required-field" for="branchNameInput">{{ 'NEW-TRANSLATE.COMMON.BRANCH-NAME' | translate }}</label>
          <input
            autocomplete="off"
            class="form-control"
            data-testid="branchNameInput"
            formControlName="branch_name"
            id="branchNameInput"
            [maxlength]="validators.seller_detail.branch_name.maxLength"
            [readonly]="isLoading"
            type="text"
            [ngClass]="{ 'is-invalid': submitted && form.controls['branch_name'].errors }"
          />
        </div>
        <div class="col-12 col-lg-3">
          <label class="form-label required-field" for="houseNumberInput">{{ 'NEW-TRANSLATE.COMMON.HOUSE-NO' | translate }}</label>
          <input
            class="form-control"
            data-testid="houseNumberInput"
            formControlName="house_no"
            id="houseNumberInput"
            [maxlength]="validators.seller_detail.house_no.maxLength"
            [readonly]="isLoading"
            type="text"
            [ngClass]="{ 'is-invalid': submitted && form.controls['house_no'].errors }"
          >
        </div>
        <div class="col-12 col-lg-3">
          <label class="form-label" for="floorInput">{{ 'NEW-TRANSLATE.COMMON.FLOOR' | translate }}</label>
          <input
            class="form-control"
            data-testid="floorInput"
            formControlName="floor"
            id="floorInput"
            [maxlength]="validators.seller_detail.floor.maxLength"
            [readonly]="isLoading"
            type="text"
            [ngClass]="{ 'is-invalid': submitted && form.controls['floor'].errors }"
          >
        </div>
        <div class="col-12 col-lg-6">
          <label class="form-label" for="buildingOrApartmentInput">{{ 'NEW-TRANSLATE.COMMON.BUILDING-NAME' | translate }}</label>
          <input
            class="form-control"
            data-testid="buildingOrApartmentInput"
            formControlName="building_name"
            id="buildingOrApartmentInput"
            [maxlength]="validators.seller_detail.building_name.maxLength"
            [readonly]="isLoading"
            type="text"
            [ngClass]="{ 'is-invalid': submitted && form.controls['building_name'].errors }"
          >
        </div>
        <div class="col-12 col-lg-6">
          <label class="form-label" for="alleyInput">{{ 'NEW-TRANSLATE.COMMON.ALLEY' | translate }}</label>
          <input
            class="form-control"
            data-testid="alleyInput"
            formControlName="alley"
            id="alleyInput"
            [maxlength]="validators.seller_detail.alley.maxLength"
            [readonly]="isLoading"
            type="text"
            [ngClass]="{ 'is-invalid': submitted && form.controls['alley'].errors }"
          >
        </div>
        <div class="col-12 col-lg-6">
          <label class="form-label" for="mooNumberInput">{{ 'NEW-TRANSLATE.COMMON.VILLAGE-NO' | translate }}</label>
          <input
            class="form-control"
            data-testid="mooNumberInput"
            formControlName="moo_no"
            id="mooNumberInput"
            [maxlength]="validators.seller_detail.moo_no.maxLength"
            [readonly]="isLoading"
            type="text"
            [ngClass]="{ 'is-invalid': submitted && form.controls['moo_no'].errors }"
          >
        </div>
        <div class="col-12 col-lg-6">
          <label class="form-label" for="roadInput">{{ 'NEW-TRANSLATE.COMMON.ROAD' | translate }}</label>
          <input
            class="form-control"
            data-testid="roadInput"
            formControlName="road"
            id="roadInput"
            [maxlength]="validators.seller_detail.road.maxLength"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.ROAD-EXAMPLE' | translate"
            [readonly]="isLoading"
            type="text"
            [ngClass]="{ 'is-invalid': submitted && form.controls['road'].errors }"
          >
        </div>
        <div class="col-12 col-lg-6">
          <label class="form-label required-field" for="provinceSelect">{{ 'NEW-TRANSLATE.COMMON.PROVINCE' | translate }}</label>
          <ng-select
            class="form-control"
            [clearable]="false"
            data-testid="provinceSelect"
            formControlName="province"
            id="provinceSelect"
            [items]="provinces"
            [loading]="isLoadingProvince"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-PROVINCE' | translate"
            [readonly]="isLoading"
            [searchFn]="customSearchLocationValue"
            (change)="onChangeProvince($event)"
            [ngClass]="{ 'is-invalid': submitted && form.controls['province'].errors  }"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ language === 'th' ? item.name_th : item.name_en }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{ language === 'th' ? item.name_th : item.name_en }}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12 col-lg-6">
          <label class="form-label required-field" for="districtSelect">{{ 'NEW-TRANSLATE.COMMON.DISTRICT' | translate }}</label>
          <ng-select
            class="form-control"
            [clearable]="false"
            data-testid="districtSelect"
            formControlName="district"
            id="districtSelect"
            [items]="districts"
            [loading]="isLoadingDistrict"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-DISTRICT' | translate"
            [readonly]="!form.controls['province'].value || isLoadingDistrict || isLoading"
            [searchFn]="customSearchLocationValue"
            (change)="onChangeDistrict(form.controls['province'].value ,$event)"
            [ngClass]="{ 'is-invalid': submitted && form.controls['district'].errors  }"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ language === 'th' ? item.name_th : item.name_en }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{ language === 'th' ? item.name_th : item.name_en }}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12 col-lg-6">
          <label class="form-label required-field" for="subdistrictSelect">{{ 'NEW-TRANSLATE.COMMON.SUB-DISTRICT' | translate }}</label>
          <ng-select
            class="form-control"
            [clearable]="false"
            data-testid="subdistrictSelect"
            formControlName="subdistrict"
            id="subdistrictSelect"
            [items]="subdistricts"
            [loading]="isLoadingSubdistrict"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-SUB-DISTRICT' | translate"
            [readonly]="!form.controls['district'].value || isLoadingSubdistrict || isLoading"
            [searchFn]="customSearchLocationValue"
            (change)="onChangeSubdistrict($event)"
            [ngClass]="{ 'is-invalid': submitted && form.controls['subdistrict'].errors  }"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ language === 'th' ? item.name_th : item.name_en }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{ language === 'th' ? item.name_th : item.name_en }}
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12 col-lg-6">
          <label class="form-label required-field" for="postalCodeInput">{{ 'NEW-TRANSLATE.COMMON.POSTAL-CODE' | translate }}</label>
          <input
            class="form-control"
            data-testid="postalCodeInput"
            formControlName="zip_code"
            id="postalCodeInput"
            mask="0*"
            [maxlength]="validators.seller_detail.zip_code.maxLength"
            [minlength]="validators.seller_detail.zip_code.minLength"
            type="text"
            [readonly]="!form.controls['subdistrict'].value || isLoading"
            [ngClass]="{ 'is-invalid': submitted && form.controls['zip_code'].errors }"
          >
        </div>
        <div class="col-12 col-lg-6">
          <label class="form-label" for="telNumberInput">{{ 'NEW-TRANSLATE.COMMON.TEL' | translate }}</label>
          <input
            class="form-control"
            data-testid="telNumberInput"
            formControlName="branch_tel"
            id="telNumberInput"
            [maxlength]="validators.seller_detail.branch_tel.maxLength"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PHONE-NUMBER' | translate"
            [readonly]="isLoading"
            type="text"
            [ngClass]="{ 'is-invalid': submitted && form.controls['branch_tel'].errors }"
          >
        </div>
      </div>
    </form>
    <div class="modal-footer-wrapper">
      <button
        class="btn btn-dv btn-clean btn-wide m-r-5"
        (click)="onDismiss()"
        [disabled]="isLoading"
      >
        {{ 'NEW-TRANSLATE.COMMON.CANCEL' | translate }}
      </button>
      <button
        class="btn btn-dv btn-wide"
        [disabled]="isLoading"
        (click)="onSave()"
      >
        {{ 'NEW-TRANSLATE.COMMON.SAVE' | translate }}
      </button>
    </div>
  </div>
</div>