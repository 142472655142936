import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { StepsModule } from 'primeng/steps';
import { ngfModule } from 'angular-file';

import { DocumentUploadRoutingModule } from './document-upload-routing.module';
import { DocumentUploadComponent } from './document-upload.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedComponentModule } from '../../shared/components/shared-component.module';
import { SharedPipeModule } from '../../shared/pipes/shared-pipe.module';
import { DocumentUploadDatatableComponent } from './components/document-upload-datatable/document-upload-datatable.component';

@NgModule({
  declarations: [
    DocumentUploadComponent,
    DocumentUploadDatatableComponent
  ],
  imports: [
    CommonModule,
    DocumentUploadRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    StepsModule,
    NgbModule,
    CoreModule,
    TranslateModule,
    FontAwesomeModule,
    ngfModule,
    SharedComponentModule,
    SharedPipeModule
  ],
  exports: [
    DocumentUploadDatatableComponent
  ]
})
export class DocumentUploadModule { }
