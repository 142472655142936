export const CONSTANT = {
  encodingType: [
    {
      label: 'UTF-8',
      value: 'UTF-8',
    },
    {
      label: 'WINDOWS-874',
      value: 'WINDOWS-874'
    }
  ]
}

export const SUPPORT_FORMAT = ['application/pdf'];

export const ITEMS_PER_PAGE_OPTIONS = [
  { label: '10', value: 10 },
  { label: '40', value: 40 },
  { label: '100', value: 100 }
];

export const PAGINATION = {
  MAX_SIZE: 10,
  PAGE_SIZE: 40
}

export const MODAL_CONFIG = {
  animated: true,
  class: 'modal-dialog-centered'
};

export const CALENDAR_LOCALE = {
  TH: {
    firstDayOfWeek: 0,
    dayNames: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
    dayNamesShort: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
    dayNamesMin: ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส'],
    monthNames: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
    monthNamesShort: ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
    today: 'วันนี้',
    clear: 'ล้างข้อมูล'
  },
  EN: {
    firstDayOfWeek: 0,
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun.', 'Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.'],
    dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'],
    today: 'Today',
    clear: 'Clear'
  }
}
