import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../service/api.service';
import { AuthenticationService } from '../../service/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../service/notification.service';
import { HttpParams } from '@angular/common/http';
import {
  forEach as lodashForEach,
  uniqBy as lodashUniqBy,
  cloneDeep as lodashCloneDeep,
  uniq as lodashUniq
} from 'lodash';
import { UtilityService } from '../../service/utility.service';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { UserModel } from '../../shared/interfaces';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss'
})
export class UserManagementComponent implements OnInit {

  private unsubscribe$: Subject<void> = new Subject();

  public user: UserModel | undefined;

  public isLoading: boolean = false;
  public is_loading: boolean = false;
  public userName: any;
  public changeEmail: any;
  public password: any;
  public loading: any;
  public userRole: any;
  public roleList: any[] = [];
  public selectedUserRole: any;
  public selectedRoleList: any;
  public currentRole: any;
  public tempdata: any;
  public companyList: any[] = [];
  public selectedCompany: any;
  public alreadyGotCompanyList = false;

  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public totalItems: number = 0;
  public setPage_size: number = 10;

  public tableCol = [];
  public tableDataForMaker: any[] | null = [];
  public tableDataForChecker: any[] = [];
  public modal: NgbModalRef | undefined | null;
  public reCaptchaModal: NgbModalRef | undefined | null;

  public userRequestIDList: any[] = [];
  public rejectIDList: any;
  public rejectReason: any;
  public rejectedReasonModal: any;
  public isCheckAll: any;
  public countIsCheckAll: number = 0;

  public recaptchaSiteKey: string | undefined = environment['recaptChaSiteKey'];
  public filteredCompany: any;
  public errorMessage: string | null | undefined;

  public tempFilteredCompany: any;
  public tempSelectedUserRole: any;

  canSelectCompany: boolean = true;

  @ViewChild('confirmModal') public confirmModal: any;
  @ViewChild('recaptcha') public recaptcha: ElementRef | undefined;

  constructor(
    private modalService: NgbModal,
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private utilityService: UtilityService
  ) {
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.authenticationService.user?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.user = x);
  }

  initialSetting(): void {
    this.translateService
    .onLangChange
    .subscribe(() => {
      this.loadInput();
    });
    this.loadInput();
    this.getUserCompany()
      .then((res) => {
        this.getUserList();
        this.getRequestUserList();
      });
  }

  loadInput(): void {
    this.currentRole = this.user?.group;

    // Check is SuperMaker or Maker
    if (this.canMaker()) {
      this.canSelectCompany = false;
    }

    if (this.currentRole.includes('Viewer') === true) {
      const viewerRemoveBracket = this.currentRole.replace(/[()]/g, '');
      this.currentRole = viewerRemoveBracket.trim().replace(/ /g, '_');
    }


    this.selectedUserRole = null;
    this.userRole = [{
      label: this.translateService.instant('COMMON.ALL'),
      value: null
    }];
    const params = new HttpParams().set(this.currentRole, 'True');
    this.apiService
      .getGroupsforUserManagement(params)
      .subscribe(
      (result) => {
        lodashForEach((result as any).results, (obj) => {
          this.userRole.push({
            label: obj.name,
            value: obj.id
          });
        });
        this.userRole = lodashUniqBy(this.userRole, 'label');
      }
    );

    this.roleList = [];
    this.apiService
      .getGroupsforUserManagement(params).subscribe(
      (result) => {
        lodashForEach((result as any).results, (obj) => {
          this.roleList.push({
            label: obj.name,
            value: obj.id
          });
        });
        this.roleList = lodashUniqBy(this.roleList, 'label');
      }
    );
  }

  popRejectedReason(event: any, rejectedReason: any, data: any): void {
    this.rejectedReasonModal = [data];
    this.modal = this.modalService.open(rejectedReason, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  getUserList(): void {
    if (this.canChecker()) {
      return;
    }
    this.isLoading = true;
    let params = new HttpParams();
    params = params.append('approved_delete_request_type', 'True');
    params = params.append('page', this.currentPage.toString());
    params = params.append('page_size', this.setPage_size.toString());
    if (this.tempSelectedUserRole) {
      params = params.append('groups', this.tempSelectedUserRole);
    }
    if (this.tempFilteredCompany) {
      params = params.append('company', this.tempFilteredCompany)
    }
    this.apiService.getUserList(params).subscribe(
      (res) => {
        this.tableDataForMaker = (res as any).results;
        if (this.canMaker() || this.canViewer()) {
          this.totalItems = (res as any).count;
        }
        this.isLoading = false;
      }
    );
  }

  replaceFilterVariableWithTempFilterVariable(): void {
    this.tempFilteredCompany = lodashCloneDeep(this.filteredCompany);
    this.tempSelectedUserRole = lodashCloneDeep(this.selectedUserRole);
  }

  getRequestUserList(): void {
    if (this.canMaker() || this.canViewer()) {
      return;
    }
    this.isLoading = true;
    let params = new HttpParams();
    params = params.append('request_status', 'pending');
    params = params.append('page', this.currentPage.toString());
    params = params.append('page_size', this.setPage_size.toString());
    if (this.tempSelectedUserRole) {
      params = params.append('both_old_and_new_group', this.tempSelectedUserRole);
    }
    if (this.tempFilteredCompany) {
      params = params.append('company', this.tempFilteredCompany);
    }
    this.apiService.getRequestUserManagement(params).subscribe(
      (res) => {
        this.tableDataForChecker = (res as any).results.map((obj: any) => {
          return Object.assign(obj, {status: false});
        });
        if (this.canChecker()) {
          this.totalItems = (res as any).count;
        }
        this.isLoading = false;
      }
    );
  }

  toggleSwitch(dat: any, disable: any): void {
    this.modal = this.modalService.open(disable, {backdrop: 'static', keyboard: false});
  }

  changePermission(data: any, recaptchaToken?: any): void {
    this.is_loading = true;
    const company_index = this.companyList.findIndex(obj => [data.company].includes(obj['label']));
    let selectedCompany = null;
    if (this.companyList[company_index]) {
      selectedCompany = this.companyList[company_index]['value'];
    }
    const detail = {
      is_active: !data.is_active,
      user: data.id,
      recaptchaToken: recaptchaToken,
      company: selectedCompany,
      email: data.email,
    };
    this.apiService.updateRequestUserManagement(detail).subscribe(
      (results) => {
        this.is_loading = false;
        this.closeModalDisable();
        this.clearData();
        this.notificationService.showSuccess();
        this.getUserList();
      },
      (error) => {
        this.is_loading = false;
        this.closeModalDisable();
        this.showErrorNotification(error.error);
      }
    );
  }

  closeModalDisable() {
    if (this.modal) {
      this.modal.close();
    }
  }

  revertBack() {
    this.modal?.close();
    this.modal = null;
    this.clearRejectReason();
  }

  getUserCompany() {
    return new Promise((resolve, reject) => {
      this.apiService.getUserCompany().subscribe(
        (companyRes: any) => {
          this.companyList = companyRes.results.map(
            (obj: any) => {
              return {
                label: obj['company_name_en'],
                value: obj['id']
              }
            }
          );
          if (this.companyList.length) {
            const scb_index = this.companyList.findIndex(obj => ['scb', 'SCB'].includes(obj['label']))
            if (this.companyList[scb_index]) {
              this.filteredCompany = this.companyList[scb_index]['value']
            }
          }
          resolve(companyRes);
        },
        (error) => {
          reject(error);
          this.showErrorNotification(error.error)
        }
      );
    }).catch((error) => {
      console.log(error)
    })
  }

  validateRecaptcha() {
    this.reCaptchaModal = this.modalService.open(this.recaptcha)
  }

  recaptChaResolved(recaptchaToken: any, modalName: any, data?: any) {
    setTimeout(() => {
      this.closeRecaptchaModal();
    }, 500)
    if (modalName === 'createUser') {
      this.createUser(recaptchaToken)
    } else if (modalName === 'approve') {
      this.approvalRequest(recaptchaToken);
    } else if (modalName === 'reject') {
      this.rejectRequest(recaptchaToken);
    } else if (modalName === 'resetPassword') {
      this.changePassword(data, recaptchaToken)
    } else if (modalName === 'disable') {
      this.changePermission(data, recaptchaToken)
    } else if (modalName === 'edit') {
      this.updateUser(data, recaptchaToken)
    }

  }

  closeRecaptchaModal() {
    if (this.reCaptchaModal) {
      this.reCaptchaModal.close();
    }
  }

  validateCreateUserInput() {
    const errorMessageList = this.validateInput();
    if (errorMessageList.length > 0) {
      this.errorMessage = errorMessageList.join(' , ');
      return;
    }
    this.validateRecaptcha();
  }

  createUser(recaptchaToken: any) {
    const data = {
      group: this.selectedRoleList,
      username: this.userName,
      password: this.password,
      email: this.changeEmail,
      recaptchaToken: recaptchaToken,
      is_active: false,
      company: this.selectedCompany,
    };
    this.is_loading = true;
    this.apiService.createUserWithUserRequest(data).subscribe(
      (res) => {
        this.is_loading = false;
        this.modal?.close();
        this.notificationService.showSuccess();
        this.clearData();
        this.getUserList();
      },
      (error) => {
        this.errorMessage = this.showErrorNotification(error.error);
        this.is_loading = false;
      }
    );
  }

  validateInput() {
    let result = []
    if (!this.userName) {
      result.push('Username can not be blank')
    }
    if (!this.changeEmail) {
      result.push('Email can not be blank')
    }
    if (this.changeEmail && !this.validateEmail(this.changeEmail)) {
      result.push('Invalid Email')
    }
    if (!this.selectedRoleList) {
      result.push('Role can not be blank')
    }
    return result
  }

  validateEmail(email: string) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  resetVariable(): void {
    this.errorMessage = null;
    this.userName = null;
    this.changeEmail = null;
    this.selectedRoleList = null;
    const scb_index = this.companyList.findIndex(obj => ['scb', 'SCB'].includes(obj['label']));
    if (this.companyList[scb_index]) {
      this.selectedCompany = this.companyList[scb_index]['value'];
    }
  }

  resetEditVariable(data: any): void {
    this.errorMessage = null;
    this.userName = null;
    this.changeEmail = null;
    this.selectedRoleList = null;
    const role_index = this.roleList.findIndex(obj => [data.group.name].includes(obj['label']));
    if (this.roleList[role_index]) {
      this.selectedRoleList = this.roleList[role_index]['value'];
    }
    const company_index = this.companyList.findIndex(obj => [data.company].includes(obj['label']));
    if (this.companyList[company_index]) {
      this.selectedCompany = this.companyList[company_index]['value'];
    }
  }

  clearRejectReason(): void {
    this.rejectReason = null;
  }

  updateUser(data: any, recaptchaToken?: any): void {
    this.is_loading = true;
    const detail = {
      groups: this.selectedRoleList,
      company: this.selectedCompany,
      email: data.email,
      user: data.id,
      recaptchaToken: recaptchaToken,
    };
    this.apiService.createRequestUserManagement(detail).subscribe(
      (results) => {
        this.is_loading = false;
        this.closeModalDisable();
        this.clearData();
        this.notificationService.showSuccess();
        this.getUserList();
      },
      error => {
        this.closeModalDisable();
        this.showErrorNotification(error.error);
      }
    );
  }

  addUser(event: any, create: any): void {
    this.resetVariable();
    this.modal = this.modalService.open(create, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  editUser(event: any, edit: any, data: any) {
    this.resetEditVariable(data);
    this.modal = this.modalService.open(edit, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  openApprove(event: any, approve: any) {
    this.modal = this.modalService.open(approve, {backdrop: 'static', keyboard: false});
  }

  openReject(event: any, reject: any) {
    this.modal = this.modalService.open(reject, {backdrop: 'static', keyboard: false});
  }

  openResetPassword(event: any, resetPassword: any) {
    this.modal = this.modalService.open(resetPassword, {backdrop: 'static', keyboard: false});
  }

  rejectRequest(token: any): void {
    this.is_loading = true;
    this.rejectIDList = null;
    this.tableDataForChecker.filter(obj => {
      return obj.status ? this.rejectIDList = obj.id : null;
    });
    const rejectID = this.rejectIDList;
    const data = {
      'request_status': 'rejected',
      'rejected_reason': this.rejectReason,
      'recaptchaToken': token
    };
    this.clearRejectReason();
    this.apiService
      .rejectRequestUserManagement(data, rejectID)
      .subscribe({
        next: (res) => {
          this.tableDataForChecker = this.tableDataForChecker.filter(obj => obj.id !== rejectID);
          this.totalItems--;
          this.countIsCheckAll = 0;
          this.is_loading = false;
          this.notificationService.showSuccess();
          this.modal?.close();
        },
        error: (err) => {
          this.is_loading = false;
          this.showErrorNotification(err.error);
          this.modal?.close();
        }
      });
  }

  showErrorNotification(errorMessage: any) {
    const errorString = this.utilityService.getErrorString(errorMessage);
    this.notificationService.showErrorNotification(errorString);
    return errorString;
  }

  approvalRequest(token: any) {
    this.is_loading = true;
    this.tableDataForChecker.filter(obj => {
      return obj.status ? this.userRequestIDList.push(obj.id) : null;
    });
    const approvedList = lodashUniq(this.userRequestIDList);
    const data = {
      'user_request_id_list': lodashUniq(this.userRequestIDList),
      'request_status': 'approved',
      'recaptchaToken': token
    };
    this.apiService
      .approveRequestUserManagement(data)
      .subscribe({
        next: (res) => {
          this.tableDataForChecker = this.tableDataForChecker.filter(obj => !approvedList.includes(obj.id));
          this.totalItems = this.totalItems - lodashUniq(this.userRequestIDList).length;
          this.is_loading = false;
          this.isCheckAll = false;
          this.countIsCheckAll = 0;
          this.userRequestIDList = [];
          this.notificationService.showSuccess();
          this.modal?.close();
        },
        error: (err) => {
          this.is_loading = false;
          this.userRequestIDList = [];
          this.showErrorNotification(err.error);
          this.modal?.close();
        }
      });
  }

  canMaker(): boolean {
    return this.authenticationService.isSuperMaker() || this.authenticationService.isMaker();
  }

  canViewer(): boolean {
    return this.isViewerImpAndHelp() || this.isViewerOperation();
  }

  isSuperMaker(): boolean {
    return this.authenticationService.isSuperMaker();
  }

  isSuperChecker(): boolean {
    return this.authenticationService.isSuperChecker();
  }

  isViewerOperation(): boolean {
    return this.authenticationService.isViewerOperation();
  }

  isViewerImpAndHelp(): boolean {
    return this.authenticationService.isViewerImpAndHelp();
  }

  canChecker(): boolean {
    return this.authenticationService.isSuperChecker() || this.authenticationService.isChecker();
  }

  checkAll(event: CheckboxChangeEvent): void {
    if (!event.checked) {
      this.countIsCheckAll = 0;
    } else {
      this.countIsCheckAll = this.tableDataForChecker.length;
    }
    this.tableDataForChecker.forEach(x => {
      x.status = event.checked;
      return x;
    });
  }


  checkBox(event: CheckboxChangeEvent): void {
    this.isCheckAll = false;
    if (event.checked) {
      this.countIsCheckAll++;
    }
    if (!event.checked) {
      this.countIsCheckAll--;
    }
    if (this.tableDataForChecker.length === this.countIsCheckAll) {
      this.isCheckAll = true;
    }
  }

  deleteUser(event: any, data: any): void {
    const detail = {
      user: data.id,
      request_type: 'delete'
    };
    this.apiService.deleteUserManagement(detail).subscribe();
  }

  pageChanged(): void {
    this.isCheckAll = false;
    this.countIsCheckAll = 0;
    this.tableDataForMaker = null;
    this.getUserList();
    this.getRequestUserList();
  }

  setPageSide(event: any): void {
    this.isCheckAll = false;
    this.countIsCheckAll = 0;
    this.setPage_size = event;
    this.currentPage = 1;
    this.getUserList();
    this.getRequestUserList();
  }

  searchButtonClicked(): void {
    this.replaceFilterVariableWithTempFilterVariable();
    this.setCurrentPageTo1();
    this.getUserList();
    this.getRequestUserList();
  }

  setCurrentPageTo1(): void {
    this.currentPage = 1;
  }

  clearData(): void {
    this.filteredCompany = null;
    this.selectedUserRole = null;
    this.userName = null;
    this.password = null;
    this.changeEmail = null;
  }

  clearFilter() {
    this.clearData();
    this.replaceFilterVariableWithTempFilterVariable();
    this.getRequestUserList();
    this.getUserList();
  }

  canEdit(data: any) {
    return data.can_edit_is_active;
  }

  requestPending(data: any) {
    return data.pending_request.pending_request_count > 0;
  }

  changePassword(event: any, token: any) {
    this.is_loading = true;
    const userInformation = event;
    const company_index = this.companyList.findIndex(obj => [userInformation.company].includes(obj['label']));
    let selectedCompany = null;
    if (this.companyList[company_index]) {
      selectedCompany = this.companyList[company_index]['value'];
    }
    const data = {
      is_reset_password: true,
      user: userInformation.id,
      recaptchaToken: token,
      company: selectedCompany,
      email: userInformation.email,
    };
    this.apiService
      .resetPassswordRequestUserManagement(data)
      .subscribe({
        next: (res) => {
          this.is_loading = false;
          this.closeModalDisable();
          this.clearData();
          this.notificationService.showSuccess();
          this.getUserList();
        },
        error: (err) => {
          this.is_loading = false;
          this.closeModalDisable();
          this.showErrorNotification(err.error);
        }
      });
  }

  openConfirmModal(data: any): any {
    this.tempdata = data;
    this.confirmModal.open()
  }

}


