<div
  class="dropdown-label-group"
  [ngClass]="{ 
    'is-invalid': invalid,
    'date-picker-disabled': isDisabled || isLoading
  }"
>
  <p-calendar
    [attr.data-testid]="inputId ?? 'datePicker'"
    class="form-control date-picker-custom datepicker-style-custom"
    [dateFormat]="format ?? 'dd/mm/yy'"
    [disabled]="isLoading || isDisabled"
    inputId="inputId ?? 'datePicker'"
    [id]="inputId ?? 'datePicker'"
    [iconDisplay]="'input'"
    [(ngModel)]="date"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [placeholder]="placeholder ?? 'NEW-TRANSLATE.PLACEHOLDER.SELECT-DATE' | translate"
    [readonlyInput]="true"
    [showIcon]="true"
    [showClear]="clearable"
    (ngModelChange)="changeDate($event)"     
  >
    <ng-template
      pTemplate="inputicon"
      let-clickCallBack="clickCallBack"
    >
      <div class="date-picker-icon-custom">
        <fa-icon
          [icon]="['far', 'calendar']"
          size="xl"
          (click)="clickCallBack($event)"
        ></fa-icon>
      </div>
    </ng-template>
    <ng-template
      pTemplate="clearicon"
    >
      <div class="date-picker-clear-icon-custom">
        <fa-icon
          [icon]="['fas', 'xmark']"
          sizr="sm"
        ></fa-icon>
      </div>
    </ng-template>
  </p-calendar>
  <label [for]="inputId ?? 'datePicker'">{{ label ?? 'NEW-TRANSLATE.COMMON.DATE' | translate }}</label>
</div>