<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.RD-SUMMARY.TITLE' | translate }}</h3>
    </div>
  </div>
</div>

<div class="rd-summary-container">
  <div class="criteria-search-container">
    <app-filter-header
      [loading]="isLoading || isLoadingRows || isLoadingOther"
      (submit)="onSearch()"
      (clear)="onClear()"
    />
    <div class="criteria-search-wrapper">
      <form [formGroup]="form" class="form-wrapper">
        <div class="row g-2">
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_id"
                selectId="companySelect"
                [options]="companies"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                (onChange)="filterBranches($event.value)"
                [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="companySelect">{{ 'NEW-TRANSLATE.COMMON.COMPANY' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_branch_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_branch_id"
                selectId="branchSelect"
                [options]="branches"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [placeholder]="'NEW-TRANSLATE.COMMON.BRANCH' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="branchSelect">{{ 'NEW-TRANSLATE.COMMON.BRANCH' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['rd_document_type'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="rd_document_type"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="documentType"
                optionValue="type"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
                selectId="documentTypeSelect"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="documentTypeSelect">{{ 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE' | translate }}</label>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['month'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="month"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="months"
                optionValue="id"
                selectId="monthSelect"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="monthSelect">{{ 'NEW-TRANSLATE.COMMON.MONTH' | translate }}</label>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['year'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="year"
                selectId="yearSelect"
                [options]="years"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
              >
              </p-dropdown>
              <label for="yearSelect">{{ 'NEW-TRANSLATE.COMMON.YEAR' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['document_Status'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="document_status"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="rdStatus"
                optionValue="id"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
                selectId="rdStatusSelect"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="rdStatusSelect">{{ 'NEW-TRANSLATE.COMMON.STATUS' | translate }}</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="total-container">
    <div class="row g-2">
      <div class="col-12 col-sm-4 col-lg-2">
        <div class="total-card">
          <div class="total-label">
            <h6>{{ 'NEW-TRANSLATE.COMMON.TOTAL-OF-NUMBER' | translate }}</h6>
          </div>
          <div class="total-wrapper">
            <h3 class="text-break">{{ numberTotal | number: '0.0-0' }}</h3>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4 col-lg-2">
        <div class="total-card">
          <div class="total-label">
            <h6 class="text-break">{{ 'NEW-TRANSLATE.RD-SUMMARY.SENT-TO-SERVICE-PROVIDER' | translate }}</h6>
          </div>
          <div class="total-wrapper">
            <h3 class="text-break">{{ sentToSPTotal | number: '0.0-0' }}</h3>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4 col-lg-2">
        <div class="total-card">
          <div class="total-label">
            <h6 class="text-break">{{ 'NEW-TRANSLATE.RD-SUMMARY.RD-ACKNOWLEDGE' | translate }}</h6>
          </div>
          <div class="total-wrapper">
            <h3 class="text-break">{{ rdAcknowledgedTotal | number: '0.0-0' }}</h3>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-lg-6">
        <div class="total-status-group-card">
          <h6 class="text-break">{{ 'NEW-TRANSLATE.COMMON.STATUS' | translate }} </h6>
          <div class="row total-status-sub-group-wrapper g-2">
            <div class="col-4">
              <div class="total-status-sub-group-card">
                <div class="total-sub-group-label">
                  <h6 class="accepted-color">{{ 'NEW-TRANSLATE.COMMON.ACCEPTED' | translate }}</h6>
                </div>
                <div class="total-sub-group-wrapper">
                  <h5 class="text-break">
                    {{ acceptedTotal | number: '0.0-0'  }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="total-status-sub-group-card">
                <div class="total-sub-group-label">
                  <h6 class="rejected-color">{{ 'NEW-TRANSLATE.COMMON.REJECTED' | translate }}</h6>
                </div>
                <div class="total-sub-group-wrapper">
                  <h5 class="text-break">
                    {{ rejectedTotal | number: '0.0-0' }}
                  </h5>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="total-status-sub-group-card">
                <div class="total-sub-group-label">
                  <h6 class="pending-color">{{ 'NEW-TRANSLATE.COMMON.PENDING' | translate }}</h6>
                </div>
                <div class="total-sub-group-wrapper">
                  <h5 class="text-break">
                    {{ pendingTotal | number: '0.0-0' }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="datatables-container">
    <app-custom-pagination
      [(currentPage)]="pages"
      [(itemsPerPage)]="limitRow"
      [totalItems]="collectionSize"
      [isLoading]="isLoading || isLoadingRows || isLoadingOther"
      (pageChange)="pageChanged()"
      (optionChangeValue)="limitRowChanged($event)"
    />
    <app-datatables
      #datatables
      tableId="rdSummaryDatatable"
      [isLoadingRows]="isLoadingRows"
      [isLoadingOther]="isLoadingOther"
      [columns]="columns"
      [rows]="rows"
      [pages]="pages"
      [limitRow]="limitRow"
      [collectionSize]="collectionSize"
      (onRowActionEvent)="onRowActionEvent($event)"
    />
  </div>
</div>