import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { KeyinService } from '../../../keyin.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DistrictModel, PayloadSellerBranchModel, ProvinceModel, SubdistrictModel } from '../../../keyin.interface';
import { environment } from '../../../../../../environments/environment';
import { HttpClientService } from '../../../../../service/http-client.service';
import { Subject, map, takeUntil } from 'rxjs';
import { HandleModalService, HandleTranslateService, UtilitiesService } from '../../../../../shared/services';
import { CUSTOM_PATTERN_CHARACTOR_AND_NUMBER } from '../../../../../shared/services/shared.constant';
import { KEYIN_VALIDATORS } from '../../../keyin-constant';
import { LanguageType } from '../../../../../shared/interfaces';

@Component({
  selector: 'app-keyin-handle-seller-branch-modal',
  templateUrl: './keyin-handle-seller-branch-modal.component.html',
  styleUrls: [ './keyin-handle-seller-branch-modal.component.scss', '../../../keyin.component.scss' ]
})
export class KeyinHandleSellerBranchModalComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  public modalID: string | undefined;

  public modalEl: NgbModalRef | undefined;

  public language: LanguageType;

  public validators = KEYIN_VALIDATORS;

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    branch_name: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.seller_detail.branch_name.maxLength) ]),
    internal_branch_code: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.seller_detail.internal_branch_code.maxLength) ]),
    branch_code: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.seller_detail.branch_code.maxLength), Validators.minLength(this.validators.seller_detail.branch_code.minLength) ]),
    branch_tel: new FormControl(undefined, [ Validators.maxLength(this.validators.seller_detail.branch_tel.maxLength) ]),
    house_no: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.seller_detail.house_no.maxLength) ]),
    building_name: new FormControl(undefined, [ Validators.maxLength(this.validators.seller_detail.building_name.maxLength) ]),
    floor: new FormControl(undefined, [ Validators.maxLength(this.validators.seller_detail.floor.maxLength) ]),
    alley: new FormControl(undefined, [ Validators.maxLength(this.validators.seller_detail.alley.maxLength) ]),
    moo_no: new FormControl(undefined, [ Validators.maxLength(this.validators.seller_detail.moo_no.maxLength) ]),
    road: new FormControl(undefined, [ Validators.maxLength(this.validators.seller_detail.road.maxLength) ]),
    province: new FormControl(undefined, [ Validators.required ]),
    district: new FormControl(undefined, [ Validators.required ]),
    subdistrict: new FormControl(undefined, [ Validators.required ]),
    zip_code: new FormControl(undefined, [ Validators.required, Validators.maxLength(this.validators.seller_detail.zip_code.maxLength), Validators.minLength(this.validators.seller_detail.zip_code.minLength) ])
  });

  public provinces: ProvinceModel[] = [];
  public districts: DistrictModel[] = [];
  public subdistricts: SubdistrictModel[] = [];

  public isLoadingProvince: boolean = false;
  public isLoadingDistrict: boolean = false;
  public isLoadingSubdistrict: boolean = false;

  public customPatterns = CUSTOM_PATTERN_CHARACTOR_AND_NUMBER;

  public onSubmitEvent: EventEmitter<PayloadSellerBranchModel> = new EventEmitter<PayloadSellerBranchModel>();

  public isLoading: boolean = false;

  constructor(
    private keyinService: KeyinService,
    private httpClientService: HttpClientService,
    private handleTranslateService: HandleTranslateService,
    private handleModalService: HandleModalService,
    private utilitiesService: UtilitiesService
  ) {
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.loadProvince();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  
  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => {
      this.language = x;
      this.provinces = this.utilitiesService.sortArrayForTranslate(x, this.provinces, [ 'name_th', 'name_en' ]);
      this.districts = this.utilitiesService.sortArrayForTranslate(x, this.districts, [ 'name_th', 'name_en' ]);
      this.subdistricts = this.utilitiesService.sortArrayForTranslate(x, this.subdistricts, [ 'name_th', 'name_en' ]);
    });
  }

  private loadProvince(): void {
    this.isLoadingProvince = true;
    this.provinces = [];
    this.httpClientService
      .get(`${ environment.apiURL }/api/provinces`)
      .pipe(
        map(x => x as ProvinceModel[])
      )
      .subscribe({
        next: (res) => {
          this.provinces = this.utilitiesService.sortArrayForTranslate(this.language, res, [ 'name_th', 'name_en' ]);
          this.isLoadingProvince = false;
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  private loadDistrict(provinceId: string): void {
    this.isLoadingDistrict = true;
    this.districts = [];
    this.httpClientService
      .get(`${ environment.apiURL }/api/province/${ provinceId }/districts`)
      .pipe(
        map(x => x as DistrictModel[])
      )
      .subscribe({
        next: (res) => {
          this.districts = this.utilitiesService.sortArrayForTranslate(this.language, res, [ 'name_th', 'name_en' ]);
          this.isLoadingDistrict = false;
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  private loadSubdistrict(provinceId: string, districtId: string): void {
    this.isLoadingSubdistrict = true;
    this.subdistricts = [];
    this.httpClientService
      .get(`${ environment.apiURL }/api/province/${ provinceId }/district/${ districtId }/sub_districts`)
      .pipe(
        map(x => x as SubdistrictModel[])
      )
      .subscribe({
        next: (res) => {
          this.subdistricts = this.utilitiesService.sortArrayForTranslate(this.language, res, [ 'name_th', 'name_en' ]);
          this.isLoadingSubdistrict = false;
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  onChangeProvince(province: ProvinceModel): void {
    if (province) {
      this.loadDistrict(province.code);
      this.form?.controls['district'].reset();
      this.form?.controls['subdistrict'].reset();
      this.form?.controls['zip_code'].reset();
    }
  }

  onChangeDistrict(province: ProvinceModel, district: DistrictModel): void {
    if (province && district) {
      this.loadSubdistrict(province.code, district.code);
      this.form?.controls['subdistrict'].reset();
      this.form?.controls['zip_code'].reset();
    }
  }

  onChangeSubdistrict(subdistrict: SubdistrictModel) {
    if (subdistrict) {
      this.form?.controls['zip_code'].setValue(subdistrict.zip_code);
    }
  }

  customSearchLocationValue = (term: string, item: any) => this.keyinService.customNgSelectSearchFn(term, item, [ this.language === 'th' ? 'name_th' : 'name_en' ]);

  public onSave(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    const data = this.getValue();
    this.onSubmitEvent.emit(data);
  }

  public onClose(): void {
    this.handleModalService.closeModal(this.modalEl);
  }

  public onDismiss(): void {
    this.handleModalService.dismissModal(this.modalEl);
  }

  private getValue(): PayloadSellerBranchModel {
    const formValue = this.form.value;
    return {
      branch_name: formValue['branch_name'],
      internal_branch_code: formValue['internal_branch_code'],
      branch_code: formValue['branch_code'],
      address_1: this.keyinService.transformAddress(formValue, 'th'),
      branch_tel: formValue['branch_tel'] ?? '',
      branch_fax: '',
      country_code: 'TH',
      zip_code: formValue['zip_code']
    }
  }

}
