import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showAndHideCheckbox'
})
export class ShowAndHideCheckboxPipe implements PipeTransform {

  transform(rows: any[], keys: string | string[], conditions: boolean | string | (boolean | string)[]): any {
    const keysArray = Array.isArray(keys) ? keys : [keys];
    const conditionsArray = Array.isArray(conditions) ? conditions : [conditions];
    if (rows && rows.length > 0) {
      return rows.some(row =>
        keysArray.every(key =>
          conditionsArray.includes(row[key])
        )
      );
    }
    return false;
  }

}
