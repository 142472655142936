import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject, filter, takeUntil } from 'rxjs';
import { ConfirmModalOptionsModel, CustomNgbModalOptions, InformationModalOptionsModel, PayloadDownloadFileModel, TextModalModel } from '../interfaces';
import { ConfirmModalComponent, DownloadFileModalComponent, InformationModalComponent } from '../components/modals';

@Injectable({
  providedIn: 'root'
})
export class HandleModalService {

  private unsubscribe$: Subject<void> = new Subject();
  private modals: NgbModalRef[] = [];

  constructor(
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public startSubscriptionModals() {
    this.modalService
      .activeInstances
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((modals: NgbModalRef[]) => {
        this.modals = modals;
      })
  }

  public hasModal(id?: string): boolean {
    if (!id) return this.modals.length !== 0;
    return this.modals.findIndex(x => x.componentInstance?.modalID === id) !== -1;
  }

  public openModal(modal: {
    componentRef: any,
    id: string,
    options: CustomNgbModalOptions,
    value?: any
  }): NgbModalRef  {
    const modalEl = this.modalService.open(modal.componentRef, modal.options);
    if (modalEl) {
      const componentInstance = modalEl.componentInstance;
            componentInstance.modalID = modal.id;
            componentInstance.modalEl = modalEl;
            componentInstance.value = modal.value;
      if (modal.options.autoCloseRoutingChange) {
        this.router
          .events
          .pipe(
            filter(x => x instanceof NavigationEnd)
          )
          .subscribe(() => {
            modalEl.dismiss();
          });
      }
    }
    return modalEl;
  }

  public informationModal(options: InformationModalOptionsModel, windowClass: string, icon?: { src: string, width: string, height: string }): NgbModalRef {
    const modalOptions: CustomNgbModalOptions = {
      windowClass: windowClass,
      centered: true,
      backdrop: options.cancelButton?.show || options.actionButton?.show ? 'static' : true,
      keyboard: options.cancelButton?.show || options.actionButton?.show ? false : true,
      autoCloseRoutingChange: true
    }
    const modalEl = this.modalService.open(InformationModalComponent, modalOptions);
    const componentInstance: InformationModalComponent = modalEl.componentInstance;
          componentInstance.modalEl = modalEl;
    if (icon && navigator.onLine) {
      componentInstance.iconSrc = icon.src;
      componentInstance.iconDimension = { width: icon.width, height: icon.height };
    }
    if (options.id) {
      componentInstance.modalID = options.id;
    }
    if (options.timeout) {
      componentInstance.timeout = options.timeout;
    }
    if (options.title) {
      componentInstance.title = options.title;
    }
    if (options.texts) {
      componentInstance.texts = options.texts ?? [];
    }
    if (options.cancelButton) {
      componentInstance.cancelButtonOptions = options.cancelButton;
    }
    if (options.actionButton) {
      componentInstance.actionButtonOptions = options.actionButton;
    }
    if (options.closeValue) {
      componentInstance.closeValue = options.closeValue;
    }
    return modalEl;
  }

  public confirmModal(options: ConfirmModalOptionsModel): NgbModalRef {
    const modalEl = this.modalService.open(
                      ConfirmModalComponent,
                      {
                        windowClass: 'confirm-modal',
                        centered: true
                      }
                    );
    const componentInstance: ConfirmModalComponent = modalEl.componentInstance;
          componentInstance.modalEl = modalEl;
          componentInstance.title = options.title;
          componentInstance.texts = options.texts ?? [];
          componentInstance.closeValue = options.closeValue;
    if (options.id) {
      componentInstance.modalID = options.id;
    }
    if (options.icon && options.icon.show) {
      componentInstance.iconSrc = options.icon.src;
      componentInstance.iconWidth = options.icon.width
      componentInstance.iconHeight = options.icon.height
    }
    if (options.cancelButton) {
      componentInstance.cancelButtonOptions = options.cancelButton;
    }
    if (options.confirmButton) {
      componentInstance.confirmButtonOptions = options.confirmButton;
    }
    this.router
      .events
      .pipe(
        filter(x => x instanceof NavigationEnd)
      )
      .subscribe(() => {
        modalEl.dismiss();
      });
    return modalEl;
  }

  public downloadFileModal(downloads: PayloadDownloadFileModel[], downloadFileFromMediaPath: boolean = false): NgbModalRef {
    const modalEl = this.modalService.open(DownloadFileModalComponent ,{
      windowClass: 'download-file-modal',
      centered: true,
      backdrop: 'static',
      keyboard: false
    })
    const componentInstance: DownloadFileModalComponent = modalEl.componentInstance;
          componentInstance.modalEl = modalEl;
          componentInstance.downloads = downloads;
          componentInstance.downloadFileFromMediaPath = downloadFileFromMediaPath;
    this.router
      .events
      .pipe(
        filter(x => x instanceof NavigationEnd)
      )
      .subscribe(() => {
        modalEl.dismiss();
      });
    return modalEl;
  }

  public textModal(options: InformationModalOptionsModel) {
    const windowClass = 'text-modal';
    const modalEl = this.informationModal(options, windowClass);
    return modalEl;
  }

  public successModal(options: InformationModalOptionsModel): NgbModalRef {
    const windowClass = 'information-modal';
    const icon = {
      src: 'assets/images/success_icon_modal.png',
      width: '90px',
      height: '90px'
    };
    const modalEl = this.informationModal(options, windowClass, icon);
    return modalEl;
  }

  public errorModal(options: InformationModalOptionsModel): NgbModalRef {
    const windowClass = 'error-modal';
    const icon = {
      src: 'assets/images/error_icon_modal.png',
      width: '90px',
      height: '90px'
    };
    const modalEl = this.informationModal(options, windowClass, icon);
    return modalEl;
  }

  public connectFailedModal(texts?: TextModalModel[]): NgbModalRef {
    return this.errorModal({
      id: 'failedModal',
      title: 'NEW-TRANSLATE.COMMON.FAILED',
      texts: texts ?? [ { text: `NEW-TRANSLATE.ERROR.SORRY-SOMETHING-WHENT-WRONG` } ],
      cancelButton: {
        show: true,
        id: 'closeModalButton',
        class: 'btn-dv btn-wide',
        name: 'NEW-TRANSLATE.COMMON.CLOSE'
      }
    });
  }

  public somethingWentWrongModal(texts?: TextModalModel[]): NgbModalRef {
    return this.errorModal({
      id: 'failedModal',
      title: 'NEW-TRANSLATE.ERROR.SORRY-SOMETHING-WHENT-WRONG',
      texts: texts ?? [],
      cancelButton: {
        show: true,
        id: 'closeModalButton',
        class: 'btn-dv btn-wide',
        name: 'NEW-TRANSLATE.COMMON.CLOSE'
      }
    });
  }

  public closeModal(modalEl: NgbModalRef | undefined, value?: any): void {
    modalEl?.close(value ? value : undefined);
  }

  public dismissModal(modalEl: NgbModalRef | undefined, value?: any): void {
    modalEl?.dismiss(value ? value : undefined);
  }

}
