import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskDirective } from 'ngx-mask';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormarrayToFormgroupPipe, TransformAddressPipe, ValueInArrayPipe } from './pipes';
import { TableModule } from 'primeng/table';
import { NgxCurrencyDirective, NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from "ngx-currency";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { provideAngularSvgIcon, SvgIconComponent } from 'angular-svg-icon';

import { KeyinRoutingModule } from './keyin-routing.module';
import { KeyinComponent } from './keyin.component';
import { KeyinValidators } from './keyin.validators';
import { KeyinService } from './keyin.service';

import {
  KeyinSection1Component,
  KeyinSection2Component,
  KeyinSection3Component,
  KeyinSection4Component,
  KeyinSection5Component,
  KeyinPreviewPdfModalComponent,
  KeyinHandleSellerBranchModalComponent
} from './components';

@NgModule({
  declarations: [
    KeyinComponent,
    KeyinSection1Component,
    KeyinSection2Component,
    KeyinSection3Component,
    KeyinSection4Component,
    KeyinSection5Component,
    TransformAddressPipe,
    FormarrayToFormgroupPipe,
    ValueInArrayPipe,
    KeyinPreviewPdfModalComponent,
    KeyinHandleSellerBranchModalComponent
  ],
  imports: [
    CommonModule,
    KeyinRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    KeyinRoutingModule,
    TranslateModule,
    FontAwesomeModule,
    NgSelectModule,
    NgxMaskDirective,
    CalendarModule,
    CheckboxModule,
    RadioButtonModule,
    TableModule,
    NgbModule,
    NgxCurrencyDirective,
    NgxExtendedPdfViewerModule,
    SvgIconComponent
  ],
  exports: [
    KeyinSection1Component,
    KeyinSection2Component,
    KeyinSection3Component,
    KeyinSection4Component,
    KeyinSection5Component
  ],
  providers: [
    KeyinValidators,
    KeyinService,
    provideEnvironmentNgxCurrency({
      prefix: '',
      thousands: ',',
      decimal: '.',
      nullable: false,
      allowNegative: false,
      align: 'right',
      inputMode: NgxCurrencyInputMode.Financial
    }),
    provideAngularSvgIcon()
  ]
})
export class KeyinModule { }
