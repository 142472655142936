import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor(
  ) { }

  public setCookie(cookieName: string, value: string, minutes?: number): void {
    let expires = '';
    if (minutes) {
      const date = new Date();
      date.setTime(date.getTime() + (minutes * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = cookieName + '=' + (value || '') + expires + '; path=/';
  }

  public getCookie(cookieName: string): string | null {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      let [key, value] = cookie.split('=');
      key = key.trim();
      if (key === cookieName) {
        return value;
      }
    }
    return null;
  }

  public deleteCookie(cookieName: string): void {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

}
