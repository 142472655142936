import { Injectable } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faXmark as fasXmart,
  faMagnifyingGlass as fasMagnifyingGlass,
  faBars as fasBars,
  faSpinner as fasSpinner,
  faCircle as fasCircle,
  faEnvelope as fasEnvelope,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faEye as fasEye,
  faCheck as fasCheck,
  faXmark as fasXmark,
  faFileLines as fasFileLines,
  faPenToSquare as fasPenToSquare,
  faLock as fasLock,
  faXmarkCircle as fasXmarkCircle,
  faFileAlt as fasFileAlt,
  faChevronDown as fasChevronDown,
  faCircleMinus as fasCircleMinus,
  faPlus as fasPlus,
  faTrash as fasTrash,
  faCircleExclamation as fasCircleExclamation
} from '@fortawesome/free-solid-svg-icons';

import {
  faCalendar as farCalendar,
  faPenToSquare as farPenToSquare,
  faEye as farEye,
  faClock as farClock
} from '@fortawesome/free-regular-svg-icons'

@Injectable({
  providedIn: 'root'
})
export class FontawesomeService {

  constructor(
    private faIconLibrary: FaIconLibrary
  ) { }

  initialIcon() {
    this.faIconLibrary.addIcons(
      fasXmart,
      farCalendar,
      fasMagnifyingGlass,
      fasBars,
      fasSpinner,
      fasCircle,
      fasEnvelope,
      fasChevronLeft,
      fasChevronRight,
      fasEye,
      farPenToSquare,
      fasCheck,
      fasXmark,
      fasFileLines,
      fasPenToSquare,
      fasLock,
      fasXmarkCircle,
      fasFileAlt,
      fasChevronDown,
      fasCircleMinus,
      fasPlus,
      fasTrash,
      farEye,
      farClock,
      fasCircleExclamation
    );
  }



}
