import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ProfileListComponent } from '../profile-list/profile-list.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApiService } from '../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';
import {
  forEach as lodashForEach,
  cloneDeep as lodashCloneDeep
} from 'lodash';
import { MODAL_CONFIG } from '../../../service/constant';

@Component({
  selector: 'app-profile-list-detail',
  templateUrl: './profile-list-detail.component.html',
  styleUrl: './profile-list-detail.component.scss'
})
export class ProfileListDetailComponent implements OnInit {

  public modalRef: BsModalRef | undefined;
  public user: any = {};
  public groups: any[] = [];
  public title: string = '';
  public createMode: boolean = false;
  public errorMessage: string | null = null;
  public passwordPlaceHolder: string = '';
  public parent: ProfileListComponent | null = null;

  @ViewChild('modalTemplate') modalTemplate: TemplateRef<any> | undefined;

  constructor(
    private apiService: ApiService,
    private modalService: BsModalService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.loadGroups()
  }

  open(parent: ProfileListComponent, selectedUser: any): void {
    this.parent = parent;
    this.errorMessage = null;

    if (selectedUser != null) {
      this.user = lodashCloneDeep(selectedUser);
      this.user.group = this.user.group.id;
      delete this.user.status;

      this.createMode = false;
      this.title = this.translateService.instant('PROFILE.LIST.MODAL.TITLE') + ' : ' + this.user.username;
      this.passwordPlaceHolder = '(' + this.translateService.instant('PROFILE.LIST.MODAL.PASSWORD-PLACEHOLDER') + ')';
    }
    else {
      this.user = {"group": this.groups[0]['value']};
      this.createMode = true;
      this.title = this.translateService.instant('PROFILE.LIST.MODAL.ADD-USER');
      this.passwordPlaceHolder = this.translateService.instant('PROFILE.LIST.MODAL.PASSWORD');
    }

    if (this.modalTemplate) {
      this.modalRef = this.modalService.show(this.modalTemplate, MODAL_CONFIG);
    }

  }

  submit(): void {
    let errorField = [];
    this.errorMessage = null;

    if (this.user.username == null || this.user.username.length == 0)
      errorField.push("ชื่อผู้ใช้งาน");

    if (this.createMode && (this.user.password == null || this.user.password.length == 0))
      errorField.push("รหัสผ่าน");

    if (errorField.length > 0) {
      this.errorMessage = "โปรดกรอก" + errorField.join(',') + 'ให้ถูกต้อง';
      return;
    }

    let request = null;

    if (this.createMode)
      request = this.apiService.createUser(this.user);
    else
      request = this.apiService.updateUser(this.user);

    request
      .subscribe({
        next: (res) => {
          this.parent?.reload();
          this.modalRef?.hide();
        },
        error: (err) => {
          this.errorMessage = this.apiService.getErrorMessage(err);
          console.log('aa')
        }
      });
  }

  disableUser(): void {
    let request = this.apiService.deleteUser(this.user);
    request
      .subscribe({
        next: (res) => {
          this.parent?.reload();
          this.modalRef?.hide();
        },
        error: (err) => {
          this.errorMessage = this.apiService.getErrorMessage(err);
          console.log('aa')
        }
      });
  }

  enableUser() {
    this.user.is_active = true;
    let request = this.apiService.updateUser(this.user);

    request
      .subscribe({
        next: (res) => {
          this.parent?.reload();
          this.modalRef?.hide();
        },
        error: (err) => {
          this.errorMessage = this.apiService.getErrorMessage(err);
        }
      });
  }

  loadGroups(): void {
    this.groups = [{
      'label': this.translateService.instant('COMMON.ALL'),
      'value': null
    }];

    this.apiService
      .getGroups()
      .subscribe({
        next: (res: any) => {
          lodashForEach(res.results, (group) => {
            this.groups.push({
              'label': group['name'],
              'value': group['id']
            })
          })
        }
      });
  }

}
