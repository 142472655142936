import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HandleModalService } from '../../../../../shared/services';

@Component({
  selector: 'app-keyin-preview-pdf-modal',
  templateUrl: './keyin-preview-pdf-modal.component.html',
  styleUrl: './keyin-preview-pdf-modal.component.scss'
})
export class KeyinPreviewPdfModalComponent implements OnInit {

  public modalID: string | undefined;

  public value: { pdf: Blob | string } | undefined;

  public pdfSrc: Blob | string | undefined;

  public closeValue: any;

  public modalEl: NgbModalRef | undefined;

  @Input() public renderText: boolean = false;

  public onSubmitEvent: EventEmitter<void> = new EventEmitter<void>();

  public isLoading: boolean = false;

  constructor(
   private handleModalService: HandleModalService
  ) {

  }

  ngOnInit(): void {
    if (this.value) {
      this.pdfSrc = this.value.pdf;
    }
  }

  public onConfirm(): void {
    this.onSubmitEvent.emit();
  }

  public onClose(): void {
    this.handleModalService.closeModal(this.modalEl, this.closeValue);
  }

  public onDismiss(): void {
    this.handleModalService.dismissModal(this.modalEl);
  }

}
