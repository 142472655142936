import { Inject, Injectable } from '@angular/core';
import { BroadcastChannelModel } from '../../interfaces';
import { Observable, Subject, fromEventPattern, map, takeUntil } from 'rxjs';
import { BROADCAST_CHANNEL } from './boardcast-init';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private messageSubject = new Subject<BroadcastChannelModel>();

  constructor(
    @Inject(BROADCAST_CHANNEL) private channel: BroadcastChannel
  ) {
    this.channel.onmessage = (event) => {
      this.messageSubject.next(event.data);
    };
  }

  public sendMessage(message: BroadcastChannelModel): void {
    this.channel.postMessage(message);
  }

  public listen(): Observable<BroadcastChannelModel> {
    return this.messageSubject.asObservable();
  }

  public closeChannel(): void {
    this.channel.close();
  }

}
