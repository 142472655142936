import {environment} from '../../environments/environment';
import * as moment from "moment";
import Base = moment.unitOfTime.Base;

const BASE_URL = environment.apiURL;

export const API_CONSTANT = {
  'BASE_URL': BASE_URL,
  MEDIA_PATH_URL: BASE_URL + '/media/',
  request_otp: BASE_URL + '/api/auth/request-otp/',
  validate_otp: BASE_URL + '/api/auth/validate-otp/',
  login: BASE_URL + '/api-token-auth/',
  upload_csv: BASE_URL + '/api/tax_imports/',
  sign_docs: BASE_URL + '/api/sign_doc/',
  digital_signature: BASE_URL + '/api/certificate/',
  tax_data_upload_result: BASE_URL + '/api/tax_data_upload_result/',
  background_jobs: BASE_URL + '/api/background_jobs/',
  summary_csv: BASE_URL + '/api/summary_csv/',
  zip_file: BASE_URL + '/api/zip_file/',
  sales_tax_report: BASE_URL + '/api/sales_tax_report/',
  zip_file_two_megabyte: BASE_URL + '/api/zip_file_two_megabyte/',
  search_files: BASE_URL + '/api/search_files/',
  sign_counter: BASE_URL + '/api/sign_counter/',
  mark_download: BASE_URL + '/api/mark_download/',
  dashboard_monthly: BASE_URL + '/api/dashboard_monthly/',
  dashboard_sign_counter_minute: BASE_URL + '/api/dashboard_sign_counter_minute/',
  dashboard_length: BASE_URL + '/api/dashboard_length/',
  dashboard_activity: BASE_URL + '/api/dashboard_activity/',
  set_password: BASE_URL + '/api/set_password/',
  users: BASE_URL + '/api/users/',
  scb_users: BASE_URL + '/api/scb_users/',
  user_request: BASE_URL + '/api/user_request/',
  approve_history: BASE_URL + '/api/approve_history/',
  bulk_update_user_request: BASE_URL + '/api/user_request/bulk_update_user_request/',
  groups: BASE_URL + '/api/groups/',
  me: BASE_URL + '/api/me/',
  corporates: BASE_URL + '/api/corporates/',
  group_corporates: BASE_URL + '/api/group-corporates/',
  branches: BASE_URL + '/api/corporate-branches/',
  document_type: BASE_URL + '/api/documents_type/',
  sales_tax_view: BASE_URL + '/api/sales_tax_views/',
  export_missing_excel: BASE_URL + '/api/export_excel_missing_docs/',
  approve_sale_tax: BASE_URL + '/api/approved_sales_tax/',
  export_all_docs: BASE_URL + '/api/export_excel_all_docs/',
  export_monthly_excel_report: BASE_URL + '/api/export_excel_monthly_report/',
  sales_zip_tax: BASE_URL + '/api/sales_tax_zip_views/',
  approve_upload_sale: BASE_URL + '/api/approved_uploaded_sales_tax_zip/',
  export_zip_file: BASE_URL + '/api/zip_file_two_megabyte/',
  buyer: BASE_URL + '/api/buyer/',
  b2p_document_summary: BASE_URL + '/api/b2p_document_summary/',
  b2p_document_summary_detail: BASE_URL + '/api/b2p_document_summary_detail/',
  b2p_document_summary_detail_excel: BASE_URL + '/api/b2p_document_summary_detail_excel/',
  export_billing_monthly_excel_report: BASE_URL + '/api/export_sign_counter_excel_monthly_report/',
  patch_tax_import: BASE_URL + '/api/patch_tax_import/',
  email_report: BASE_URL + '/api/email_report/',
  send_email_tax_data: BASE_URL + '/api/send_email_tax_data/',
  bulk_update_email_report: BASE_URL + '/api/email_report/bulk_update_email_report/',
  resend_email: BASE_URL + '/api/resend_email/',
  get_tax_data_zip_file_url: BASE_URL + '/api/get_tax_data_zip_file_url/',
  get_tax_data_zip_file_url_email_report: BASE_URL + '/api/get_tax_data_zip_file_url_email_report/',
  tax_data: BASE_URL + '/api/tax_data/',
  setup_user: BASE_URL + '/api/setup_user/',
  tax_import_transaction: BASE_URL + '/api/tax_import_transaction/',
  download_company_excel_transaction: BASE_URL + '/api/download_company_excel_transaction/',
  forget_password: BASE_URL + '/api/forget_password/',
  company: BASE_URL + '/api/company/',
  logout: BASE_URL + '/api/user_logout/',
  get_login_attempted_left: BASE_URL + '/api/get_user_login_attempted_left/',
  get_max_failed_login_attempted: BASE_URL + '/api/get_max_failed_login_attempted/',
  logging_login: BASE_URL + '/logging_login/',
  account_number_request: BASE_URL + '/api/account_number_request/',
  update_account_number_request: BASE_URL + '/api/account_number_request/update_account_number_request/',
  fee_engine: BASE_URL + '/api/fee_engine/',
  download_all_tax_report: BASE_URL + '/api/download_all_tax_report/',
  get_approval_history: BASE_URL + '/api/user_request/approve_history/'
};
