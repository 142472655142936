@if (form) {
  <div class="keyin-section-2-container box-parent section-box mb-2">
    <h5 class="section-title">{{ 'NEW-TRANSLATE.COMMON.SELLER-DETAIL' | translate }}</h5>
    <form [formGroup]="form" class="form-wrapper">
      <div class="row g-2 mb-3">
        <div class="col-12 col-lg-3 col-md-4 col-sm-6">
          <label class="mb-2 required-field" for="sellerBranchSelect">{{
            "NEW-TRANSLATE.COMMON.SELLER-BRANCH" | translate
          }}</label>
          <ng-select
            #sellerSelectEl
            autocomplete="off"
            class="form-control"
            [clearable]="false"
            data-testid="sellerBranchSelect"
            formControlName="seller_branch"
            id="sellerBranchSelect"
            [items]="sellerBranchs"
            [readonly]="isLoading || isLoadingSellerBranch"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-BRANCH' | translate"
            [searchFn]="customSearchSellerBranchValue"
            [ngClass]="{ 'is-invalid': submitted && form.controls['seller_branch'].errors }"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.internal_branch_code + '/' + item.branch_name + '/' + item.branch_code }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{ item.internal_branch_code + '/' + item.branch_name + '/' + item.branch_code }}
            </ng-template>
            <ng-template ng-footer-tmp>
              <button
                (click)="addSellerBranch(sellerSelectEl)"
                class="btn btn-add-new-seller-branch"
              ><fa-icon [icon]="['fas', 'plus']"></fa-icon> {{ 'NEW-TRANSLATE.COMMON.ADD-NEW' | translate }}</button>
            </ng-template>
          </ng-select>
        </div>
      </div>
      @if (form.controls['seller_branch'].value) {
        <div class="seller-branch-address-area">
          <p>{{ form.controls['seller_branch'].value['company_name'] }} {{ form.controls['seller_branch'].value['branch_name'] }}</p>
          <p>{{ form.controls['seller_branch'].value['address_1'] }}</p>
          <p>@if (form.controls['seller_branch'].value['branch_tel']) { โทร. {{ form.controls['seller_branch'].value['branch_tel'] }} } เลขประจำตัวผู้เสียภาษี {{ form.controls['seller_branch'].value['company_tax_id'] }} {{ form.controls['seller_branch'].value['branch_code'] }}</p>
        </div>
      }
    </form>
  </div>
}