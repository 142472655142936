<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.DOCUMENT-SEARCH.TITLE' | translate }}</h3>
    </div>
  </div>
</div>

<div class="submit-to-rd-container">
  <div class="criteria-search-container">
    <app-filter-header
      [loading]="isLoading || isLoadingRows || isLoadingOther"
      (submit)="onSearch()"
      (clear)="onClear()"
    />
    <div class="criteria-search-wrapper">
      <form [formGroup]="form" class="form-wrapper">
        <div class="row g-2">
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_id"
                selectId="companySelect"
                [options]="companies"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                (onChange)="filterBranches($event.value)"
                [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="companySelect">{{ 'NEW-TRANSLATE.COMMON.COMPANY' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_branch_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_branch_id"
                selectId="branchSelect"
                [options]="branches"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [placeholder]="'NEW-TRANSLATE.COMMON.BRANCH' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="branchSelect">{{ 'NEW-TRANSLATE.COMMON.BRANCH' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['rd_document_type'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="rd_document_type"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="documentType"
                optionValue="type"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
                selectId="documentTypeSelect"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="documentTypeSelect">{{ 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE' | translate }}</label>
            </div>
          </div>   
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-label-group">
              <input
                class="form-control"
                data-testid="documentNumberInput"
                formControlName="docu_no"
                id="documentNumberInput"
                type="text"
                [placeholder]="'NEW-TRANSLATE.COMMON.DOCUMENT-NUMBER' | translate"
              >
              <label for="documentNumberInput">{{ 'NEW-TRANSLATE.COMMON.DOCUMENT-NUMBER' | translate }}</label>
            </div>
          </div>  
          <div class="col-12 col-md-6 col-lg-4">
            <div class="form-label-group">
              <input
                class="form-control"
                data-testid="buyerNameInput"
                formControlName="buyer_name"
                id="buyerNameInput"
                type="text"
                [placeholder]="'NEW-TRANSLATE.COMMON.BUYER-NAME' | translate"
              >
              <label for="buyerNameInput">{{ 'NEW-TRANSLATE.COMMON.BUYER-NAME' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <app-date-range-picker
              formControlName="docu_date_period"
              label="NEW-TRANSLATE.COMMON.DOCUMENT-DATE"
              [maxRange]="93"
              [ngClass]="{ 'is-invalid': submitted && form.controls['docu_date_period'].errors }"
              [isLoading]="isLoading || isLoadingRows || isLoadingOther"
            ></app-date-range-picker>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <app-date-range-picker
              formControlName="upload_date_period"
              [clearable]="true"
              label="NEW-TRANSLATE.COMMON.UPLOAD-DATE"
              [maxRange]="93"
              [ngClass]="{ 'is-invalid': submitted && form.controls['upload_date_period'].errors }"
              [isLoading]="isLoading || isLoadingRows || isLoadingOther"
            ></app-date-range-picker>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="datatables-container">
    <app-custom-pagination
      [(currentPage)]="pages"
      [(itemsPerPage)]="limitRow"
      [totalItems]="collectionSize"
      [isLoading]="isLoading || isLoadingRows || isLoadingOther"
      (pageChange)="pageChanged()"
      (optionChangeValue)="limitRowChanged($event)"
    >
      @if (isPermissionDatatableTools()) {
        <div class="col-auto d-flex gap-2 mt-3 mt-lg-0">
          <button
            class="btn btn-dv btn-wide"
            data-testid="downloadTaxReportXmlButton"
            id="downloadTaxReportXmlButton"
            [disabled]="!rows || rows.length === 0 || !selectedRows || selectedRows.length === 0 || isLoadingRows || isLoadingOther || isLoading"
            type="button"
            [title]="'NEW-TRANSLATE.DOWLOAD.DOWNLOAD-TAX-REPORT-XML' | translate"
            (click)="onDownloadReport('.xml')"
          >
            {{ 'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-TAX-REPORT-XML' | translate }}
          </button>
          <button
            class="btn btn-dv btn-wide"
            data-testid="downloadTaxReportPdfButton"
            id="downloadTaxReportPdfButton"
            [disabled]="!rows || rows.length === 0 || !selectedRows || selectedRows.length === 0 || isLoadingRows || isLoadingOther || isLoading"
            type="button"
            [title]="'NEW-TRANSLATE.DOWLOAD.DOWNLOAD-TAX-REPORT-PDF' | translate"
            (click)="onDownloadReport('.pdf')"
          >
            {{ 'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-TAX-REPORT-PDF' | translate }}
          </button>
        </div>
      }
    </app-custom-pagination>
    <app-datatables
      #datatables
      [collectionSize]="collectionSize"
      [columns]="columns"
      [isLoadingRows]="isLoadingRows"
      [isLoadingOther]="isLoadingOther"
      [limitRow]="limitRow"
      [pages]="pages"
      [rows]="rows"
      [(selectedRows)]="selectedRows"
      [(sortBy)]="sortBy"
      [(sortMode)]="sortMode"
      tableId="documentSearchDatatable"
      (onSortEvent)="onSortEvent()"
    />
  </div>
</div>