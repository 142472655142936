import { LookupModel } from "../../shared/interfaces";

export const VAT_LIST: string[] = [
  '7',
  '0',
  'FRE'
];

export const OTHER_REASON: LookupModel = {
  name_th: 'เหตุอื่น (ระบุสาเหตุ)',
  name_en: 'เหตุอื่น (ระบุสาเหตุ)'
};

export const KEYIN_VALIDATORS = {
  reference_document: {
    /* Document No. */
    doc_no: {
      maxLength: 35
    },
    running_no: {
      maxLength: 9
    },
    /* (CN/DN) Reference No. and (Replacement) Refer Document No. */
    reference_no: {
      maxLength: 35
    },
    /* (CN/DN) Description and (Replacement) Description */
    reason_description: {
      maxLength: 256
    },
    reference_abb_no: {
      maxLength: 35
    }
  },
  seller_detail: {
    internal_branch_code: {
      maxLength: 5
    },
    branch_code: {
      maxLength: 5,
      minLength: 5
    },
    branch_name: {
      maxLength: 256
    },
    house_no: {
      maxLength: 256
    },
    building_name: {
      maxLength: 256
    },
    floor: {
      maxLength: 256
    },
    alley: {
      maxLength: 256
    },
     /* Village No. */
    moo_no: {
      maxLength: 256
    },
    road: {
      maxLength: 256
    },
    /* Postal Code */
    zip_code: {
      maxLength: 5,
      minLength: 5
    },
    /* Tel */
    branch_tel: {
      maxLength: 30
    },
  },
  buyer_detail: {
    /* Buyer Name / Company Name */
    buyer_name: {
      maxLength: 256
    },
    /* TAX ID. */
    buyer_taxid: {
      individual: {
        maxLength: 13,
        minLength: 3
      },
      juristic: {
        maxLength: 13,
        minLength: 13
      },
      foreigner: {
        maxLength: 35
      }
    },
    /* Branch Code */
    buyer_branch_code: {
      maxLength: 5,
      minLength: 5
    },
    /* Branch Name. */
    buyer_branch_name: {
      maxLength: 256
    },
    email_to: {
      maxLength: 256
    },
    house_no: {
      maxLength: 16
    },
    building_name: {
      maxLength: 50
    },
    floor: {
      maxLength: 5
    },
    alley: {
      maxLength: 50
    },
    /* Village No. */
    moo_no: {
      maxLength: 5
    },
    road: {
      maxLength: 50
    },
    /* Postal Code */
    zip_code: {
      domestic: {
        maxLength: 5,
        minLength: 5
      },
      international: {
        maxLength: 16
      }
    },
    address: {
      maxLength: 200
    }
  },
  line_items: {
    /* No */
    id: {
      maxLength: 10
    },
    product_code: {
      maxLength: 35
    },
    description: {
      maxLength: 256
    },
    qty: {
      maxLength: 15,
      decimal: 2
    },
    unit: {
      maxLength: 18
    },
    unit_price: {
      maxLength: 15,
      decimal: 2
    },
    discount: {
      maxLength: 15,
      decimal: 2
    },
    charge: {
      maxLength: 15,
      decimal: 2
    },
    amount_exclude: {
      maxLength: 15,
      decimal: 2
    },
    total_vat: {
      maxLength: 15,
      decimal: 2
    },
    total_include: {
      maxLength: 15,
      decimal: 2
    }
  },
  end_of_bill: {
    total: {
      maxLength: 15,
      decimal: 2
    },
    discount_or_charge: {
      maxLength: 15,
      decimal: 2
    },
    total_amount_after_discount_or_charge: {
      maxLength: 15,
      decimal: 2
    },
    base_amount_vat: {
      maxLength: 15,
      decimal: 2
    },
    vat_7_percentage: {
      maxLength: 15,
      decimal: 2
    },
    base_amount_vat_0_percentage: {
      maxLength: 15,
      decimal: 2
    },
    base_amount_non_vat: {
      maxLength: 15,
      decimal: 2
    },
    total_amount: {
      maxLength: 15,
      decimal: 2
    },
    original_total_amount: {
      maxLength: 15,
      decimal: 2
    },
    new_total_amount: {
      maxLength: 15,
      decimal: 2
    },
    different_amount: {
      maxLength: 15,
      decimal: 2
    }
  }
};