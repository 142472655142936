<div class="card-container">
  <div class="box-parent">
    <h3>{{'TEXT.PASSWORD' | translate}}</h3>

    <div class="row mt-3" *ngIf="!resetSuccess">
      <div class="col-12 custom-label">
        <input type="password" class="form-control" id="newPassword" [(ngModel)]="newPassword"
               placeholder="{{'PROFILE.PASSWORD.NEW-PASSWORD' | translate}}">
        <label for="newPassword">{{'PROFILE.PASSWORD.NEW-PASSWORD' | translate}}</label>
      </div>
      <div class="col-12 custom-label mt-3">
        <input type="password" class="form-control" id="confirmNewPassword" [(ngModel)]="confirmNewPassword"
               placeholder="{{'PROFILE.PASSWORD.CONFIRM-NEW-PASSWORD' | translate}}"
               (keyup.enter)="submitPassword()">
        <label for="confirmNewPassword">{{'PROFILE.PASSWORD.CONFIRM-NEW-PASSWORD' | translate}}</label>
      </div>
      <div class="col-12 custom-label mt-3">
        <small class="text-red">** {{ 'USER-MANAGEMENT.CONFIRM-BY-RECAPTCHA' | translate }}</small>
        <re-captcha #recaptchaRef (resolved)="resolve($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
      </div>
      <div class="col-12 text-right mt-3">
        <button type="button" class="btn btn-dv btn-wide me-2" [disabled]="(newPassword ? (newPassword != confirmNewPassword) : true) || !recaptchaResponse || isLoading" (click)="submitPassword()">
          <fa-icon *ngIf="isLoading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
          {{'TEXT.SUBMIT' | translate}}
        </button>
      </div>
    </div>
    <div class="col-12 text-center mt-3" *ngIf="resetSuccess">
      <h5>{{resetSuccess}}</h5>
    </div>
  </div>
</div>
<p-toast position="top-right"></p-toast>
