@if (!isError) {
  <div class="row box-parent">
    <div class="col">
      <h4 class="row header">
        {{ 'TEXT.CD' | translate }}
      </h4>
    </div>
    <div class="datatables-container">
      <app-custom-pagination
        [(currentPage)]="pages"
        [(itemsPerPage)]="limitRow"
        [totalItems]="collectionSize"
        [isLoading]="isLoadingOther || isLoadingRows"
        (pageChange)="pageChanged()"
        [hideItemsPerPage]="true"
      >
      <div class="col-auto d-flex gap-2 mt-3 mt-lg-0 align-items-center flex-wrap">
        <button
          class="btn btn-dv btn-wide"
          data-testid="downloadAllXmlButton"
          [disabled]="!rows || rows.length === 0 || isLoadingRows || isLoadingOther"
          id="downloadAllXmlButton"
          type="button"
          [title]="'TEXT.D-XML' | translate"
          (click)="onDownloadAll('xml')"
        >
          {{ 'TEXT.D-XML' | translate }}
        </button>
        <button
          class="btn btn-dv btn-wide"
          data-testid="downloadAllPdfButton"
          [disabled]="!rows || rows.length === 0 || isLoadingRows || isLoadingOther"
          id="downloadAllPdfButton"
          type="button"
          [title]="'TEXT.D-PDF' | translate"
          (click)="onDownloadAll('pdf')"
        >
          {{ 'TEXT.D-PDF' | translate }}
        </button>
      </div>
      </app-custom-pagination>
      <app-datatables
        [collectionSize]="collectionSize"
        [columns]="columns"
        [isLoadingOther]="isLoadingOther"
        [isLoadingRows]="isLoadingRows"
        [limitRow]="limitRow"
        [pages]="pages"
        [rows]="rows"
        tableId="documentUploadResultDatatable"
        (onRowActionEvent)="onRowActionEvent($event)"
      />
    </div>
  </div>
} @else {
  <div class="text-center mt-4">
    <h4>{{ 'NEW-TRANSLATE.ERROR.TRY-AGAIN' | translate }}</h4>
    <div>{{ errorMessage ?? '' | translate }}</div>
  </div>
}
