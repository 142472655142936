@if (form && documentType) {
<div class="keyin-section-1-container box-parent section-box mb-2">
  <h5 class="section-title">{{ "NEW-TRANSLATE.COMMON.DOCUMENT-DETAIL" | translate }}</h5>
  <form [formGroup]="form" class="form-wrapper">
    <div class="row g-2 mb-3">
      <div class="col-12 col-lg-3 col-md-4 col-sm-6">
        <label class="mb-2 required-field" for="documentNumberInput">{{
          "NEW-TRANSLATE.COMMON.DOCUMENT-NO" | translate
        }}</label>
        <input
          autocomplete="off"
          class="form-control"
          data-testid="documentNumberInput"
          formControlName="doc_no"
          id="documentNumberInput"
          [maxlength]="validators.reference_document.doc_no.maxLength"
          [readonly]="isLoading"
          type="text"
          [ngClass]="{
            'is-invalid': submitted && form.controls['doc_no'].errors
          }"
        />
      </div>
      <div class="col-12 col-lg-3 col-md-4 col-sm-6">
        <label class="mb-2 required-field" for="runningNumberInput">{{
          "NEW-TRANSLATE.COMMON.RUNNING-NO" | translate
        }}</label>
        <input
          autocomplete="off"
          class="form-control"
          data-testid="runningNumberInput"
          formControlName="running_no"
          id="runningNumberInput"
          mask="0*"
          [maxlength]="validators.reference_document.running_no.maxLength"
          [ngbTooltip]="'NEW-TRANSLATE.GUILD.RUNNING-NO' | translate"
          [readonly]="isLoading"
          placement="bottom"
          [specialCharacters]="[]"
          type="text"
          [ngClass]="{
            'is-invalid': submitted && form.controls['running_no'].errors
          }"
        />
      </div>
      <div class="col-12 col-lg-3 col-md-4 col-sm-6">
        <label class="mb-2 required-field" for="documentDatePicker">{{
          "NEW-TRANSLATE.COMMON.DOCUMENT-DATE" | translate
        }}</label>
        <p-calendar
          class="form-control date-picker-custom datepicker-style-custom"
          dateFormat="dd/mm/yy"
          [disabled]="isLoading"
          formControlName="doc_date"
          inputId="documentDatePicker"
          [iconDisplay]="'input'"
          [showIcon]="true"
          [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-DATE' | translate"
          [readonlyInput]="true"
          [ngClass]="{
            'is-invalid': submitted && form.controls['doc_date'].errors
          }"
        >
          <ng-template pTemplate="inputicon" let-clickCallBack="clickCallBack">
            <div class="date-picker-icon-custom">
              <fa-icon
                [icon]="['far', 'calendar']"
                size="xl"
                (click)="clickCallBack($event)"
              ></fa-icon>
            </div>
          </ng-template>
        </p-calendar>
      </div>
    </div>
    @if (cnAndDnOption) {
      <div class="row g-2">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 d-flex align-items-end gap-3 pb-2">
          <div class="d-flex align-items-center">
            <p-radioButton 
              formControlName="purpose"
              inputId="forNewRadio"
              [value]="1"
              (onClick)="onChangePurpose($event)"
            />
            <label for="forNewRadio" class="ms-2">
              {{ 'NEW-TRANSLATE.COMMON.NEW' | translate }}
            </label>
          </div>
          <div class="d-flex align-items-center">
            <p-radioButton 
              formControlName="purpose"
              inputId="forReplacementRadio"
              [value]="2"
              (onClick)="onChangePurpose($event)"
            />
            <label for="forReplacementRadio" class="ms-2">
              {{ 'NEW-TRANSLATE.COMMON.REPLACEMENT' | translate }}
            </label>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <label class="mb-2 required-field" for="referenceNoSelect">{{
            'NEW-TRANSLATE.COMMON.REFERENCE-NO' | translate
          }}</label>
          <input
            autocomplete="off"
            class="form-control"
            data-testid="referenceNoInput"
            formControlName="reference_no"
            [maxlength]="validators.reference_document.reference_no.maxLength"
            id="referenceNoInput"
            type="text"
            [ngClass]="{
              'is-invalid':
                submitted && form.controls['reference_no'].errors
            }"
          />
        </div>
        <div class="col-12 col-lg-3 col-md-4 col-sm-6 ">
          <label class="mb-2 required-field" for="reasonSelect">{{
            'NEW-TRANSLATE.COMMON.REASON' | translate
          }}</label>
          <ng-select
            autocomplete="off"
            [bindLabel]="language === 'th' ? 'name_th' : 'name_en'"
            bindValue="name_th"
            class="form-control"
            [clearable]="false"
            data-testid="reasonSelect"
            formControlName="reason"
            id="reasonSelect"
            [items]="reasons"
            [readonly]="isLoading || isLoadingReason"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
            (change)="onChangeReason($event)"
            [ngClass]="{
              'is-invalid':
                submitted && form.controls['reason'].errors
            }"
          >
          </ng-select>
        </div>
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 offset-0 offset-md-4 offset-lg-0">
          <label
            class="mb-2"
            for="reasonDescriptionInput"
            [ngClass]="{ 'required-field': form.controls['reason'].value === otherReason.name_th }"
          >{{
            'NEW-TRANSLATE.COMMON.DESCRIPTION' | translate
          }}</label>
          <input
            autocomplete="off"
            class="form-control"
            data-testid="reasonDescriptionInput"
            formControlName="reason_description"
            id="reasonDescriptionInput"
            [maxlength]="validators.reference_document.reason_description.maxLength"
            [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SPECIFIC' | translate"
            [readonly]="isLoading || form.controls['reason'].value !== otherReason.name_th"
            type="text"
            [ngClass]="{
              'is-invalid':
                submitted && form.controls['reason_description'].errors
            }"
          />
        </div>
      </div>
    }
    @if (replacementOption) {
     <div class="mb-3">
      <div class="d-flex align-items-center gap-2 mb-2">
        <p-radioButton 
          formControlName="purpose"
          inputId="forNewRadio"
          [value]="0"
          (onClick)="onChangePurpose($event)"
        />
        <label for="forNewRadio" class="ms-2">
          {{ 'NEW-TRANSLATE.COMMON.NEW' | translate }}
        </label>
      </div>
    </div>
    <div class="mb-3">
      <div class="d-flex align-items-center gap-2 mb-2">
        <p-radioButton 
          formControlName="purpose"
          inputId="forReplacementRadio"
          [value]="2"
          (onClick)="onChangePurpose($event)"
        />
        <label for="forReplacementRadio" class="ms-2">
          {{ 'NEW-TRANSLATE.COMMON.CREATE-FOR-REPLACEMENT' | translate }}
        </label>
      </div>
      @if (form.controls['purpose'].value === 2) {
        <div class="row g-2 ps-4-5">
          <div class="col-12 col-md-4 col-sm-6">
            <label class="mb-2 required-field" for="referenceDocumentNumberInput">{{
              'NEW-TRANSLATE.COMMON.REFERENCE-DOCUMENT-NO' | translate
            }}</label>
            <input
              autocomplete="off"
              class="form-control"
              data-testid="referenceDocumentNumberInput"
              formControlName="reference_no"
              id="referenceDocumentNumberInput"
              mask="A*"
              [maxlength]="validators.reference_document.reference_no.maxLength"
              [patterns]="customPatterns"
              [readonly]="isLoading"
              type="text"
              [specialCharacters]="[]"
              [ngClass]="{
                'is-invalid':
                  submitted && form.controls['reference_no'].errors
              }"
            />
          </div>
          <div class="col-12 col-md-4 col-sm-6">
            <label class="mb-2 required-field" for="replacementReasonSelect">{{
              'NEW-TRANSLATE.COMMON.REPLACEMENT-REASON' | translate
            }}</label>
            <ng-select
              autocomplete="off"
              [bindLabel]="language === 'th' ? 'name_th' : 'name_en'"
              bindValue="name_th"
              class="form-control"
              [clearable]="false"
              data-testid="replacementReasonSelect"
              formControlName="reason"
              id="replacementReasonSelect"
              [items]="reasons"
              [readonly]="isLoading || isLoadingReason"
              [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
              (change)="onChangeReason($event)"
              [ngClass]="{
                'is-invalid':
                  submitted && form.controls['reason'].errors
              }"
            >
            </ng-select>
          </div>
          <div class="col-12 col-md-4 col-sm-6">
            <label
              class="mb-2"
              for="replacementReasonDescriptionInput"
              [ngClass]="{ 'required-field': form.controls['reason'].value === otherReason.name_th }"
            >{{
              'NEW-TRANSLATE.COMMON.DESCRIPTION' | translate
            }}</label>
            <input
              autocomplete="off"
              class="form-control"
              data-testid="replacementReasonDescriptionInput"
              formControlName="reason_description"
              id="replacementReasonDescriptionInput"
              [maxlength]="validators.reference_document.reason_description.maxLength"
              [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SPECIFIC' | translate"
              [readonly]="
                isLoading ||
                form.controls['reason'].value !==
                  otherReason.name_th
              "
              type="text"
              [ngClass]="{
                'is-invalid':
                  submitted && form.controls['reason_description'].errors
              }"
            />
          </div>
        </div>
      }
    </div>
    } @if (fullTaxInvoiceOption) {
    <div class="mb-3">
      <div class="d-flex align-items-center gap-2 mb-2">
        <p-radioButton 
          formControlName="purpose"
          inputId="forNewRadio"
          [value]="0"
          (onClick)="onChangePurpose($event)"
        />
        <label for="forNewRadio" class="ms-2">
          {{ 'NEW-TRANSLATE.COMMON.NEW' | translate }}
        </label>
      </div>
    </div>
    <div class="mb-3">
      <div class="d-flex align-items-center gap-2 mb-2">
        <p-radioButton 
          formControlName="purpose"
          inputId="forReplacementRadio"
          [value]="2"
          (onClick)="onChangePurpose($event)"
        />
        <label for="forReplacementRadio" class="ms-2">
          {{ 'NEW-TRANSLATE.COMMON.CREATE-FOR-REPLACEMENT' | translate }}
        </label>
      </div>
      @if (form.controls['purpose'].value === 2) {
        <div class="row g-2 ps-4-5">
          <div class="col-12 col-md-4 col-sm-6">
            <label class="mb-2 required-field" for="referenceDocumentNumberInput">{{
              'NEW-TRANSLATE.COMMON.REFERENCE-DOCUMENT-NO' | translate
            }}</label>
            <input
              autocomplete="off"
              class="form-control"
              data-testid="referenceDocumentNumberInput"
              formControlName="reference_no"
              id="referenceDocumentNumberInput"
              mask="A*"
              [maxlength]="validators.reference_document.reference_no.maxLength"
              [patterns]="customPatterns"
              [readonly]="isLoading"
              type="text"
              [specialCharacters]="[]"
              [ngClass]="{
                'is-invalid':
                  submitted && form.controls['reference_no'].errors
              }"
            />
          </div>
          <div class="col-12 col-md-4 col-sm-6">
            <label class="mb-2 required-field" for="replacementReasonSelect">{{
              'NEW-TRANSLATE.COMMON.REPLACEMENT-REASON' | translate
            }}</label>
            <ng-select
              autocomplete="off"
              [bindLabel]="language === 'th' ? 'name_th' : 'name_en'"
              bindValue="name_th"
              class="form-control"
              [clearable]="false"
              data-testid="replacementReasonSelect"
              formControlName="reason"
              id="replacementReasonSelect"
              [items]="reasons"
              [readonly]="isLoading || isLoadingReason"
              [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
              (change)="onChangeReason($event)"
              [ngClass]="{
                'is-invalid':
                  submitted && form.controls['reason'].errors
              }"
            >
            </ng-select>
          </div>
          <div class="col-12 col-md-4 col-sm-6">
            <label
              class="mb-2"
              for="replacementReasonDescriptionInput"
              [ngClass]="{ 'required-field': form.controls['reason'].value === otherReason.name_th }"
            >{{
              'NEW-TRANSLATE.COMMON.DESCRIPTION' | translate
            }}</label>
            <input
              autocomplete="off"
              class="form-control"
              data-testid="replacementReasonDescriptionInput"
              formControlName="reason_description"
              id="replacementReasonDescriptionInput"
              [maxlength]="validators.reference_document.reason_description.maxLength"
              [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SPECIFIC' | translate"
              [readonly]="
                isLoading ||
                form.controls['reason'].value !==
                  otherReason.name_th
              "
              type="text"
              [ngClass]="{
                'is-invalid':
                  submitted && form.controls['reason_description'].errors
              }"
            />
          </div>
        </div>
      }
    </div>
    <div class="mb-3">
      <div class="d-flex align-items-center gap-2 mb-2">
        <p-radioButton 
          formControlName="purpose"
          inputId="forCancelAbbRadio"
          [value]="3"
          (onClick)="onChangePurpose($event)"
        />
        <label for="forCancelAbbRadio" class="ms-2">
          {{ 'NEW-TRANSLATE.KEYIN.FULL-TAX-INVOICE-CANCEL-ABB' | translate }}
        </label>
      </div>
      @if (form.controls['purpose'].value === 3) {
        <div class="row g-2 ps-4-5">
          <div class="col-12 col-md-4 col-sm-6">
            <label class="mb-2 required-field" for="referenceAbbNumberInput">{{
              'NEW-TRANSLATE.COMMON.REFERENCE-ABB-NO' | translate
            }}</label>
            <input
              autocomplete="off"
              class="form-control"
              data-testid="referenceAbbNumberInput"
              formControlName="reference_abb_no"
              id="referenceAbbNumberInput"
              mask="A*"
              [maxlength]="validators.reference_document.reference_abb_no.maxLength"
              [patterns]="customPatterns"
              [readonly]="isLoading"
              type="text"
              [specialCharacters]="[]"
              [ngClass]="{
                'is-invalid':
                  submitted && form.controls['reference_abb_no'].errors
              }"
            />
          </div>
          <div class="col-12 col-md-4 col-sm-6">
            <label class="mb-2 required-field" for="referenceDocumentDatePicker">{{
              'NEW-TRANSLATE.COMMON.DOCUMENT-DATE' | translate
            }}</label>
            <p-calendar
              class="form-control date-picker-custom datepicker-style-custom"
              dateFormat="dd/mm/yy"
              formControlName="reference_doc_date"
              inputId="referenceDocumentDatePicker"
              [iconDisplay]="'input'"
              [showIcon]="true"
              [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-DATE' | translate"
              [ngClass]="{
                'is-invalid':
                  submitted && form.controls['reference_doc_date'].errors
              }"
            >
              <ng-template
                pTemplate="inputicon"
                let-clickCallBack="clickCallBack"
              >
                <div class="date-picker-icon-custom">
                  <fa-icon
                    [icon]="['far', 'calendar']"
                    size="xl"
                    (click)="clickCallBack($event)"
                  ></fa-icon>
                </div>
              </ng-template>
            </p-calendar>
          </div>
        </div>
      }
    </div>
    }
  </form>
</div>
}
