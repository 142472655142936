import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';


export const AuthGuard: CanActivateFn = (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const isLoggedIn = authenticationService.isLoggedIn();
  if(!isLoggedIn) {
    authenticationService.onLogout(state.url);
    return false;
  }
  return true;
};
