import { Component } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HandleModalService } from '../../../services';
import { ButtonModalOptions, TextModalModel } from '../../../interfaces';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  public modalID: string | undefined;

  public iconSrc: string | undefined;
  public iconClass: string | undefined;
  public iconHeight: string | undefined;
  public iconWidth: string | undefined

  public confirmButtonOptions: ButtonModalOptions | undefined;
  public cancelButtonOptions: ButtonModalOptions | undefined;

  public title: string | undefined;
  public texts: TextModalModel[] = [];

  public closeValue: any;

  public modalEl: NgbModalRef | undefined;

  public requiredReCaptcha: boolean = false;
  public reCaptchaSiteKey: string | undefined = environment['recaptChaSiteKey'];
  public reCaptchaToken: string | null | undefined

  constructor(
    private handleModalService: HandleModalService
  ) {
  }

  public recaptChaResolved(event: string | null): void {
    this.reCaptchaToken = event;
  }

  onClose() {
    this.handleModalService.closeModal(this.modalEl, { value: this.closeValue, reCaptchaToken: this.reCaptchaSiteKey });
  }

  onDismiss() {
    this.handleModalService.dismissModal(this.modalEl);
  }

}
