<div class="setting-report-container">
  <div class="criteria-search-container">
    <app-filter-header
      [loading]="isLoading || isLoadingRows"
      (submit)="onSearch()"
      (clear)="onClear()"
    />
    <div class="criteria-search-wrapper">
      <form [formGroup]="form" class="form-wrapper">
        <div class="row g-2">
          <div class="col-12 col-md-6">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['tax_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="tax_id"
                selectId="companySelect"
                [options]="companies"
                optionValue="tax_id"
                [loading]="isLoading || isLoadingRows"
                [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="companySelect">{{ 'NEW-TRANSLATE.COMMON.COMPANY' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['send_to_rd_configuration'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="send_to_rd_configuration"
                [loading]="isLoading || isLoadingRows"
                [options]="sendToRdConfigurations"
                optionValue="id"
                [placeholder]="'NEW-TRANSLATE.SETTING.SENT-TO-RD' | translate"
                selectId="sendToRdConfigurationSelect"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="sendToRdConfigurationSelect">{{ 'NEW-TRANSLATE.SETTING.SENT-TO-RD' | translate }}</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="datatables-container">
    <app-custom-pagination
      [(currentPage)]="pages"
      [(itemsPerPage)]="limitRow"
      [totalItems]="collectionSize"
      [isLoading]="isLoading || isLoadingRows"
      (pageChange)="pageChanged()"
      (optionChangeValue)="limitRowChanged($event)"
    />
    <app-datatables
      #datatables
      tableId="settingReportDatatable"
      [isLoadingRows]="isLoadingRows"
      [columns]="columns"
      [rows]="rows"
      [pages]="pages"
      [limitRow]="limitRow"
      [collectionSize]="collectionSize"
    />
  </div>
</div>