import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../service/authentication.service';
import { Router } from '@angular/router';
import { Observable, Subject, map, of, takeUntil, throwError, zip } from 'rxjs';
import { ApiService } from '../../service/api.service';
import { HttpClientService } from '../../service/http-client.service';
import { DocumentTypeMasterDataModel, LanguageType, LookupModel, ResponseMessageCompanyModel } from '../../shared/interfaces';
import { HandleTranslateService, HandleModalService, UtilitiesService, MasterDataService } from '../../shared/services';
import { MONTHS } from '../../shared/services/shared.constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { DashboardModel, PayloadDashboardModel, ResponseMessageDashboardModel } from './dashboard.interface';
import { Chart, ChartData, ChartDataset, ChartOptions, TooltipItem } from 'chart.js';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements AfterViewInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public years: number[] = [];
  public months: LookupModel[] = [ ...MONTHS ];
  public documentTypeList: DocumentTypeMasterDataModel[] = [];

  private currentDate: Date = new Date();

  public chartOfDashboard: Chart<'bar'> | undefined;
  @ViewChild('chartOfDashboard') private chartOfDashboardEl: ElementRef | undefined;

  private total: DashboardModel | undefined;

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    corporate_id: new FormControl(null),
    year: new FormControl(this.currentDate.getFullYear(), [ Validators.required ])
  });

  public isBCMStaff: boolean = false;

  public isLoading: boolean = false;
  public isLoadingChart: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private handleTranslateService: HandleTranslateService,
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService,
    private masterDataService: MasterDataService
  ) {
    this.checkPermission()
      .subscribe({
        next: () => {
          this.settingRolePermission();
          this.subscribeToServices();
        },
        error: () => {
          if (this.authenticationService.isSCBBCMManageUser() || this.authenticationService.isViewerOperation()) {
            this.router.navigate(['/user-management']);
          } else if (!this.authenticationService.isDashboardPermission()) {
            this.router.navigate(['/']);
          }
        }
      });
  }

  ngAfterViewInit(): void {
    this.checkPermission()
      .subscribe({
        next: () => {
          this.initialSetting();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.chartOfDashboard?.destroy();
  }

  private subscribeToServices(): void {
    this.handleTranslateService
      .language
      ?.pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(x => {
        this.language = x;
        if (this.chartOfDashboard) {
          this.settingChart();
        }
      });
  }

  private checkPermission(): Observable<void> {
    if (this.authenticationService.isDashboardPermission()) {
      return of(undefined);
    } else {
      return throwError(() => this.handleTranslateService.translate('NEW-TRANSLATE.ERROR.YOU-DO-NOT-HAVE-ACCESS-TO-THIS-PAGE'));
    }
  }

  private settingRolePermission(): void {
    this.isBCMStaff = this.authenticationService.isSCBBCM();
  }

  private initialSetting(): void {
    this.isLoading = true;
    zip(
      this.loadCompany(),
      this.loadYear(),
      this.loadDocumentType()
    )
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe({
      next: ([ companies, years, documentType ]) => {
        this.documentTypeList = documentType;
        this.settingChart();
        this.companies = companies;
        if (!this.authenticationService.isSCBBCM() && this.companies.length === 1) {
          const defaultCompany = this.companies[0].id;
          this.form.controls['corporate_id'].setValue(defaultCompany, { emitEvent: false });
        } else {
          this.companies.unshift(this.allSelect);
        }
        this.years = years;
        this.loadDashboard();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    });
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.apiService
      .getCompany()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(res => {
          const newRes =  res as ResponseMessageCompanyModel;
          return [...newRes.results].map(x => {
            return { name_th: x.name, name_en: x.name, id: x.id }
          });
        })
      );
  }

  private loadYear(): Observable<number[]> {
    const currentYear = this.currentDate.getFullYear();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
    return of(years)
            .pipe(
              takeUntil(this.unsubscribe$)
            );
  }

  private loadDocumentType(): Observable<DocumentTypeMasterDataModel[]> {
    return of(this.masterDataService.getDocumentType())
            .pipe(
              takeUntil(this.unsubscribe$)
            );
  }

  public loadDashboard(): void {
    this.isLoadingChart = true;
    const formValue = this.form.value;
    const params: PayloadDashboardModel = {
      company_id: formValue['corporate_id'] ?? '',
      year: formValue['year'] ?? ''
    };
    this.httpClientService
      .get(`${ environment.apiURL }/api/dashboard_monthly/`, params)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(x => x as ResponseMessageDashboardModel)
      )
      .subscribe({
        next: (res) => {
          this.total = res;
          this.settingChart();
          this.isLoadingChart = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingChart = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  private getChartDatasets(): ChartDataset<'bar', number[]>[] {
    return this.documentTypeList
            .map(x => {
              const total = (this.total?.signing_counter[x.type] || [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ]) as number[];
              const chartDataset: ChartDataset<'bar', number[]> = {
                label: this.language === 'th' ? x.name_th : x.name_en,
                backgroundColor: x.color_code,
                borderColor: x.color_code,
                data: total
              }
              return chartDataset;
            });
  }

  private settingChart(): void {
    const chartOfDashboardEl = this.chartOfDashboardEl;
    if (chartOfDashboardEl) {
      const data: ChartData<'bar'> = {
        labels: [ ...this.months ].map(x => this.language === 'th' ? x.name_th : x.name_en),
        datasets: this.getChartDatasets()
      };
      if (!this.chartOfDashboard) {
        this.chartOfDashboard = new Chart(chartOfDashboardEl.nativeElement, {
          type: 'bar',
          data: data,
          options: this.getChartOptions()
        });
      } else {
        this.chartOfDashboard.data = data;
        this.chartOfDashboard.options = this.getChartOptions();
        this.chartOfDashboard?.update();
      }
    }
  }

  private getChartOptions(): ChartOptions<'bar'> {
    const unitName = this.handleTranslateService.translate('NEW-TRANSLATE.COMMON.TIMES');
    const totalName = this.handleTranslateService.translate('NEW-TRANSLATE.COMMON.TOTAL');
    let total = 0;
    const options: ChartOptions<'bar'> = {
      plugins: {
        tooltip: {
          filter: (tooltipItem: TooltipItem<'bar'>, tooltipIndex: number, array: TooltipItem<'bar'>[]) => {
            const showLabel = !array.every(x => x.dataset.data[tooltipItem.dataIndex] === 0);
            return showLabel;
          },
          mode: 'index',
          intersect: false,
          callbacks: {
            afterTitle: () => {
              total = 0;
            },
            label: (context) => {
              
              const value = this.getTotalFromDataset(context.dataset.data[context.dataIndex]);
              let label = (context.dataset.label ?? context.label ?? '') + this.getTooltipLabel(value, unitName);
              total += value;
              return label;
            },
            footer: (context) => {
              const text = [ totalName + ' : ' + total.toString() + ' ' + unitName +
                ((total >= 2 && unitName === 'Time' && this.language !== 'th') ? 's' : '')];
              return context && context.length > 0 ? text : '';
            }

          }
        },
        legend: {
          position: 'top'
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
            stacked: true,
        },
        y: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            callback: (value: any) => {
              if (value % 1 === 0) {
                return value;
              }
            }
          }
        }
      },
    };
    return options;
  }

  private getTotalFromDataset(total: number | number[] | null): number {
    if (typeof total === 'number') {
      return total;
    } else if (Array.isArray(total)) {
      return total.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    } else {
      return 0;
    }
  }

  private getTooltipLabel(total: number, unitName: string): string {
    return ' : ' + total.toString() + ' ' + unitName + (total >= 2 && this.language !== 'th' ? 's' : '');
  }

}
