import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, map, takeUntil } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClientService } from '../../../../service/http-client.service';
import { SellerBranchModel } from '../../keyin.interface';
import { KeyinService } from '../../keyin.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { KeyinHandleSellerBranchModalComponent } from '../modals';
import { HandleModalService, HandleTranslateService, UtilitiesService } from '../../../../shared/services';
import { LanguageType } from '../../../../shared/interfaces';

@Component({
  selector: 'app-keyin-section-2',
  templateUrl: './keyin-section-2.component.html',
  styleUrls: [ './keyin-section-2.component.scss', '../../keyin.component.scss' ]
})
export class KeyinSection2Component implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();
  public language: LanguageType;

  @Input() public form!: FormGroup;
  @Input() public submitted: boolean = false;
  @Input() public isLoading: boolean = false;

  public sellerBranchs: SellerBranchModel[] = [];

  public isLoadingSellerBranch: boolean = false;

  constructor(
    private handleTranslateService: HandleTranslateService,
    private httpClientService: HttpClientService,
    private keyinService: KeyinService,
    private handleModalService: HandleModalService,
    private utilitiesService: UtilitiesService
  ) {
    this.subscribeToServices();
  }

  ngOnInit(): void {
   this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private initialSetting(): void {
    this.loadSellerBranch();
  }

  private loadSellerBranch(branch?: { branch_code: string, internal_branch_code: string }): void {
    this.isLoadingSellerBranch = true;
    this.httpClientService
      .get(`${ environment.apiURL }/api/branches`)
      .pipe(
        map(x => x as SellerBranchModel[])
      )
      .subscribe({
        next: (res) => {
          this.isLoadingSellerBranch = false;
          this.sellerBranchs = res;
          if (branch) {
            const updateBranch = res.find(x => x.branch_code === branch.branch_code && x.internal_branch_code === branch.internal_branch_code);
            this.form.controls['seller_branch'].setValue(updateBranch);
          }
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  public customSearchSellerBranchValue = (term: string, item: any) => this.keyinService.customNgSelectSearchFn(term, item, [ 'branch_name', 'branch_code', 'internal_branch_code' ]);

  public addSellerBranch(sellerSelectEl: NgSelectComponent): void {
    sellerSelectEl.close();
    const modalEl = this.handleModalService
          .openModal(
            { 
              componentRef: KeyinHandleSellerBranchModalComponent,
              id: 'handleSellerBranchPdfModal',
              options: {
                windowClass: 'handle-seller-branch-modal',
                centered: true,
                size: 'lg',
                keyboard: false,
                backdrop: false,
                autoCloseRoutingChange: true
              }
            }
          );
    const modalComponent = (modalEl.componentInstance as KeyinHandleSellerBranchModalComponent);
          modalComponent
            .onSubmitEvent
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              (res) => {
                modalComponent.isLoading = true;
                this.httpClientService
                  .post(`${ environment.apiURL }/api/create_branch`, res)
                  .pipe(
                    takeUntil(this.unsubscribe$)
                  )
                  .subscribe({
                    next: () => {
                      modalComponent.onClose();
                      modalComponent.isLoading = false;
                      this.handleModalService.successModal({
                        title: 'NEW-TRANSLATE.MODAL-TEXT.SUCCESSFULLY',
                        cancelButton: {
                          id: 'closeModalButton',
                          show: true,
                          name: 'NEW-TRANSLATE.COMMON.CLOSE',
                          class: 'btn-dv btn-wide'
                        }
                      });
                      this.loadSellerBranch({ branch_code: res.branch_code, internal_branch_code: res.internal_branch_code });
                    },
                    error: (err) => {
                      console.error(err);
                      modalComponent.isLoading = false;
                      if (!this.handleModalService.hasModal('failedModal')) {
                        const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
                        this.handleModalService.connectFailedModal(errorMessage);
                      }
                    }
                  });
              }
            );
  }

}
