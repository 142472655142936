import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, map, of, takeUntil, zip } from 'rxjs';
import { LookupModel, DocumentTypeModel, BranchModel, DatatableCustomColumnModel, ResponseMessageCompanyModel, ResponseMessageBranchModel, RowActionEventModel, LanguageType } from '../../shared/interfaces';
import { MONTHS, STATUS } from '../../shared/services/shared.constant';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpClientService } from '../../service/http-client.service';
import { HandleModalService, HandleTranslateService, MasterDataService, UtilitiesService } from '../../shared/services';
import { PayloadRdSummaryModel, RdSummaryReportModel, ResponseMessageRdSummaryModel } from './rd-summary.interface';
import { environment } from '../../../environments/environment';
import { DatatablesComponent } from '../../shared/components/datatables/datatables.component';

@Component({
  selector: 'app-rd-summary',
  templateUrl: './rd-summary.component.html',
  styleUrl: './rd-summary.component.scss'
})
export class RdSummaryComponent implements OnInit, OnDestroy {

  @ViewChild('datatables') private datatablesElement: DatatablesComponent | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public allDocumentType: DocumentTypeModel = {
    type: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public branches: LookupModel[] = [];
  private defaultBranches: BranchModel[] = [];
  public documentType: DocumentTypeModel[] = [];
  public years: number[] = [];
  public months: LookupModel[] = [ ...MONTHS ];
  public rdStatus: LookupModel[] = [];

  private currentDate: Date = new Date();

  public numberTotal: number = 0;
  public sentToSPTotal: number = 0;
  public rdAcknowledgedTotal: number = 0;

  public pendingTotal: number = 0;
  public acceptedTotal: number = 0;
  public rejectedTotal: number = 0;
  
  public pages: number = 1;
  public limitRow: number = 10;
  public collectionSize: number = 0;
  public rows: RdSummaryReportModel[] = [];
  public columns: DatatableCustomColumnModel[] = [
    {
      id: 'no',
      label: 'NEW-TRANSLATE.COMMON.NO',
      name: 'no',
      type: 'no',
      width: '80px',
      minWidth: '80px'
    },
    {
      id: 'companyName',
      label: 'NEW-TRANSLATE.COMMON.COMPANY-NAME',
      name: 'company_name',
      type: 'string',
      width: '150px',
      minWidth: '150px'
    },
    {
      id: 'branchName',
      label: 'NEW-TRANSLATE.COMMON.BRANCH-NAME',
      name: '',
      type: 'join-string',
      width: '300px',
      minWidth: '300px',
      joinString: {
        keys: [
          'internal_branch_code',
          'branch_code',
          'branch_name'
        ],
        separator: '/'
      }
    },
    {
      id: 'documentType',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE',
      name: 'rd_document_type',
      type: 'document-type',
      width: '150px',
      minWidth: '150px'
    },
    {
      id: 'documentNumber',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-NUMBER',
      name: 'header_invoice_number',
      type: 'string',
      width: '120px',
      minWidth: '120px'
    },
    {
      id: 'issueDate',
      label: 'NEW-TRANSLATE.COMMON.ISSUE-DATE',
      name: 'header_issue_dt',
      type: 'date',
      format: 'DD/MM/YYYY',
      width: '120px',
      minWidth: '120px'
    },
    {
      id: 'sentToRdDate',
      label: 'NEW-TRANSLATE.RD-SUMMARY.SENT-TO-RD-DATE',
      name: 'rd_sent_date',
      type: 'date',
      format: 'DD/MM/YYYY',
      width: '120px',
      minWidth: '120px'
    },
    {
      id: 'conversationId',
      label: 'NEW-TRANSLATE.RD-SUMMARY.CONVERSATION-ID',
      name: 'conversation_id',
      type: 'string',
      width: '200px',
      minWidth: '200px',
      defaultValue: '-'
    },
    {
      id: 'sentToSp',
      label: 'NEW-TRANSLATE.RD-SUMMARY.SENT-TO-SP',
      name: 'is_sent',
      type: 'boolean-icon',
      width: '120px',
      minWidth: '120px'
    },
    {
      id: 'rdAcknowledge',
      label: 'NEW-TRANSLATE.RD-SUMMARY.RD-ACKNOWLEDGE',
      name: 'is_acknowledge',
      type: 'boolean-icon',
      width: '120px',
      minWidth: '120px'
    },
    {
      id: 'status',
      label: 'NEW-TRANSLATE.COMMON.STATUS',
      name: 'document_status',
      type: 'status',
      width: '140px',
      minWidth: '140px'
    },
    {
      id: 'notes',
      label: 'NEW-TRANSLATE.COMMON.NOTES',
      name: 'notes',
      type: 'action-icon',
      width: '140px',
      minWidth: '140px',
      format: 'eye',
      actionIconOptions: {
        style: 'color: #633BA4;',
        condition: 'error_note'
      }
    }
  ];

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    corporate_id: new FormControl(null),
    corporate_branch_id: new FormControl(null),
    rd_document_type: new FormControl(null),
    month: new FormControl(this.currentDate.getMonth() + 1, [ Validators.required ]),
    year: new FormControl(this.currentDate.getFullYear(), [ Validators.required ]),
    document_status: new FormControl(null)
  });

  public isLoading: boolean = true;
  public isLoadingRows: boolean = false;
  public isLoadingOther: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private handleTranslateService: HandleTranslateService,
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService,
    private masterDataService: MasterDataService
  ) {
    this.checkPermission();
    this.filterColumn();
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private checkPermission(): void {
    if (!this.authenticationService.isRdSummaryPermission()) {
      this.router.navigate(['/']);
    }
  }

  private filterColumn(): void {
    let columns = [...this.columns];
    const removeColumns = (colsToRemove: string[]) => columns = columns.filter(x => !colsToRemove.includes(x.id));
    if (!this.authenticationService.isViewerImpAndHelp()) {
      removeColumns([ 'companyName' ]);
    }
    this.columns = columns;
  }

  public isPermissionDatatableTools(): boolean {
    return !this.authenticationService.isViewerImpAndHelp();
  }

  private initialSetting(): void {
    this.isLoading = true;
    zip(
      this.loadCompany(),
      this.loadBranch(),
      this.loadRdSummaryStatus(),
      this.loadYear(),
      this.loadDocumentType()
    )
    .pipe()
    .subscribe({
      next: ([ companies, branches, status, years, documentType ]) => {
        this.years = years;
        this.companies = companies;
        this.rdStatus = status;
        this.documentType = documentType;
        this.defaultBranches = branches.results;
        if (!this.authenticationService.isSCBBCM() && this.companies.length === 1) {
          const defaultCompany = this.companies[0].id;
          this.form.controls['corporate_id'].setValue(defaultCompany, { emitEvent: false });
          this.filterBranches(defaultCompany);
        } else {
          this.companies.unshift(this.allSelect);
          this.filterBranches();
        }
        this.loadRdSummary();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    })
  }

  private loadDocumentType(): Observable<DocumentTypeModel[]> {
    return of(this.masterDataService.getDocumentType())
            .pipe(
              map(res => {
                const newRes: DocumentTypeModel[] = res.filter(x => x.is_sent_to_rd).map(x => {
                        return { type: x.type, name_th: x.name_th, name_en: x.name_en }
                      });
                      newRes.unshift(this.allDocumentType);
                return newRes;
              })
            );
  }

  private loadRdSummaryStatus(): Observable<LookupModel[]> {
    const status = [ ...[ this.allSelect ], ...STATUS ];
    return of(status);
  }

  private loadYear(): Observable<number[]>{
    const currentYear = this.currentDate.getFullYear();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
    return of(years);
  }

  public filterBranches(companyId?: number | string | null) {
    this.form.controls['corporate_branch_id'].setValue(null);
    this.branches = Object.assign([] as BranchModel[], this.defaultBranches)
                      .filter(x => x.corporate === companyId || !companyId)
                      .map(x => {
                        const filterName = x.code + '/' +  x.rd_id + '/' + x.name;
                        return { name_th: filterName, name_en: filterName, id: x.id };
                      });
    this.branches.unshift(this.allSelect);          
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.apiService
      .getCompany()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(res => {
          const newRes =  res as ResponseMessageCompanyModel;
          return [ ...newRes.results ].map(x => {
            return { name_th: x.name, name_en: x.name, id: x.id }
          });
        })
      );
  }

  private loadBranch(): Observable<ResponseMessageBranchModel> {
    return this.apiService
        .getBranch()
        .pipe(
          takeUntil(this.unsubscribe$),
          map(x => x as ResponseMessageBranchModel)
        );
  }

  public onSearch(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.submitted = false;
    this.pages = 1;
    this.datatablesElement?.reset();
    this.loadRdSummary();
  }

  public onClear(): void {
    const defaultCorportateId = this.companies.length === 1 ? this.companies[0].id : null;
    const formControl = this.form.controls;
          formControl['corporate_id'].setValue(defaultCorportateId);
          formControl['corporate_branch_id'].setValue(null);
          formControl['rd_document_type'].setValue(null);
          formControl['month'].setValue(this.currentDate.getMonth() + 1);
          formControl['year'].setValue(this.currentDate.getFullYear());
          formControl['document_status'].setValue(null);
    this.filterBranches(defaultCorportateId);
    this.pages = 1;
    this.submitted = false;
    this.loadRdSummary();
  }

  public pageChanged(): void {
    this.loadRdSummary();
  }

  public limitRowChanged(event: number): void {
    this.limitRow = event;
    this.pages = 1;
    this.loadRdSummary();
  }

  private loadRdSummary(): void {
    this.rows = [];
    this.isLoadingRows = true;
    const formValue = this.form.value;
    const payload: PayloadRdSummaryModel = {
      corporate_id: formValue['corporate_id'] ?? undefined,
      corporate_branch_id: formValue['corporate_branch_id'] ?? undefined,
      year: formValue['year'],
      month: formValue['month'],
      rd_document_type: formValue['rd_document_type'] ?? undefined,
      document_status: formValue['document_status'] ?? undefined
    };
    this.httpClientService
      .post(`${ environment.apiURL }/api/rd_summary?page=${ this.pages }&page_size=${ this.limitRow }`, payload)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(res => {
          const newResponse = Object.assign({}, res as ResponseMessageRdSummaryModel);
          const newRdSummary = Object.assign([] as RdSummaryReportModel[], newResponse.results)
                                .map(x => {
                                  const newValue =  Object.assign({}, x);
                                        newValue.branch_tax_id = newValue.branch_tax_id + newValue.branch_code;
                                  return newValue;
                                });
          newResponse.results = newRdSummary; 
          return newResponse;
        })
      )
      .subscribe({
        next: (res) => {
          this.collectionSize = res.total_sent;
          this.rows = res.results ?? [];
          this.isLoadingRows = false;
          this.numberTotal = res.total_document;
          this.sentToSPTotal = res.total_sent;
          this.rdAcknowledgedTotal = res.total_acknowledge;
          this.acceptedTotal = res.total_accepted;
          this.rejectedTotal = res.total_rejected;
          this.pendingTotal = res.total_pending;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingRows = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onRowActionEvent(event: RowActionEventModel): void {
    if (event.action === 'notes') {
      this.showNotesModal(event.row['error_note']);
    }
  }

  private showNotesModal(notes?: string) {
    this.handleModalService
      .textModal({
        title: 'NEW-TRANSLATE.COMMON.DETAIL',
        cancelButton: {
          id: 'closeModalButton',
          show: true,
          name: 'NEW-TRANSLATE.COMMON.CLOSE',
          class: 'btn-dv btn-wide btn-clean'
        },
        texts: notes ? [ { text: notes } ] : [{ text: '-' }]
      });
  }

}
