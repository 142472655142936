<div class="row justify-content-center mt-2 mb-1">
  <div class="col-md-6">
    <ul id="progressbar">
      <li [ngClass]="{ 'active': step === 0 }">
        {{ 'NEW-TRANSLATE.COMMON.CHOOSE-TYPE-OF-DOCUMENT' | translate }}
      </li>
      <li [ngClass]="{ 'active': step === 1 }">
        {{ 'NEW-TRANSLATE.COMMON.UPLOAD-FILE' | translate }}
      </li>
      <li [ngClass]="{ 'active': step === 2 }">
        {{ 'NEW-TRANSLATE.COMMON.CREATE-FILE' | translate }}
      </li>
    </ul>
  </div>
</div>

@if (step === 0) {
  <div class="upload-container row justify-content-center">
    <div class="col">
      <h4 class="row header">
        {{ 'NEW-TRANSLATE.DOCUMENT-UPLOAD.CHOOSE-TYPE-OF-DOCUMENT-TO-CONVERT-TYPE-FILE-TO-XML-PDF-A-3' | translate }}
      </h4>
      <div class="row box-parent text-center px-3 px-sm-5">
        <div class="col-md-6" *ngFor="let documentType of documentTypeList">
          <div
            class="type-option"
            [ngClass]="{ 'selected-type': form.controls['doc_type'].value?.type === documentType.type }"
            (click)="onSelectDocumentType(documentType)"
          >
            <img src="../../../../assets/images/csv.png" class="me-4" alt="File Icon">
            {{ documentType.name_en }}
            <br>
            {{ documentType.name_th }}
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if (step === 1) {
  <div class="upload-container row justify-content-center">
    <div class="col">
      <h4 class="row header">
        {{ 'NEW-TRANSLATE.DOCUMENT-UPLOAD.CONVERT-TYPE-FILE-TO-XML-PDF-A-3' | translate }} - {{ language === 'th' ? form.controls['doc_type'].value?.name_th : form.controls['doc_type'].value?.name_en }}
      </h4>
      <div class="row box-parent px-3 px-sm-5">
        <div class="upload-file-wrapper text-center">
          <div
            ngfDrop
            [accept]="acceptTypes"
            [(file)]="file"
            [fileDropDisabled]="isLoadingSummary"
            [maxSize]="limitSize"
            (fileChange)="onChangeFile($event)"
          >
            <div class="inside-text">
              {{ 'NEW-TRANSLATE.COMMON.DRAG-FILE-HERE' | translate }}<br>
              {{ 'NEW-TRANSLATE.COMMON.OR' | translate }}<br>
              <div
                ngfSelect
                [accept]="acceptTypes"
                class="btn btn-dv btn-clean btn-wide"
                [fileDropDisabled]="isLoadingSummary"
                [(file)]="file"
                [maxSize]="limitSize"
                (fileChange)="onChangeFile($event)"
              >{{ 'NEW-TRANSLATE.COMMON.CHOOSE-FILE' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="mt-3 p-0 mb-2" *ngIf="file">
          @if (file && file.type === 'text/csv') {
            <form [formGroup]="form" class="form-wrapper">
              <div class="d-block d-sm-flex align-items-sm-center gap-2 encoding-wrapper ms-auto">
                <label for="encodingTypeSelect" class="mb-3 mb-sm-0">Encoding</label>
                <p-dropdown
                  class="dv-dropdown flex-1"
                  id="encodingTypeSelect"
                  data-testid="encodingTypeSelect"
                  formControlName="encoding"
                  [options]="encodingType"
                  optionLabel="label"
                  optionValue="value"
                  selectId="encodingTypeSelect"
                >
                </p-dropdown>
              </div>
            
            </form>
          }
       
          <h3 id="tableFileDescription" class="text-start">{{ 'NEW-TRANSLATE.COMMON.FILE' | translate }}</h3>
          <table class="upload-table" aria-describedby="tableFileDescription">
            <thead>
              <tr>
                <th class="column-name">{{ 'NEW-TRANSLATE.COMMON.NAME' | translate }}</th>
                <th class="column-type">{{ 'NEW-TRANSLATE.COMMON.TYPE' | translate }}</th>
                <th class="column-size">{{ 'NEW-TRANSLATE.COMMON.SIZE' | translate }}</th>
                <th class="column-action">{{ 'NEW-TRANSLATE.COMMON.ACTION' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="column-name">
                  <strong>{{ file.name }}</strong>
                </td>
                <td class="column-type">
                  {{ fileTypes | findByKey: 'type' : file.type }}
                </td>
                <td class="column-size">
                  {{ file.size/1024/1024 | number:'.2' }} MB
                </td>
                <td class="column-action">
                  <button
                    class="btn-icon btn-icon-delete"
                    [disabled]="isLoadingSummary"
                    type="button"
                    [title]="'NEW-TRANSLATE.COMMON.DELETE' | translate"
                    (click)="onRemoveFile()"
                  >
                    <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="m-t-30 text-center">
    <button
      class="btn btn-dv btn-clean btn-wide m-r-5"
      data-testid="backStepButton"
      id="backStepButton"
      (click)="nextStep(0)"
      [disabled]="isLoadingSummary"
      [title]="'NEW-TRANSLATE.COMMON.BACK' | translate"
    >
      <fa-icon [icon]="['fas', 'chevron-left']" class="m-r-5"></fa-icon>
      {{ 'NEW-TRANSLATE.COMMON.BACK' | translate }}
    </button>
    <button
      class="btn btn-dv btn-wide"
      data-testid="nextStepButton"
      id="nextStepButton"
      (click)="onGetSummary()"
      [disabled]="isLoadingSummary || form.invalid || !file"
      [title]="'NEW-TRANSLATE.COMMON.NEXT' | translate"
    >
      @if (isLoadingSummary) {
        <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
      }
      {{ 'NEW-TRANSLATE.COMMON.NEXT' | translate }}
      <fa-icon [icon]="['fas', 'chevron-right']" class="m-r-5"></fa-icon>
    </button>
  </div>
}

@if (step === 2) {
  <div class="summary-container row justify-content-center">
    <div class="col">
      <h4 class="row header">
        {{ 'NEW-TRANSLATE.COMMON.RESULT' | translate }}
      </h4>
      <div class="row box-parent px-3 px-sm-5">
        <div class="col-12 col-sm-4 pt-3">
          <div class="card-summary text-center">
            {{ 'NEW-TRANSLATE.COMMON.NUMBER-OF-DOCUMENTS' | translate }}
            <div class="total-summary">{{ totalDocument | number: '0.0-0' }} {{ 'NEW-TRANSLATE.COMMON.FILE' | translate }}</div>
          </div>
        </div>
        <div class="col-12 col-sm-4 pt-3">
          <div class="card-summary text-center">
            {{ 'NEW-TRANSLATE.COMMON.VALUE-ADDED-TAX' | translate }}
            <div class="total-summary">{{ totalVat | number: '0.2-2' }} {{ 'NEW-TRANSLATE.COMMON.BAHT' | translate }}</div>
          </div>
        </div>
        <div class="col-12 col-sm-4 pt-3">
          <div class="card-summary text-center">
            {{ 'NEW-TRANSLATE.COMMON.AMOUNT' | translate }}
            <div class="total-summary">{{ totalPayment |  number: '0.2-2' }} {{ 'NEW-TRANSLATE.COMMON.BAHT' | translate }}</div>
          </div>
        </div>
      </div>
      @if (isError && errorMessage) {
        <div class="text-center mt-4">
          <h4>{{ 'NEW-TRANSLATE.ERROR.TRY-AGAIN' | translate }}</h4>
          <div>{{ errorMessage | translate }}</div>
        </div>
      } 
      @if (isError && errorsMessage && errorsMessage.length > 0) {
        <div class="row box-parent mt-4">
          <div class="col">
            <div class="row">
              <div class="col">
                <h4 id="tableErrorDescription">{{ 'NEW-TRANSLATE.ERROR.ERROR' | translate }}</h4>
              </div>
            </div>
            <div class="row">
              <div class="col scrollable mt-3">
                <table class="table text-center" aria-describedby="tableErrorDescription">
                  <thead>
                    <tr>
                      <th>{{ 'NEW-TRANSLATE.COMMON.ROW' | translate}}</th>
                      <th class="text-left">{{ 'NEW-TRANSLATE.COMMON.MESSAGE' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    @for (error of errorsMessage; track error) {
                      <tr>
                        <td>{{ error.row }}</td>
                        <td class="text-left">
                          @if (error.message | isArray) {
                            @for (message of error.message; track message) {
                              <p>{{ message | translate }}</p>
                            }
                          } @else {
                            <p>{{ ((error.message | isString) ? error.message : '' | translate) }}</p>
                          }  
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div class="text-center">
              {{ 'NEW-TRANSLATE.DOCUMENT-UPLOAD.NOTICE' | translate }}
            </div>
          </div>
        </div>
      }
      @if (isLoadingGenerate || isLoadingUploadStatus) {
        <div class="row box-parent text-center mt-4">
          <div class="col text-center">
            <app-loading-spinner [isLoading]="true"></app-loading-spinner>
            <div class="mb-2">
              {{ ('NEW-TRANSLATE.DOCUMENT-UPLOAD.WAITING-FOR-CHANGE-{{type}}-FILE-TO-XML-PDF-A-3' | translate: { type: (fileTypes | findByKey: 'type' : file?.type)  }) }}
            </div>
            <div class="mb-4">
              {{ uploadProgress + ' %' }}
            </div>
            <div>
              {{ 'NEW-TRANSLATE.GUILD.PLEASE-DONT-CLOSE-THE-WINDOW' | translate }}
            </div>
          </div>
        </div>
      }
      @if (isUploadSuccess && taxImportId && taxImportId >= 0) {
        <div class="mt-4">
          <app-document-upload-datatable
            [taxImportId]="taxImportId"
          />
        </div>
      }
    </div>
  </div>
  <div class="m-t-30 text-center">
    @if (isUploadSuccess) {
      <button
        class="btn btn-dv btn-clean btn-wide m-r-5"
        data-testid="createANewFileButton"
        id="createANewFileButton"
        (click)="createANewFile()"
        [title]="'NEW-TRANSLATE.COMMON.CREATE-A-NEW-FILE' | translate"
      >
        {{ 'NEW-TRANSLATE.COMMON.CREATE-A-NEW-FILE' | translate }}
      </button>
    } @else {
      <button
        class="btn btn-dv btn-clean btn-wide m-r-5"
        data-testid="backStepButton"
        id="backStepButton"
        (click)="nextStep(1)"
        [disabled]="isLoadingGenerate || isLoadingUploadStatus"
        [title]="'NEW-TRANSLATE.COMMON.BACK' | translate"
      >
        <fa-icon [icon]="['fas', 'chevron-left']" class="m-r-5"></fa-icon>
        {{ 'NEW-TRANSLATE.COMMON.BACK' | translate }}
      </button>
      @if (!isError) {
        <button
          class="btn btn-dv btn-wide"
          data-testid="createFileButton"
          id="createFileButton"
          (click)="onGenerate()"
          [disabled]="isLoadingGenerate || form.invalid || !file || isLoadingUploadStatus"
          [title]="'NEW-TRANSLATE.COMMON.CREATE-FILE' | translate"
        >
          @if (isLoadingGenerate) {
            <fa-icon class="spinner-border mr-1" [icon]="['fas','spinner']"></fa-icon>
          }
          {{ 'NEW-TRANSLATE.COMMON.CREATE-FILE' | translate }}
        </button>
      }
    }
  </div>
}
