import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';
import { AuthenticationService } from '../../service/authentication.service';
import { get as lodashGet } from 'lodash';
import { BroadcastService, HandleTranslateService, IdleService } from '../../shared/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private broadcastService: BroadcastService,
    private idleService: IdleService,
   
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
            .pipe(
              catchError(
                (err: HttpErrorResponse) => {
                  const errorDetail = lodashGet(err, 'error.detail');
                  if (this.shouldHandleError(errorDetail)) {
                    this.handleError(errorDetail);
                    return of({} as HttpEvent<unknown>);
                  }
                  return throwError(() => err);
                }
              )
            );
  }
  
  private shouldHandleError(errorDetail: string): boolean {
    const errorMessages = [
      'Signature has expired.',
      'Error decoding signature.',
      'Invalid signature.',
      'User account is disabled.',
      'บัญชีผู้ใช้ถูกระงับ',
      'Given token not valid for any token type',
      'Authentication credentials were not provided.',
      'another_user_login'
    ];
    return errorMessages.includes(errorDetail);
  }

  private handleError(errorDetail: string): void {
    this.idleService.clearIdle();
    if (errorDetail === 'another_user_login') {
      this.authenticationService.onAnotherUserLogin();  
      this.broadcastService.sendMessage({ type: 'another-user-login' });
    } else {
      this.broadcastService.sendMessage({ type: 'logout' });
      this.authenticationService.onLogout();
    }
  }


}