<div class="data-purge-result-container">
  <p-toast position="top-right"></p-toast>
  <div class="header-container">
    <div class="header">
      <div class="row">
        <div class="col-md text-end">
          <button
            class="btn-icon"
            [disabled]="isLoadingRows || isLoadingOther || isDataPurgeInProgress"
            [title]="'NEW-TRANSLATE.COMMON.BACK' | translate"
            (click)="onBack()"
          >
            <fa-icon
              class="text-red"
              [icon]="['fas', 'circle-xmark']"
              size="xl"
            ></fa-icon>
          </button>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md">
          <h3>{{ 'NEW-TRANSLATE.DATA-PURGE.RESULT.TITLE' | translate }}</h3>
          <h5>
            {{ 'NEW-TRANSLATE.DATA-PURGE.RESULT.TOTAL-NUMBER-TO-DELETE-{{value}}-FILES' | translate: { value: totalItems } }} | {{ 'NEW-TRANSLATE.COMMON.SIZE' | translate }} {{ totalSize | formatByte: 3 }}
          </h5>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-auto d-flex gap-2 flex-wrap">
          @if (!isDataPurgeInProgress) {
            <button
              class="btn btn-dv btn-wide"
              [disabled]="selectedRows.length === 0 || isLoadingRows || isLoadingOther || isDataPurgeInProgress"
              data-testid="startDataPurgeButton"
              id="startDataPurgeButton"
              type="button"
              [title]="'NEW-TRANSLATE.DATA-PURGE.RESULT.START-PURGE-FILES' | translate"
              (click)="onStartDataPurge()"
            >
              {{ 'NEW-TRANSLATE.DATA-PURGE.RESULT.START-PURGE-FILES' | translate }}
            </button>
          }
          <!-- @if (isDataPurgeInProgress) {
            <button
              class="btn btn-dv btn-wide"
              data-testid="stopDataPurgeButton"
              id="stopDataPurgeButton"
              type="button"
              [title]="'NEW-TRANSLATE.DATA-PURGE.RESULT.STOP-PURGE-FILES' | translate"
              (click)="onStopDataPurge()"
            >
              {{ 'NEW-TRANSLATE.DATA-PURGE.RESULT.STOP-PURGE-FILES' | translate }}
            </button>
          } -->
          @if (!isDataPurgeInProgress) {
            <button
              class="btn btn-dv"
              [disabled]="selectedRows.length === 0 || isLoadingRows || isLoadingOther || isDataPurgeInProgress"
               data-testid="deselectDataPurgeButton"
              id="deselectDataPurgeButton"
              type="button"
              [title]="'NEW-TRANSLATE.DATA-PURGE.RESULT.DESELECT-FILES-FOR-PURGE' | translate"
              (click)="onDeselectDataPurge()"
            >
              {{ 'NEW-TRANSLATE.DATA-PURGE.RESULT.DESELECT-FILES-FOR-PURGE' | translate }}
            </button>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="datatables-container">
    <app-datatables
      #datatables
      [columns]="columns"
      [isLoadingRows]="isLoadingRows"
      [isLoadingOther]="isLoadingOther || isDataPurgeInProgress"
      [limitRow]="limitRow"
      [rows]="rows"
      [(selectedRows)]="selectedRows"
      tableId="dataPurgeResultDatatable"
    />
  </div>
</div>