<div class="header">
  <h3 id="pageTitle">{{'BILLING.TITLE' | translate}}</h3>
</div>

<div class="box-filter position-relative z-2">
  <app-filter-header [loading]="loading" (submit)="search()" (clear)="clearSearch()"></app-filter-header>

  <div class="row align-items-center">
    <div class="col-6 col-md-3">
      <div class="dropdown-label-group">
        <p-dropdown
          class="dv-dropdown"
          selectId="month"
          [options]="monthListDropdown"
          [(ngModel)]="selectedMonth"
          [autoDisplayFirst]="false"
          placeholder="{{'FILTER.MONTH' | translate}}"
        >
        </p-dropdown>
        <label for="month">{{'FILTER.MONTH' | translate}}</label>
      </div>
    </div>
    <div class="col-6 col-md-3">
      <div class="dropdown-label-group">
        <p-dropdown
          class="dv-dropdown"
          selectId="year"
          [options]="yearList"
          [(ngModel)]="selectedYear"
          [autoDisplayFirst]="false"
          placeholder="{{'FILTER.YEAR' | translate}}"
        >
        </p-dropdown>
        <label for="year">{{'FILTER.YEAR' | translate}}</label>
      </div>
    </div>
    <div class="col-md-6">
      <p-checkbox class="dv-checkbox" [(ngModel)]="selectedDuplictedData" binary="true"></p-checkbox>
      <span>{{'BILLING.DOCUMENTS-SIGNED-MORE' | translate}}</span>
      <br/>
      <p-checkbox class="dv-checkbox" [(ngModel)]="includeInB2POnly" binary="true"></p-checkbox>
      <span>{{'BILLING.DOCUMENTS-B2P-ONLY' | translate}}</span>
    </div>
  </div>
</div>

<div class="box-parent position-relative z-1" *ngIf="totalItems === 0 && loading === false">
  <div class="row m-t-20">
    <div class="col-12 text-center">
      <h5>{{'COMMON.DATA-NOT-FOUND' | translate}}</h5>
    </div>
  </div>
</div>

<div class="box-parent position-relative z-1" *ngIf="loading">
  <div class="row m-t-20">
    <div class="col-12 text-center">
      <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
    </div>
  </div>
</div>

<div class="box-parent position-relative z-1" *ngIf="totalItems !== 0 && loading === false">
  <div class="row justify-content-between align-items-center">
    <div class="col-md-auto">
      <h5>{{'BILLING.FOUND-DIGITAL-SIGNATURES' | translate}} <span class="progress-text">{{ totalItems }}</span> {{'COMMON.TIMES' | translate}}</h5>
    </div>
    <div class="col-md-auto">
      <app-custom-pagination
        [(currentPage)]="currentPage"
        [(itemsPerPage)]="itemsPerPage"
        [totalItems]="totalItems"
        [hideItemsPerPage]="true"
        [rightSide]="true"
        (pageChange)="pageChanged()"
      ></app-custom-pagination>
    </div>
  </div>

  <div class="scrollable m-t-30">
    <div class="m-b-10" style="text-align: right">
      <button type="button" class="btn btn-dv btn-wide" [disabled]="loading_excel_report"  (click)="DownloadExcelReport()">
        <fa-icon *ngIf="loading_excel_report" class="spinner-border m-r-5" [icon]="['fas','spinner']" size="2xl"></fa-icon>
        {{'COMMON.DOWNLOAD' | translate}}
      </button>
    </div>

    <table class="table text-center" aria-describedby="pageTitle">
      <thead>
        <tr>
          <th>{{'COMMON.TABLE.ORDER' | translate}}</th>
          <th>{{'FILTER.DOCUMENT-TYPE' | translate}}</th>
          <th>{{'กกก' | translate}}</th>
          <th>{{'FILTER.DOCUMENT-NUMBER' | translate}}</th>
          <th>{{'BILLING.SOURCE' | translate}}</th>
          <th>{{'BILLING.SIGNATURE-DATE' | translate}}</th>
          <th>{{'BILLING.FILE-SIZE' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let res of list; let index = index">
          <td>{{((currentPage - 1) * 10) + index + 1}}</td>
          <td>{{res.document_type}}</td>
          <td>{{res.seller_tax_id}}</td>
          <td>{{res.invoice_number}}</td>
          <td>{{res.source}}</td>
          <td>{{res.created_at | date:"dd/MM/yyyy HH:mm" }}</td>
          <td>{{ humanFileSize(res.size_bytes) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
