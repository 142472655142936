import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDropdown, Placement } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { Calendar } from 'primeng/calendar';

type PeriodDateType = [ Date | undefined, Date | undefined] | undefined;

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true
    }
  ]
})
export class DateRangePickerComponent implements OnInit, OnDestroy {

  @ViewChild('calendarStart') public calendarStart!: Calendar;
  @ViewChild('calendarEnd') public calendarEnd!: Calendar;

  @Input() public placeholder: string = 'NEW-TRANSLATE.PLACEHOLDER.SELECT-DATE';
  @Input() public label: string = 'NEW-TRANSLATE.COMMON.DATE';
  @Input() public container: 'body' | null = 'body';
  @Input() public placement: Placement = 'auto';

  public startDate: Date | undefined;
  public endDate: Date | undefined;
  public minStartDate: Date | undefined;
  public maxStartDate: Date | undefined;
  public minEndDate: Date | undefined;
  public maxEndDate: Date | undefined;

  @Output() onChangeDate = new EventEmitter<PeriodDateType>();
  private onTouched!: Function;
  private onChanged!: Function;

  private mutationObserver: MutationObserver | undefined;

  @Input() public date: PeriodDateType;
  @Input() public invalid: boolean = false;
  @Input() public maxRange: number = 0;
  @Input() public clearable: boolean = false;
  @Input() public isLoading: boolean = false;

  public dateValue: PeriodDateType;
  public dateString: string | undefined;
  public format: string = 'DD/MM/YYYY';

  constructor(
    private elementRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.settingObservable();
  }

  ngOnDestroy(): void {
    this.mutationObserver?.disconnect();
  }

  settingObservable() {
    this.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const currentEl = this.elementRef.nativeElement as HTMLElement;
        if (currentEl.classList.contains('is-invalid')) {
          this.invalid = true;
        } else {
          this.invalid = false;
        }
      });
    });

    this.mutationObserver.observe(this.elementRef.nativeElement, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  writeValue(date: PeriodDateType): void {
    this.initialValue(date);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private initialValue(date: PeriodDateType): void {
    if (date && date.length > 1 && date.every(x => x instanceof Date)) {
      const newDates = Object.assign([] as Date[] as [ Date, Date ], date);
      const startDate = new Date(newDates[0]);
      const endDate = new Date(newDates[1]);
      this.date = [ startDate, endDate ];
      this.dateValue = [ startDate, endDate ];
      this.dateString = this.transformDate(this.dateValue);
      this.startDate = startDate;
      this.endDate = endDate;
      this.setMaxAndMinEndDate(startDate);
    } else {
      this.clearDate()
    }
  }

  public changeDate(isStart: boolean, dropdownEl: NgbDropdown): void {
    if (isStart && this.startDate) {
      this.setMaxAndMinEndDate(this.startDate);
      this.dateValue = [ this.startDate, undefined ];
    } else {
      this.dateValue = [ this.startDate, this.endDate ];
    }
    if (this.dateValue?.length === 2 && this.dateValue.every(x => x instanceof Date)) {
      this.date = Object.assign([], this.dateValue);
      this.dateString = this.transformDate(this.date);
      this.changeDateEvent(this.date);
      dropdownEl.close();
    }
  }

  private changeDateEvent(date: PeriodDateType) {
    this.onChangeDate.emit(date);
    if(this.onTouched) {
      this.onTouched();
    }
    if(this.onChanged) {
      this.onChanged(date);
    }
  }

  clearDate() {
    this.date = undefined;
    this.dateValue = undefined
    this.dateString = undefined;
    this.startDate = undefined;
    this.endDate = undefined;
    this.maxStartDate = undefined;
    this.minStartDate = undefined;
    this.maxEndDate = undefined;
    this.minEndDate = undefined;
    this.onChangeDate.emit(this.date);
    if(this.onTouched) {
      this.onTouched();
    }
    if(this.onChanged) {
      this.onChanged(this.date);
    }
  }

  public setMaxAndMinEndDate(startDate?: Date): void {
    if (this.maxRange && startDate) {
      const maxDate = moment(startDate).add(this.maxRange, 'days').toDate();
      this.maxEndDate = maxDate; 
      this.minEndDate = startDate;
    } else {
      this.minEndDate = this.startDate;
    }
  }

  private transformDate(date: PeriodDateType): string | undefined {
    if (date?.length === 2 && date.every(x => x instanceof Date)) {
      const start = moment(date[0]).format(this.format);
      const end = moment(date[1]).format(this.format);
      return start + ' - ' + end;
    }
    return undefined;
  }

  public dropdownChange(event: boolean): void {
    if (event) {
      this.initialValue(this.date);
    } else {
      this.calendarStart.view = 'date';
      this.calendarEnd.view = 'date';
      this.dateValue = undefined;
      this.startDate = undefined;
      this.endDate = undefined;
      this.maxStartDate = undefined;
      this.minStartDate = undefined;
      this.maxEndDate = undefined;
      this.minEndDate = undefined;
    }
  }

}
