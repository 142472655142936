import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { KeyinService } from "./keyin.service";

@Injectable({
  providedIn: 'root'
})
export class KeyinValidators {

  constructor(
    private keyinService: KeyinService
  ) {
  }

  taxIdForJuristic(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (value && value.length === 13) {
      if (this.keyinService.validatorThaiID(value)) {
        return null;
      } else {
        return { id: true };
      }
    } else {
      return { id: true };
    }
  }

  taxIdForIndividual(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;
    if (value && (value.length === 13 || value === "N/A")) {
      if (value === "N/A" || this.keyinService.validatorThaiID(value)) {
        return null;
      } else {
        return { id: true };
      }
    } else {
      return { id: true };
    }
  }

}
