import { Component } from '@angular/core';
import { Observable, catchError, concatMap, delay, finalize, from, map, of, takeWhile, tap, throwError } from 'rxjs';
import { ApiService } from '../../../../service/api.service';
import { PayloadDownloadFileModel } from '../../../interfaces';
import { HandleModalService, UtilitiesService } from '../../../services';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientService } from '../../../../service/http-client.service';

@Component({
  selector: 'app-download-file-modal',
  templateUrl: './download-file-modal.component.html',
  styleUrl: './download-file-modal.component.scss'
})
export class DownloadFileModalComponent {

  public downloads: PayloadDownloadFileModel[] = [];

  public downloadFileFromMediaPath: boolean = false;

  public total: number = 0;
  public currentIndex: number = 0;
  public isLoading: boolean = false;
  public loadingProgress: number = 0;

  public isError: string | undefined;
  public isSuccess: boolean = false;

  public cancelDownload: boolean = false;

  public modalEl: NgbModalRef | undefined;

  constructor(
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService
  ) {

  }

  ngOnInit(): void {
    this.startDownload();
  }

  ngOnDestroy(): void {
    this.cancelDownload = true;
  }

  public startDownload(): void {
    this.isLoading = true;
    this.total = this.downloads.length;
    this.currentIndex = 0;
    from(this.downloads)
      .pipe(
        delay(500),
        takeWhile(() => !this.cancelDownload),
        concatMap((item, index) => {
          if (this.downloadFileFromMediaPath) {
            return this.downloadFileFromUrl(item, index).pipe(delay(1000));
          } else {
            return this.downloadFileFromPayload(item, index).pipe(delay(1000));
          }
        }),
        catchError((error) => {
          this.isError = error;
          this.isLoading = false;
          return throwError(() => error);
        }),
        finalize(() => {
          if (!this.cancelDownload && !this.isError) {
            this.isSuccess = true;
          }
          this.isLoading = false;
        })
      )
      .subscribe();
  }
  
  private downloadFileFromUrl(item: PayloadDownloadFileModel, index: number): Observable<Blob | null> {
    const downloadUrl = item.url;
    if (!downloadUrl) {
      this.updateProgress(index + 1);
      return of(null);
    }
    return this.httpClientService
            .getBlob(downloadUrl)
            .pipe(
              map(x => x as Blob),
              tap((res) => {
                if (this.cancelDownload) return;
                this.utilitiesService.downloadFile(res, item.filename ?? 'unknow');
                this.updateProgress(index + 1);
              }),
              catchError((error) => {
                console.error('Download error:', error);
                this.updateProgress(index + 1);
                return throwError(() => error);
              })
            );
  }

  private downloadFileFromPayload(item: PayloadDownloadFileModel, index: number): Observable<Blob | null> {
    if (!item.payload) {
      this.updateProgress(index + 1);
      return of(null);
    }
    return this.apiService
      .getAllZipFile(item.payload)
      .pipe(
        tap((res) => {
          if (this.cancelDownload) return;
          this.utilitiesService.downloadFile(res, item.filename ?? 'unknow');
          this.updateProgress(index + 1);
        }),
        catchError((error) => {
          console.error('Download error:', error);
          this.updateProgress(index + 1);
          return throwError(() => error);
        })
      );
  }

  private updateProgress(current: number): void {
    this.currentIndex = current;
    this.loadingProgress = (current / this.total) * 100;
  }

  public onClose(): void {
    this.cancelDownload = true;
    this.handleModalService.closeModal(this.modalEl);
  }

  public onDismiss(): void {
    this.cancelDownload = true;
    this.handleModalService.dismissModal(this.modalEl);
  }

}
