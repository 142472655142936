import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';
import { ApiService } from '../../service/api.service';
import { NotificationService } from '../../service/notification.service';
import { HttpParams } from '@angular/common/http';
import { HandleTranslateService } from '../../shared/services';

@Component({
  selector: 'app-approval-history',
  templateUrl: './approval-history.component.html',
  styleUrl: './approval-history.component.scss'
})
export class ApprovalHistoryComponent implements OnInit {

  public loading = false;
  public monthListDropdown: any[] = [];
  public selectedMonth: any;
  public yearList: any[] = [];
  public selectedYear: any;
  public statusList: any[] = [];
  public selectedStatus: string | null | undefined = '';
  public currentPage: number = 1;
  public itemsPerPage: number = 10;
  public totalItems: number = 0;
  public transactionDataList: any[] = [];
  public tableCol: any[] = [];

  constructor(
    private notificationService: NotificationService,
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private handleTranslateService: HandleTranslateService
  ) {

  }

  ngOnInit(): void {
    this.initialSetting();
  }

  initialSetting(): void {
    if (!(this.isChecker() || this.isSuperChecker())) {
      this.router.navigate(['']);
    } else {
      this.handleTranslateService
        .language
        ?.pipe()
        .subscribe(() => {
          this.loadTexts();
        });
      this.loadTexts();
      this.populateDropdown();
      this.getHistoryList();
    }
  }

  isViewerOperation(): boolean {
    return this.authenticationService.isViewerOperation();
  }

  isViewerImpAndHelp(): boolean {
    return this.authenticationService.isViewerImpAndHelp();
  }

  isChecker(): boolean {
    return this.authenticationService.isChecker();
  }

  isSuperChecker(): boolean {
    return this.authenticationService.isSuperChecker();
  }

  getHistoryList(): void {
    this.loading = true;
    let params = new HttpParams()
      .set('updated_at__month', this.selectedMonth.toString())
      .set('updated_at__year', this.selectedYear.toString())
      .set('page', this.currentPage.toString())
      .set('page_size', this.itemsPerPage.toString());
    if (this.selectedStatus) {
      params = params.set('request_status', this.selectedStatus.toString().toLowerCase());
    } else {
      params = params.set('request_status__in', 'approved,rejected');
    }
    this.apiService
      .getApprovalHistory(params)
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.transactionDataList = res.results.map((obj: any) => {
            obj.is_active = obj.is_active == null ? true : obj.is_active;
            if (obj.request_status) {
              if (obj.request_status.toLowerCase().includes('approve')) {
                obj.request_status = "Approved";
              } else if (obj.request_status.toLowerCase().includes('reject')) {
                obj.request_status = "Rejected";
              }
            }
            if (obj.is_reset_password) {
              obj.request_type = "Reset Password";
            } else if (!obj.is_active) {
              obj.request_type = "Disable User";
            } else if (obj.request_type == 'update') {
              obj.request_type = "Edit Role User";
            } else if (obj.request_type == 'create') {
              obj.request_type = "Create User";
            } else {
              obj.request_type = "-";
            }
            return obj;
          });
          this.totalItems = res.count;
        },
        error: (err) => {
          this.loading = false;
          this.notificationService.showErrorNotification(err.error);
          this.transactionDataList = [];
        }
      });
  }

  loadTexts(): void {
    this.statusList = [
      { value: null, label: this.handleTranslateService.translate('COMMON.ALL') },
      { value: 'Approved', label: 'Approved' },
      { value: 'Rejected', label: 'Rejected' }
    ];
    this.monthListDropdown = [];
    this.monthListDropdown.push(
      { label: this.handleTranslateService.translate('MONTH.JANUARY'), value: 1 },
      { label: this.handleTranslateService.translate('MONTH.FEBRUARY'), value: 2 },
      { label: this.handleTranslateService.translate('MONTH.MARCH'), value: 3 },
      { label: this.handleTranslateService.translate('MONTH.APRIL'), value: 4 },
      { label: this.handleTranslateService.translate('MONTH.MAY'), value: 5 },
      { label: this.handleTranslateService.translate('MONTH.JUNE'), value: 6 },
      { label: this.handleTranslateService.translate('MONTH.JULY'), value: 7 },
      { label: this.handleTranslateService.translate('MONTH.AUGUST'), value: 8 },
      { label: this.handleTranslateService.translate('MONTH.SEPTEMBER'), value: 9 },
      { label: this.handleTranslateService.translate('MONTH.OCTOBER'), value: 10 },
      { label: this.handleTranslateService.translate('MONTH.NOVEMBER'), value: 11 },
      { label: this.handleTranslateService.translate('MONTH.DECEMBER'), value: 12 }
    );
  }

  populateDropdown(): void {
    let currentDate = new Date();
    // set current month
    this.selectedMonth = currentDate.getMonth() + 1;
    let thisYear = currentDate.getFullYear();
    for (let i = 0; i <= 10; i++) {
      this.yearList?.push({ label: (thisYear - i).toString(), value: thisYear - i });
    }
    this.selectedYear = thisYear;
    this.selectedStatus = null;
  }

  searchButtonClicked(): void {
    this.currentPage = 1;
    this.getHistoryList();
  }

  clearData(): void {
    let currentDate = new Date();
    // set current month and year
    this.selectedMonth = currentDate.getMonth() + 1;
    this.selectedYear = currentDate.getFullYear();
    this.loading = false;
    this.selectedStatus = null;
    this.currentPage = 1;
    this.totalItems = 0;
    this.transactionDataList = [];
    this.searchButtonClicked();
  }

  pageChanged() {
    this.getHistoryList();
  }

  setPageSide(event: any) {
    this.itemsPerPage = event;
    this.currentPage = 1;
    this.pageChanged();
  }

}
