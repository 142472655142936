import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../../core/core.module';
import { CheckboxModule } from 'primeng/checkbox';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedComponentModule } from '../../shared/components/shared-component.module';

import { EmailDashboardRoutingModule } from './email-dashboard-routing.module';
import { EmailDashboardComponent } from './email-dashboard.component';
import { SharedPipeModule } from '../../shared/pipes/shared-pipe.module';
import { EmailDashboardResendEmailComponent } from './components/email-dashboard-resend-email/email-dashboard-resend-email.component';
import { NgxMaskDirective } from 'ngx-mask';

@NgModule({
  declarations: [
    EmailDashboardComponent,
    EmailDashboardResendEmailComponent
  ],
  imports: [
    CommonModule,
    EmailDashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,    
    TranslateModule,
    CoreModule,
    CheckboxModule,
    FontAwesomeModule,
    SharedComponentModule,
    FontAwesomeModule,
    SharedPipeModule,
    NgxMaskDirective
  ],
  providers: [
  ]
})
export class EmailDashboardModule { }
