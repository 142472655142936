import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';

@Pipe({
  name: 'formarrayToFormgroup'
})
export class FormarrayToFormgroupPipe implements PipeTransform {

  transform(control: AbstractControl): FormGroup[] {
    return (control as FormArray).controls as FormGroup[];
  }

}
