import { Component, OnDestroy } from '@angular/core';
import { BroadcastService, FontawesomeService, HandleModalService, HandleTranslateService, IdleService } from './shared/services';
import { PrimeNGConfig } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { AuthenticationService } from './service/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    private fontawesomeService: FontawesomeService,
    private primengConfig: PrimeNGConfig,
    private handleTranslateService: HandleTranslateService,
    private handleModalService: HandleModalService,
    private broadcastService: BroadcastService,
    private authenticationService: AuthenticationService,
    private idleService: IdleService
  ) {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.broadcastService.closeChannel();
  }

  private initialSetting(): void {
    this.handleModalService.startSubscriptionModals();
    this.fontawesomeService.initialIcon();
    this.watchBroadcast();
    this.handleTranslateService
      .language
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.handleTranslateService.get('NEW-TRANSLATE.PRIME-NG-CALENDAR').subscribe(x => this.primengConfig.setTranslation(x));
      });


  }

  private watchBroadcast(): void {
    this.broadcastService
      .listen()
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((message) => {
        if (message.type === 'language-change') {
          this.handleTranslateService.setLanguage(message.value);
        }
        if (message.type === 'login') {
          window.location.reload();
        }
        if (message.type === 'logout') {
          this.idleService.clearIdle();
          this.authenticationService.onLogout();
        }
        if (message.type === 'session-timeout') {
          this.idleService.clearIdle();
          this.idleService.onSessionExpired();
        }
        if (message.type === 'another-user-login') {
          this.idleService.clearIdle();
          this.authenticationService.onAnotherUserLogin();
        }
      });
  }

}
