import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FeeDebitAccountComponent } from './fee-debit-account.component';

export const routes: Routes = [
  {
    path : 'fee-debit-account',
    component : FeeDebitAccountComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeeDebitAccountRoutingModule { }
