@if (form) {
  <div class="keyin-section-4-container box-parent section-box mb-2">
    <h5 class="section-title">{{ 'NEW-TRANSLATE.COMMON.LINE-ITEMS-DETAIL' | translate }}</h5>
    <form [formGroup]="form" class="form-wrapper">
      <div class="line-items-detail-wrapper">
        <label class="form-label">{{ 'NEW-TRANSLATE.COMMON.VAT-%' | translate }}</label>
        <div class="mb-3 d-flex gap-3 flex-wrap">
          <div class="d-flex align-items-center">
            <p-radioButton 
              formControlName="vat_type"
              inputId="perHeaderRadio"
              [value]="1"
              (onClick)="onChangeVatType($event)"
            />
            <label for="perHeaderRadio" class="ms-2">
              {{ 'NEW-TRANSLATE.COMMON.PER-HEADER' | translate }}
            </label>
            <div class="ms-2 vat-percentage-wrapper">
              <ng-select
                autocomplete="off"
                class="form-control ng-select-vat-percentage"
                [clearable]="false"
                data-testid="vatPercentageSelect"
                formControlName="vat_percentage"
                id="vatPercentageSelect"
                [items]="vatList"
                [readonly]="isLoading || form.controls['vat_type'].value !== 1"
                [searchable]="false"
                (change)="onChangeVatPercentage($event)"
                [ngClass]="{ 'is-invalid': submitted && form.controls['vat_percentage'].errors }"
              >
              </ng-select>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <p-radioButton 
              formControlName="vat_type"
              inputId="perItemRadio"
              [value]="2 "
              (onClick)="onChangeVatType($event)"
            />
            <label for="perItemRadio" class="ms-2">
              {{ 'NEW-TRANSLATE.COMMON.PER-ITEM' | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="line-item-datatable-wrapper">
        <p-table
          #lineItemTable
          [loading]="isLoading"
          [value]="form.controls['line_items'] | formarrayToFormgroup"
          [scrollable]="true"
          tableStyleClass="table"
          class="form-datatable"
        >
          <ng-template pTemplate="header">
            <tr>
              @for (column of columns; track column.id; let i = $index) {
                @if (column.frozen && column.alignFrozen) {
                  <th
                    pFrozenColumn
                    [alignFrozen]="column.alignFrozen"
                    [class]="column.headerClass ?? null"
                    [id]="column.id + 'Column'"
                    [style]="column.headerStyle ?? null"
                    [style.minWidth]="column.width"
                    [style.width]="column.width"
                  >{{ column.label | translate }}</th>
                } @else {
                  <th
                    [class]="column.headerClass ?? null"
                    [id]="column.id + 'Column'"
                    [style]="column.headerStyle ?? null"
                    [style.minWidth]="column.width"
                    [style.width]="column.width"
                  >{{ column.label | translate }}</th>
                }
              }
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
            <tr>
              @for (column of columns; track column.id;) {
                @if (column.frozen && column.alignFrozen) {
                  <td
                    pFrozenColumn
                    [attr.data-testid]="column.id + (rowIndex + 1) + 'Cell'"
                    [alignFrozen]="column.alignFrozen"
                    [class]="column.cellClass ?? null"
                    [id]="column.id + rowIndex + 'Cell'"
                    [style]="column.cellStyle ?? null"
                    [style.minWidth]="column.width"
                    [style.width]="column.width"
                  >
                    <ng-container
                      [ngTemplateOutlet]="columnEl"
                      [ngTemplateOutletContext]="{ rowIndex: rowIndex, column: column, formGroup: data }"
                    />
                  </td>
                } @else {
                  <td
                    [attr.data-testid]="column.id + (rowIndex + 1) + 'Cell'"
                    [class]="column.cellClass ?? null"
                    [id]="column.id + rowIndex + 'Cell'"
                    [style]="column.cellStyle ?? null"
                  >
                    <ng-container
                      [ngTemplateOutlet]="columnEl"
                      [ngTemplateOutletContext]="{ rowIndex: rowIndex, column: column, formGroup: data }"
                    />
                  </td>
                }
              }
            </tr>
          </ng-template>
        </p-table>
        <div class="text-end mt-3">
          <button
            class="btn btn-dv"
            data-testid="addLineItemButton"
            id="addLineItemButton"
            type="button"
            [title]="'NEW-TRANSLATE.COMMON.ADD-ITEM' | translate"
            (click)="addLineItem()"
          >
            <fa-icon [icon]="['fas', 'plus']"></fa-icon> {{ 'NEW-TRANSLATE.COMMON.ADD-ITEM' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
}

<ng-template #columnEl let-rowIndex="rowIndex" let-column="column" let-formGroup="formGroup">
  @switch (column.type) {
    @case ('number') {
      <form
        [id]="column.id + (rowIndex + 1) + 'Form'"
        [attr.data-testid]="column.id + (rowIndex + 1) + 'Form'"
        [formGroup]="formGroup"
      >
        <input
          [attr.data-testid]="column.id + (rowIndex + 1) + 'Input'"
          [class]="column.inputClass ?? ''"
          [formControlName]="column.formControlName"
          [id]="column.id + (rowIndex + 1) + 'Input'"
          mask="d*"
          [maxlength]="column.maxLength ?? null"
          [minlength]="column.minLength ?? null"
          [readonly]="
            (column.readonly && datatableReadonly)
            || (column.eitherOrProp ? (form.controls['line_items'] | formarrayToFormgroup)[rowIndex].controls[column.eitherOrProp].value : false)"
          [style]="column.cellStyle ?? null"
          type="text"
          (change)="column.eitherOrProp && $event ? onChangeEitherOr(rowIndex, column.eitherOrProp, column.eitherOrPropValue) : false"
          [ngClass]="{ 'is-invalid': submitted && formGroup.controls[column.formControlName].errors }"
        />
      </form>
    }
    @case ('currency') {
      <form
        [id]="column.id + (rowIndex + 1) + 'Form'"
        [attr.data-testid]="column.id + (rowIndex + 1) + 'Form'"
        [formGroup]="formGroup"
      >
        <input
          currencyMask
          [attr.data-testid]="column.id + (rowIndex + 1) + 'Input'"
          [class]="column.inputClass ?? ''"
          [formControlName]="column.formControlName"
          [id]="column.id + (rowIndex + 1) + 'Input'"
          inputmode="numeric"
          [maxlength]="column.maxLength ?? null"
          [minlength]="column.minLength ?? null"
          [readonly]="
            (column.readonly && datatableReadonly)
            || (column.eitherOrProp ? (form.controls['line_items'] | formarrayToFormgroup)[rowIndex].controls[column.eitherOrProp].value != 0 : false)"
          type="text"
          (change)="column.eitherOrProp && $event ? onChangeEitherOr(rowIndex, column.eitherOrProp, column.eitherOrPropValue) : false"
          [ngClass]="{ 'is-invalid': submitted && formGroup.controls[column.formControlName].errors }"
        >
      </form>
    }
    @case ('id') {
      <form
        [id]="column.id + (rowIndex + 1) + 'Form'"
        [attr.data-testid]="column.id + (rowIndex + 1) + 'Form'"
        [formGroup]="formGroup"
      >
        <input
          [attr.data-testid]="column.id + (rowIndex + 1) + 'Input'"
          [class]="column.inputClass ?? ''"
          [formControlName]="column.formControlName"
          [id]="column.id + (rowIndex + 1) + 'Input'"
          mask="A*"
          [maxlength]="column.maxLength ?? null"
          [minlength]="column.minLength ?? null"
          [patterns]="customPatterns"
          [readonly]="
            (column.readonly && datatableReadonly)
            || (column.eitherOrProp ? (form.controls['line_items'] | formarrayToFormgroup)[rowIndex].controls[column.eitherOrProp].value : false)"
          [style]="column.cellStyle ?? null"
          type="text"
          (change)="column.eitherOrProp && $event ? onChangeEitherOr(rowIndex, column.eitherOrProp, column.eitherOrPropValue) : false"
          [ngClass]="{ 'is-invalid': submitted && formGroup.controls[column.formControlName].errors }"
        />
      </form>
    }
    @case ('no') {
    }
    @case ('string') {
      <form
        [id]="column.id + (rowIndex + 1) + 'Form'"
        [attr.data-testid]="column.id + (rowIndex + 1) + 'Form'"
        [formGroup]="formGroup"
      >
        <input
          [attr.data-testid]="column.id + (rowIndex + 1) + 'Input'"
          [class]="column.inputClass ?? ''"
          [formControlName]="column.formControlName"
          [id]="column.id + (rowIndex + 1) + 'Input'"
          [maxlength]="column.maxLength ?? null"
          [minlength]="column.minLength ?? null"
          [readonly]="
            (column.readonly && datatableReadonly)
            || (column.eitherOrProp ? (form.controls['line_items'] | formarrayToFormgroup)[rowIndex].controls[column.eitherOrProp].value : false)"
          type="text"
          (change)="column.eitherOrProp && $event ? onChangeEitherOr(rowIndex, column.eitherOrProp, column.eitherOrPropValue) : false"
          [style]="column.cellStyle ?? null"
          type="text"
          [ngClass]="{ 'is-invalid': submitted && formGroup.controls[column.formControlName].errors }"
        />
      </form>
    }
    @case ('select-list') {
      <form
        [id]="column.id + (rowIndex + 1) + 'Form'"
        [attr.data-testid]="column.id + (rowIndex + 1) + 'Form'"
        [formGroup]="formGroup"
      >
        <ng-select
          autocomplete="off"
          appendTo=".line-item-datatable-wrapper"
          [attr.data-testid]="column.id + (rowIndex + 1) + 'Input'"
          [class]="column.inputClass ?? ''"
          [clearable]="false"
          [formControlName]="column.formControlName"
          [id]="column.id + (rowIndex + 1) + 'Input'"
          id="vatPercentageSelect"
          [items]="column.list ?? []"
          [readonly]="column.readonly && datatableReadonly"
          [searchable]="false"
          [ngClass]="{ 'is-invalid': submitted && formGroup.controls[column.formControlName].errors }"
          (change)="onChangeSelectList(column.formControlName ,$event)"
        >
        </ng-select>
      </form>
    }
    @case ('action') {
      @switch (column.formControlName) {
        @case('delete') {
          <button
            *ngIf="form && (form.controls['line_items'] | formarrayToFormgroup).length > 1"
            [attr.data-testid]="column.id + (rowIndex + 1) + 'DeleteButton'"
            class="btn-img btn-img-delete"
            [id]="column.id + (rowIndex + 1) + 'DeleteButton'"
            [title]="'NEW-TRANSLATE.COMMON.DELETE' | translate"
            type="button"
            (click)="deleteLineItem(rowIndex)"
          ><fa-icon [icon]="['fas', 'trash']" size="lg"></fa-icon></button>
        }
        @default {
          Unknow
        }
      }
    }
    @default {
      Unknow
    }
  }
</ng-template>