import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequireResetPasswordGuard } from '../../core/guards';
import { EmailDashboardComponent } from './email-dashboard.component';

export const routes: Routes = [
  {
    path: 'email-dashboard',
    canActivate : [ RequireResetPasswordGuard ],
    component: EmailDashboardComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmailDashboardRoutingModule { }
