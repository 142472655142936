<p-toast position="top-right"></p-toast>
<div class="container-fluid login-container">
  <div class="row align-items-md-center login-container-row">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-lg-6 col-xl-6 title-block d-flex d-md-block flex-column justify-content-end pb-3">
      <div class="d-none d-md-block">
        <div class="e-tax-title mt-2">SCB E-TAX INVOICE & <span class="text-nowrap">E-RECEIPT</span></div>
        <div class="e-tax-subtitle">One Stop Service for E-Tax Invoice & Receipt</div>
      </div>
      <div class="d-block d-md-none">
        <img style="height:50px;" src="../../assets/images/scb_logo_white_color.png" alt="Logo">
      </div>
      
      <app-announcement-message/>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 login-right-container-row">
      <div class="login-form-container d-flex align-items-start align-items-md-center justify-content-center h-md-100">
        <div class="login-form-wrapper">
          <img
            class="login-logo d-none d-md-block"
            src="../../assets/images/scb_logo_main_color_2.png"
            alt="Logo"
          >
  
          <div class="welcome-text mt-md-4">Welcome</div>
          Please login to your account.
  
          <div class="form-group mt-2 mb-md-2 mb-3">
            <input class="form-control form-control-lg" id="username" name="username" placeholder="Username"
                  type="text" [(ngModel)]="username" (keyup.enter)="login()">
          </div>
          <div class="form-group m-0">
            <input class="form-control form-control-lg" id="password" name="password" placeholder="Password"
                  type="password" [(ngModel)]="password" (keyup.enter)="login()">
          </div>
  
          <div class="text-red text-center my-1 d-block">
            {{ errorMessage ? errorMessage : '&nbsp;' | translate }}
          </div>
  
          <div class="d-flex align-items-center flex-wrap gap-3 justify-content-center justify-content-md-start">
            <button class="btn btn-dv btn-wider" (click)="login()">
              Login
            </button>
            <div class="reset-password" (click)="forgotPassword(fgPassword)">
              Forgot password ?
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #fgPassword let-modal>
  <div class="modal-body">
    <h4>Request New Password</h4>

    <div class="form-group mt-2 mb-sm-2">
      <label for="email-fg">E-mail</label>
      <input class="form-control form-control-lg" id="email-fg" name="e-mail" placeholder="E-mail"
             type="text" [(ngModel)]="resetEmail" (keyup.enter)="resetPassword()">
    </div>
    <div class="form-group mt-2 mb-sm-2">
      <label for="username-fg">Username</label>
      <input class="form-control form-control-lg" id="username-fg" name="username" placeholder="Username"
             type="text" [(ngModel)]="resetUsername" (keyup.enter)="resetPassword()">
    </div>
    <div *ngIf="!isLoading">
      <re-captcha #recaptchaRef (resolved)="recaptChaResolved($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
    </div>

    <div class="text-red" *ngIf="errorForgotpassword">
      {{errorForgotpassword}}
    </div>
  </div>
  <div class="modal-footer-alternative">
    <div class="d-flex justify-content-end">
      <button class="btn btn-dv btn-wide" [disabled]="!resetUsername || !resetEmail || !recaptchaToken || isLoading" (click)="resetPassword()">
        <fa-icon *ngIf="isLoading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
          Confirm
      </button>
      <button class="btn btn-dv btn-wide btn-clean ms-3" (click)="close()">
        Cancel
      </button>
    </div>
  </div>

</ng-template>
