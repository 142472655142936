import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../service/authentication.service';
import { Router } from '@angular/router';
import { HttpClientService } from '../../service/http-client.service';
import { HandleModalService, UtilitiesService } from '../../shared/services';
import { environment } from '../../../environments/environment';
import { PayloadSaveAnnouncementModel } from './announcement.interface';
import { Subject, map, takeUntil } from 'rxjs';
import { ResponseMessageAnnouncementModel } from '../../shared/interfaces';
import moment from 'moment';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrl: './announcement.component.scss'
})
export class AnnouncementComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    use_login_announcement: new FormControl(false, [ Validators.required ]),
    login_announce_message: new FormControl(undefined),
    login_announce_start_date: new FormControl(undefined),
    login_announce_start_time: new FormControl(undefined),
    login_announce_end_date: new FormControl(undefined),
    login_announce_end_time: new FormControl(undefined)
  });

  public isLoading: boolean = false;
  public isLoadingSave: boolean = false;

  public startDateMinDate: Date | undefined = new Date(new Date().setHours(0, 0, 0, 0));
  public startDateMaxDate: Date | undefined;

  public endDateMinDate: Date | undefined;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService
  ) {
    this.checkPermission();
  }

  private checkPermission(): void {
    if (!this.authenticationService.isAnnouncementPermission()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.loadAnnouncement();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private loadAnnouncement(): void {
    this.isLoading = true;
    this.httpClientService
      .get(`${ environment.apiURL }/api/system_setting`)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(x => x as ResponseMessageAnnouncementModel)
      )
      .subscribe({
        next: (res) => {
          const startDatetime = res.login_announce_start_date ? new Date(res.login_announce_start_date) : undefined;
          const endDatetime = res.login_announce_end_date ? new Date(res.login_announce_end_date) : undefined;
          const formControl = this.form.controls;
                formControl['use_login_announcement'].setValue(res.use_login_announcement);
                formControl['login_announce_message'].setValue(res.login_announce_message || undefined);
                formControl['login_announce_start_date'].setValue(startDatetime);
                formControl['login_announce_start_time'].setValue(startDatetime);
                formControl['login_announce_end_date'].setValue(endDatetime);
                formControl['login_announce_end_time'].setValue(endDatetime);
          this.endDateMinDate = startDatetime;
          this.startDateMaxDate = endDatetime;
          this.isLoading = false;
        },
        error: (err) => {
          console.error(err);   
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onChangeStartDate(date: Date | undefined): void {
    const formControl = this.form.controls;
    if (date) {
      this.endDateMinDate = date;
      formControl['login_announce_start_time'].setValue(new Date(new Date(date).setHours(0, 0 , 0 , 0)))
    } else {
      this.endDateMinDate = undefined;
      formControl['login_announce_start_time'].setValue(undefined);
    }
  }

  public onChangeEndDate(date: Date | undefined): void {
    if (date) {
      this.startDateMaxDate = date;
      this.form.controls['login_announce_end_time'].setValue(new Date(new Date(date).setHours(23, 59, 0 , 0)))
    } else {
      this.startDateMaxDate = undefined;
      this.form.controls['login_announce_end_time'].setValue(undefined);
    }
  }

  public onSave(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.isLoadingSave = true;
    const formValue = this.form.value;
    const payload: PayloadSaveAnnouncementModel = {
      use_login_announcement: formValue['use_login_announcement'],
      login_announce_message: formValue['login_announce_message'] || null,
      login_announce_start_date: formValue['login_announce_start_date'] ? this.transformDatetimeForSave(true, formValue['login_announce_start_date'], formValue['login_announce_start_time']) : null,
      login_announce_end_date: formValue['login_announce_end_date'] ? this.transformDatetimeForSave(false, formValue['login_announce_end_date'], formValue['login_announce_end_time']) : null
    };
    this.httpClientService
      .put(`${ environment.apiURL }/api/system_setting/update`, payload)
      .pipe()
      .subscribe({
        next: (res) => {
          this.handleModalService.successModal({
            title: 'NEW-TRANSLATE.COMMON.SUCCESSFULLY',
            cancelButton: {
              id: 'closeModalButton',
              show: true,
              name: 'NEW-TRANSLATE.COMMON.CLOSE',
              class: 'btn-dv btn-wide'
            }
          });
          this.isLoadingSave = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingSave = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  private transformDatetimeForSave(startDate: boolean, date: Date, time: Date | undefined): string {
    const newDate = new Date(date);
    if (time) {
      const newTime = new Date(time);
            newDate.setHours(newTime.getHours(), newTime.getMinutes(), 0, 0);
    } else {
      if (startDate) {
        newDate.setHours(0, 0, 0, 0);
      } else {
        newDate.setHours(23, 59, 0, 0);
      }
    }
    return moment(newDate).format();
  }

}
