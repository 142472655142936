import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findByKey'
})
export class FindByKeyPipe implements PipeTransform {

  transform(items: any[], key: string, find: any): any {
    if (!items || items.length === 0 || find === undefined || find === null ) return null;
    return items.find(item => item[key] === find)?.value || null;
  }

}
