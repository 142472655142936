import { LookupModel } from "../../shared/interfaces";

export const SEND_TO_RD_CONFIGURATION: LookupModel[] = [
  {
    id: 0,
    name_th: '-',
    name_en: '-'
  },
  {
    id: 1,
    name_th: 'กดส่งด้วยตนเอง',
    name_en: 'Manual Send'
  },
  {
    id: 2,
    name_th: 'เลือกส่งอัตโนมัติ',
    name_en: 'Auto Send'
  }
];