import { Component } from '@angular/core';

@Component({
  selector: 'app-page-expired',
  templateUrl: './page-expired.component.html',
  styleUrl: './page-expired.component.scss'
})
export class PageExpiredComponent {

}
