import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DatatableCustomColumnModel, PayloadDownloadFileModel, RowActionEventModel } from '../../../../shared/interfaces';
import { HttpClientService } from '../../../../service/http-client.service';
import { environment } from '../../../../../environments/environment';
import { Subject, concatMap, map, range, reduce, takeUntil } from 'rxjs';
import { HandleModalService, UtilitiesService } from '../../../../shared/services';
import { DocumentUploadResultModel, ResponseMessageDocumentUploadResultModel } from '../../document-upload.interface';

@Component({
  selector: 'app-document-upload-datatable',
  templateUrl: './document-upload-datatable.component.html',
  styleUrl: './document-upload-datatable.component.scss'
})
export class DocumentUploadDatatableComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();

  @Input() public taxImportId!: number;

  public pages: number = 1;
  public limitRow: number = 40;
  public collectionSize: number = 0;
  public rows: DocumentUploadResultModel[] = [];
  public columns: DatatableCustomColumnModel[] = [
    {
      id: 'sellerTaxId',
      label: 'NEW-TRANSLATE.COMMON.SELLER-TAX-ID',
      name: 'seller_tax_id',
      type: 'string',
      width: '250px',
      minWidth: '250px'
    },
    {
      id: 'documentNumber',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-NUMBER',
      name: 'header_invoice_number',
      type: 'string',
      width: '250px',
      minWidth: '250px'
    },
    {
      id: 'XML',
      label: 'XML',
      name: 'xml',
      type: 'action-icon',
      width: '140px',
      minWidth: '140px',
      format: 'xml'
    },
    {
      id: 'PDF',
      label: 'PDF',
      name: 'pdf',
      type: 'action-icon',
      width: '140px',
      minWidth: '140px',
      format: 'pdf'
    }
  ];

  public isLoadingRows: boolean = false;
  public isLoadingOther: boolean = false;

  public errorMessage: string | undefined;
  public isError: boolean = false;

  constructor(
    private httpClientService: HttpClientService,
    private handleModalService: HandleModalService,
    private utilitiesService: UtilitiesService
  ) {

  }

  ngOnInit(): void {
    this.loadUploadResult();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public pageChanged(): void {
    this.loadUploadResult();
  }

  private loadUploadResult(): void {
    this.clearError();
    const payload = { tax_import_id: this.taxImportId };
    this.isLoadingRows = true;
    this.httpClientService
      .post(`${ environment.apiURL }/api/tax_data_upload_result/?page=${ this.pages }`, payload)
      .pipe(
        map(x => x as ResponseMessageDocumentUploadResultModel)
      )
      .subscribe({
        next: (res) => {
          this.collectionSize = res.count;
          this.rows = res.results ?? [];
          this.isLoadingRows = false;
        },
        error: (err) => {
          console.error(err);
          this.isError = true;
          this.errorMessage = 'NEW-TRANSLATE.ERROR.THE-DATA-HAS-BEEN-SUCCESSFULLY-ENTERED-INTO-THE-SYSTEM'; 
        }
      });
  }

  private clearError(): void {
    this.isError = false;
    this.errorMessage = undefined;
  }

  public onRowActionEvent(event: RowActionEventModel): void {
    if (event.action === 'xml') {
      this.onDownload(event);
    }
    if (event.action === 'pdf') {
      this.onDownload(event);
    }
  }

  public onDownloadAll(type: string): void {
    this.isLoadingOther = true;
    const pageCount = Math.ceil(this.collectionSize / this.limitRow);
    const payload = { tax_import_id: this.taxImportId };
    const fileType = type === 'pdf' ? '.pdf' : '.xml'
    range(1, pageCount)
      .pipe(
        takeUntil(this.unsubscribe$),
        concatMap(page => 
          this.httpClientService
            .post(`${environment.apiURL}/api/tax_data_upload_result/?page=${ page }`, payload)
            .pipe(
              map((res) => {
                const newRes = res as ResponseMessageDocumentUploadResultModel;
                return newRes.results;
              })
            )
        ),
        reduce((acc: DocumentUploadResultModel[], current: DocumentUploadResultModel[]) => acc.concat(current), [])
      )
      .subscribe({
        next: (res) => {
          const downloads = Object.assign([] as DocumentUploadResultModel[], res)
          .map(x => {
              return {
                type: type,
                filename: this.getFileName(x.unique_invoice_number) + fileType,
                url: `${ environment.apiURL }/api/download_document/${ type }/${ x.id }`
              } as PayloadDownloadFileModel;
            });
          this.handleModalService
            .downloadFileModal(downloads, true)
            .result
            .then(() => {
              this.isLoadingOther = false;
            })
            .catch(() => this.isLoadingOther = false)
        },
        error: (err) => {
          console.error(err);
          this.isLoadingOther = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onDownload(rowEvent: RowActionEventModel): void {
    this.isLoadingOther = true;
    this.utilitiesService.toggleLoadingCell(rowEvent.targetElement, rowEvent.loadingElement);
    const data: DocumentUploadResultModel = rowEvent.row;
    const fileType = rowEvent.action === 'pdf' ? '.pdf' : '.xml'
    this.httpClientService
      .getBlob(`${ environment.apiURL }/api/download_document/${ rowEvent.action }/${ data.id }`)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(x => x as Blob)
      )
      .subscribe({
        next: (res) => {
          const filename = this.getFileName(data.unique_invoice_number) + fileType;
          this.utilitiesService.downloadFile(res, filename);
          this.isLoadingOther = false;
          this.utilitiesService.toggleLoadingCell(rowEvent.targetElement, rowEvent.loadingElement);
        },
        error: (err) => {
          console.error(err);
          this.isLoadingOther = false;
          this.utilitiesService.toggleLoadingCell(rowEvent.targetElement, rowEvent.loadingElement);
          if (!this.handleModalService.hasModal('failedModal')) {
            if (err.error instanceof Blob) {
              this.utilitiesService
                .convertBlobToJson(err.error)
                .subscribe({
                  next: (json) => {
                    const errorMessage = this.utilitiesService.transformErrorsToTextModal(json);
                    this.handleModalService.connectFailedModal(errorMessage);
                  },
                  error: () => {
                    this.handleModalService.connectFailedModal();
                  }
                });
            } else {
              const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
              this.handleModalService.connectFailedModal(errorMessage);
            }
          }
        }
      });
  }

  private getFileName(invoice_number: string): string {
    return invoice_number.replace(/[^-\w.ก-๙]+/g, '_');
  }

}
