<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.SALES-TAX-REPORT.TITLE' | translate }}</h3>
    </div>
  </div>
</div>

<div class="sales-tax-report-container">
  <div class="criteria-search-container">
    <app-filter-header
      [loading]="isLoading || isLoadingRows || isLoadingOther"
      (submit)="onSearch()"
      (clear)="onClear()"
    />
    <div class="criteria-search-wrapper">
      <form [formGroup]="form" class="form-wrapper">
        <div class="row g-2">
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_id"
                selectId="companySelect"
                [options]="companies"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                (onChange)="filterBranches($event.value)"
                [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="companySelect">{{ 'NEW-TRANSLATE.COMMON.COMPANY' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_branch_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_branch_id"
                selectId="branchSelect"
                [options]="branches"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [placeholder]="'NEW-TRANSLATE.COMMON.BRANCH' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="branchSelect">{{ 'NEW-TRANSLATE.COMMON.BRANCH' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['rd_document_type'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="rd_document_type"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="documentType"
                optionValue="type"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
                selectId="documentTypeSelect"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="documentTypeSelect">{{ 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
             <app-date-range-picker
              formControlName="docu_date_period"
              label="NEW-TRANSLATE.COMMON.DOCUMENT-DATE"
              [maxRange]="93"
              [ngClass]="{ 'is-invalid': submitted && form.controls['docu_date_period'].errors }"
              [isLoading]="isLoading || isLoadingRows || isLoadingOther"
             ></app-date-range-picker>
          </div>
          <div class="col-12 col-md-6 col-lg-8 d-flex align-items-center gap-1">
            <p-checkbox
              binary="true"
              class="dv-checkbox"
              inputId="notApprovedCheckbox"
              formControlName="not_approved_only"
              [readonly]="isLoading || isLoadingRows || isLoadingOther"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_branch_id'].errors }"
            ></p-checkbox>
            <label for="notSentCheckbox">{{ 'NEW-TRANSLATE.SALES-TAX-REPORT.ONLY-ITEMS-THAT-HAVE-NOT-BEEN-CHECKED' | translate }}</label>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="datatables-container">
    <app-custom-pagination
      [(currentPage)]="pages"
      [(itemsPerPage)]="limitRow"
      [totalItems]="collectionSize"
      [isLoading]="isLoading || isLoadingRows || isLoadingOther"
      (pageChange)="pageChanged()"
      (optionChangeValue)="limitRowChanged($event)"
    />
    <app-datatables
      #datatables
      [collectionSize]="collectionSize"
      [columns]="columns"
      [isLoadingRows]="isLoadingRows"
      [isLoadingOther]="isLoadingOther"
      [limitRow]="limitRow"
      [pages]="pages"
      [rows]="rows"      
      tableId="salesTaxReportDatatable"
      [(sortBy)]="sortBy"
      [(sortMode)]="sortMode"
      (onRowActionEvent)="onRowActionEvent($event)"
      (onSortEvent)="onSortEvent()"
    />
  </div>
</div>
