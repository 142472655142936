<div
  data-testid="downloadFileModal"
  id="downloadFileModal"
>
  <div class="modal-body">
    <div class="download-file-wrapper">
      @if (isLoading) {
        <div class="spinner-wrapper">
          <fa-icon class="spinner-border" [icon]="['fas','spinner']" size="xl"></fa-icon>
        </div>
        <div class="progress-info-wrapper">
          {{ currentIndex }} / {{ total }}
        </div>
        
        <div class="progressbar-warpper">
          <ngb-progressbar type="info" [value]="loadingProgress" />
        </div>
      }

      @if (!isLoading && isError) {
        <div class="download-error-wrapper">
          <span>{{ 'NEW-TRANSLATE.ERROR.TRY-AGAIN' | translate }}</span>
        </div>
      }

      @if (!isLoading && isSuccess) {
        <div class="download-success-wrapper">
          <span>{{ 'NEW-TRANSLATE.MODAL-TEXT.DOWNLOAD-COMPLETE-ALL-{{total}}-ITEMS' | translate: { total: total } }}</span>
        </div>
      }
    </div>
  </div>
  <div class="modal-footer">
    @if (isSuccess) {
      <button
        data-testid="closeDownloadModalButton"
        id="closeDownloadModalButton"
        class="btn btn-dv btn-clean btn-wide"
        [title]="'NEW-TRANSLATE.COMMON.CLOSE'| translate"
        (click)="onClose()"
      >{{ 'NEW-TRANSLATE.COMMON.CLOSE' | translate }}</button>
    } @else {
      <button
        data-testid="cancelDownloadModalButton"
        id="cancelDownloadModalButton"
        class="btn btn-dv btn-danger btn-wide"
        [title]="'NEW-TRANSLATE.COMMON.CANCEL' | translate"
        (click)="onDismiss()"
      >{{ 'NEW-TRANSLATE.COMMON.CANCEL' | translate }}</button>
    }
  </div>
</div>
