import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subject, map, takeUntil } from 'rxjs';
import { CountryModel, DistrictModel, ProvinceModel, SubdistrictModel } from '../../keyin.interface';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { KeyinService } from '../../keyin.service';
import { HttpClientService } from '../../../../service/http-client.service';
import { environment } from '../../../../../environments/environment';
import { RadioButtonClickEvent } from 'primeng/radiobutton';
import { HandleTranslateService, UtilitiesService } from '../../../../shared/services';
import { CUSTOM_PATTERN_NUMBER } from '../../../../shared/services/shared.constant';
import { KEYIN_VALIDATORS } from '../../keyin-constant';
import { LanguageType, LookupModel } from '../../../../shared/interfaces';

@Component({
  selector: 'app-keyin-section-3',
  templateUrl: './keyin-section-3.component.html',
  styleUrls: [ './keyin-section-3.component.scss', '../../keyin.component.scss' ]
})
export class KeyinSection3Component implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();
  
  public language: LanguageType;

  public validators = KEYIN_VALIDATORS;

  @Input() public form!: FormGroup;
  @Input() public submitted: boolean = false;
  @Input() public isLoading: boolean = false;

  public buyerTypes: { label: string, value: number }[] = [
    { value: 2, label: 'NEW-TRANSLATE.COMMON.JURISTIC' },
    { value: 1, label: 'NEW-TRANSLATE.COMMON.INDIVIDUAL' },
    { value: 3, label: 'NEW-TRANSLATE.COMMON.FOREIGNER' }
  ];

  public customPattern = CUSTOM_PATTERN_NUMBER;

  public provinces: ProvinceModel[] = [];
  public districts: DistrictModel[] = [];
  public subdistricts: SubdistrictModel[] = [];

  public isLoadingProvince: boolean = false;
  public isLoadingDistrict: boolean = false;
  public isLoadingSubdistrict: boolean = false;

  public countries: CountryModel[] = [];

  public isLoadingCountry: boolean = false;

  constructor(
    private handleTranslateService: HandleTranslateService,
    private keyinService: KeyinService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService
  ) {
    this.subscribeToServices();
  }

  ngOnInit(): void {
      this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => {
      this.language = x;
      this.countries = this.utilitiesService.sortArrayForTranslate(x, this.countries, [ 'name_th', 'name_en' ]);
      this.provinces = this.utilitiesService.sortArrayForTranslate(x, this.provinces, [ 'name_th', 'name_en' ]);
      this.districts = this.utilitiesService.sortArrayForTranslate(x, this.districts, [ 'name_th', 'name_en' ]);
      this.subdistricts = this.utilitiesService.sortArrayForTranslate(x, this.subdistricts, [ 'name_th', 'name_en' ]);
    });
  }

  private initialSetting(): void {
    this.settingType(this.form.controls['buyer_type'].value);
  }
  

  settingType(event: 1 | 2 | 3) {
    const typeSpecificSettings = {
      1: () => this.setupType1(),
      2: () => this.setupType2(),
      3: () => this.setupType3()
    };
    typeSpecificSettings[event]();
  }

  private setupType1() {
    const fieldsToSet = [
      { field: 'buyer_name', validators: [ Validators.required ] },
      // { field: 'buyer_taxid', validators: [ (control: AbstractControl) => this.keyinValidators.taxIdForIndividual(control), Validators.required, Validators.maxLength(13), Validators.minLength(3) ] },
      { field: 'buyer_taxid', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.buyer_taxid.individual.maxLength), Validators.minLength(this.validators.buyer_detail.buyer_taxid.individual.minLength) ] },
      { field: 'email_to' },
      { field: 'house_no', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.house_no.maxLength) ] },
      { field: 'building_name', validators: [ Validators.maxLength(this.validators.buyer_detail.building_name.maxLength) ] },
      { field: 'floor', validators: [ Validators.maxLength(this.validators.buyer_detail.floor.maxLength) ] },
      { field: 'alley', validators: [ Validators.maxLength(this.validators.buyer_detail.alley.maxLength) ] },
      { field: 'moo_no', validators: [ Validators.maxLength(this.validators.buyer_detail.moo_no.maxLength) ] },
      { field: 'road', validators: [ Validators.maxLength(this.validators.buyer_detail.road.maxLength) ] },
      { field: 'province', validators: [ Validators.required ] },
      { field: 'district', validators: [ Validators.required ] },
      { field: 'subdistrict', validators: [ Validators.required ] },
      { field: 'zip_code', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.zip_code.domestic.maxLength), Validators.minLength(this.validators.buyer_detail.zip_code.domestic.minLength) ] }
    ];
    const fieldsToClear = [ 'buyer_branch_code', 'buyer_branch_name', 'address', 'country'  ];
    this.utilitiesService.manageFormState(this.form, fieldsToSet);
    this.utilitiesService.clearValidatorsAndReset(this.form, fieldsToClear);
    this.loadProvince();
    this.loadCountries();
  }

  private setupType2() {
    const fieldsToSet = [
      { field: 'buyer_name', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.buyer_name.maxLength) ] },
      // { field: 'buyer_taxid', validators: [ (control: AbstractControl) => this.keyinValidators.taxIdForJuristic(control), Validators.required, Validators.maxLength(13), Validators.minLength(13) ] },
      { field: 'buyer_taxid', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.buyer_taxid.juristic.maxLength), Validators.minLength(this.validators.buyer_detail.buyer_taxid.juristic.minLength) ] },
      { field: 'buyer_branch_code', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.buyer_branch_code.maxLength), Validators.minLength(5) ] },
      { field: 'buyer_branch_name', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.buyer_branch_name.maxLength) ] },
      { field: 'email_to' },
      { field: 'house_no', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.house_no.maxLength) ] },
      { field: 'building_name', validators: [ Validators.maxLength(this.validators.buyer_detail.building_name.maxLength) ] },
      { field: 'floor', validators: [ Validators.maxLength(this.validators.buyer_detail.floor.maxLength) ] },
      { field: 'alley', validators: [ Validators.maxLength(this.validators.buyer_detail.alley.maxLength) ] },
      { field: 'moo_no', validators: [ Validators.maxLength(this.validators.buyer_detail.moo_no.maxLength) ] },
      { field: 'road', validators: [ Validators.maxLength(this.validators.buyer_detail.road.maxLength) ] },
      { field: 'province', validators: [ Validators.required ] },
      { field: 'district', validators: [ Validators.required ] },
      { field: 'subdistrict', validators: [ Validators.required ] },
      { field: 'zip_code', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.zip_code.domestic.maxLength), Validators.minLength(this.validators.buyer_detail.zip_code.domestic.minLength) ] }
    ];
    const fieldsToClear = [ 'address', 'country' ];
    this.utilitiesService.manageFormState(this.form, fieldsToSet);
    this.utilitiesService.clearValidatorsAndReset(this.form, fieldsToClear);
    this.loadProvince();
    this.loadCountries();
  }

  private setupType3() {
    const fieldsToSet = [
      { field: 'buyer_name', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.buyer_name.maxLength) ] },
      { field: 'buyer_taxid', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.buyer_taxid.foreigner.maxLength) ] },
      { field: 'email_to' },
      { field: 'address', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.address.maxLength) ] },
      { field: 'country', validators: [ Validators.required ] },
      { field: 'zip_code' }
    ];
    const fieldsToClear = [
      'buyer_branch_code',
      'buyer_branch_name',
      'house_no',
      'building_name',
      'moo_no',
      'floor',
      'alley',
      'road',
      'province',
      'district',
      'subdistrict'
    ];
    this.utilitiesService.manageFormState(this.form, fieldsToSet);
    this.utilitiesService.clearValidatorsAndReset(this.form, fieldsToClear);
    this.loadCountries();
  }

  private loadCountries(): void {
    this.isLoadingCountry = true;
    this.countries = [];
    this.httpClientService
      .get(`${ environment.apiURL }/api/countries`)
      .pipe(
        map(x => x as CountryModel[])
      )
      .subscribe({
        next: (res) => {
          this.countries = this.utilitiesService.sortArrayForTranslate(this.language, res, [ 'name_th', 'name_en' ])
          this.isLoadingCountry = false;
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  private loadProvince(): void {
    this.isLoadingProvince = true;
    this.provinces = [];
    this.httpClientService
      .get(`${ environment.apiURL }/api/provinces`)
      .pipe(
        map(x => x as ProvinceModel[])
      )
      .subscribe({
        next: (res) => {
          this.provinces = this.utilitiesService.sortArrayForTranslate(this.language, res, [ 'name_th', 'name_en' ]);
          this.isLoadingProvince = false;
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  private loadDistrict(provinceId: string): void {
    this.isLoadingDistrict = true;
    this.districts = [];
    this.httpClientService
      .get(`${ environment.apiURL }/api/province/${ provinceId }/districts`)
      .pipe(
        map(x => x as DistrictModel[])
      )
      .subscribe({
        next: (res) => {
          this.districts = this.utilitiesService.sortArrayForTranslate(this.language, res, [ 'name_th', 'name_en' ]);
          this.isLoadingDistrict = false;
          
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  private loadSubdistrict(provinceId: string, districtId: string): void {
    this.isLoadingSubdistrict = true;
    this.subdistricts = [];
    this.httpClientService
      .get(`${ environment.apiURL }/api/province/${ provinceId }/district/${ districtId }/sub_districts`)
      .pipe(
        map(x => x as SubdistrictModel[])
      )
      .subscribe({
        next: (res) => {
          this.subdistricts = this.utilitiesService.sortArrayForTranslate(this.language, res, [ 'name_th', 'name_en' ]);
          this.isLoadingSubdistrict = false;
        },
        error: (err) => {
          console.error(err);
        }
      });
  }

  public onChangeCountry(event: CountryModel): void {
    this.form?.controls['zip_code'].reset();
    if (event.country_code === "TH") {
      this.utilitiesService.manageFormState(this.form, [ { field: 'zip_code', validators: [ Validators.required, Validators.maxLength(this.validators.buyer_detail.zip_code.domestic.maxLength), Validators.minLength(this.validators.buyer_detail.zip_code.domestic.minLength) ] } ]);
    } else {
      this.utilitiesService.manageFormState(this.form, [ { field: 'zip_code', validators: [ Validators.maxLength(this.validators.buyer_detail.zip_code.international.maxLength) ] } ]);
    }
  }

  onChangeBuyerType(event: RadioButtonClickEvent): void {
    this.settingType(event.value);
  }

  onChangeEmail(event: string[], tooltip: NgbTooltip) {
    if (event && event.length === 0) {
      setTimeout(() => {
        if (tooltip) {
          tooltip.toggle();
        }
      }, 100);
    }
  }

  onChangeProvince(province: ProvinceModel): void {
    if (province) {
      this.loadDistrict(province.code);
      this.form?.controls['district'].reset();
      this.form?.controls['subdistrict'].reset();
      this.form?.controls['zip_code'].reset();
    }
  }

  onChangeDistrict(province: ProvinceModel, district: DistrictModel): void {
    if (province && district) {
      this.loadSubdistrict(province.code, district.code);
      this.form?.controls['subdistrict'].reset();
      this.form?.controls['zip_code'].reset();
    }

  }

  onChangeSubdistrict(subdistrict: SubdistrictModel) {
    if (subdistrict) {
      this.form?.controls['zip_code'].setValue(subdistrict.zip_code);
    }
  }

  customSearchLocationValue = (term: string, item: any) => this.keyinService.customNgSelectSearchFn(term, item, [ this.language === 'th' ? 'name_th' : 'name_en' ]);

}
