import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RdSummaryRoutingModule } from './rd-summary-routing.module';
import { RdSummaryComponent } from './rd-summary.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { CoreModule } from '../../core/core.module';
import { SharedComponentModule } from '../../shared/components/shared-component.module';


@NgModule({
  declarations: [
    RdSummaryComponent
  ],
  imports: [
    CommonModule,
    RdSummaryRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CoreModule,
    DropdownModule,
    SharedComponentModule
  ]
})
export class RdSummaryModule { }
