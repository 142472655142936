<div class="row align-items-center" style="justify-content: flex-end;" ngClass="{'m-t-10': !rightSide}">
  <div class="col-auto page-size" *ngIf="!hideItemsPerPage">
    {{'PAGINATION.SHOW' | translate}}
    <p-dropdown
      class="dropdown-pagination"
      [options]="itemsPerPageOptions"
      [(ngModel)]="itemsPerPage"
      [autoDisplayFirst]="false"
      (onChange)="onOptionChange($event)"
      [readonly]="isLoading"
    >
    </p-dropdown>
    {{'PAGINATION.ITEMS-PAGES' | translate}}
  </div>
  <div class="col-auto v-divider" *ngIf="!hideItemsPerPage && totalItems > 0"></div>
  <div class="dv-pagination">
    <ngb-pagination
      [collectionSize]="totalItems"
      [(page)]="currentPage"
      [maxSize]="maxSize"
      [pageSize]="itemsPerPage"
      [rotate]="true" 
      aria-label="Pagination"
      [directionLinks]="false"
      (pageChange)="pageChange.emit()"
      [disabled]="isLoading"
    ></ngb-pagination>
  </div>

  <div class="col-auto" >
  <ng-content *ngIf="!rightSide"></ng-content>
  </div>

</div>
