import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaxB2pRoutingModule } from './tax-b2p-routing.module';
import { TaxB2pComponent } from './tax-b2p.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../core/core.module';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { MessageModule } from 'primeng/message';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    TaxB2pComponent
  ],
  imports: [
    CommonModule,
    TaxB2pRoutingModule,
    DropdownModule,
    FormsModule,
    CalendarModule,
    NgbDropdownModule,
    CoreModule,
    TableModule,
    TranslateModule,
    FontAwesomeModule
  ],
  providers: [
    MessageModule
  ]
})
export class TaxB2pModule { }
