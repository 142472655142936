import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { CoreModule } from '../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { MessageModule } from 'primeng/message';
import { DropdownModule } from 'primeng/dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedComponentModule } from '../../shared/components/shared-component.module';

import { ZipFileRoutingModule } from './zip-file-routing.module';
import { ZipFileComponent } from './zip-file.component';



@NgModule({
  declarations: [
    ZipFileComponent
  ],
  imports: [
    CommonModule,
    ZipFileRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    CoreModule,
    TranslateModule,
    DropdownModule,
    FontAwesomeModule,
    SharedComponentModule
  ],
  providers: [
    MessageModule
  ]
})
export class ZipFileModule { }
