<nav class="navbar navbar-expand-lg box-shadow">
  <a
    class="navbar-brand"
    [routerLink]="['/dashboard']"
  ><img style="height:40px;" src="/assets/images/scb_logo_white_color.png" alt="Logo"> E-Tax</a>
  <div class="nav-responsive-wrapper">
    <div
      ngbDropdown
      class="gap-1 align-middle text-white btn-wrapper"
      display="dynamic"
    >
      <button
        class="btn p-0 align-middle"
        id="dropdownBasic1"
        ngbDropdownToggle
      >
        <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Nav" transform="translate(-1344.000000, -22.000000)" fill="#FFFFFF">
              <path d="M1351.2744,43.9996 C1351.3374,43.217 1351.6118,42.4764 1352.106,41.8464 C1352.8788,40.8566 1354.0338,40.2896 1355.2756,40.2896 L1358.5866,40.2896 C1359.3678,40.2896 1360.128,40.5178 1360.7846,40.9476 C1361.8402,41.6406 1362.4772,42.7718 1362.5738,44.008 C1360.933,45.0314 1359.0038,45.6334 1356.9318,45.6334 C1354.8542,45.6334 1352.9194,45.0286 1351.2744,43.9996 M1346.2288,34.9304 C1346.2288,29.0308 1351.0294,24.2288 1356.9318,24.2288 C1362.8328,24.2288 1367.6348,29.0308 1367.6348,34.9304 C1367.6348,37.7234 1366.5498,40.2602 1364.7928,42.1684 C1364.3224,40.816 1363.4264,39.6288 1362.186,38.8154 C1361.1122,38.1098 1359.8676,37.7374 1358.5866,37.7374 L1355.2756,37.7374 C1353.2414,37.7374 1351.3542,38.6614 1350.0928,40.2742 C1349.642,40.8524 1349.2976,41.488 1349.0624,42.1614 C1347.3082,40.2532 1346.2288,37.7192 1346.2288,34.9304 M1369.8622,34.9304 C1369.8622,27.8016 1364.062,22 1356.9318,22 C1349.8002,22 1344,27.8016 1344,34.9304 C1344,39.0744 1345.967,42.7606 1349.0092,45.1294 C1349.1436,45.289 1349.306,45.4206 1349.5034,45.499 C1351.6076,46.983 1354.1668,47.8622 1356.9318,47.8622 C1359.6968,47.8622 1362.2546,46.983 1364.3588,45.499 C1364.5562,45.4206 1364.72,45.289 1364.853,45.1294 C1367.8952,42.7606 1369.8622,39.0744 1369.8622,34.9304 M1356.92914,29.72338 C1358.14714,29.72338 1359.13974,30.71598 1359.13974,31.93398 C1359.13974,33.15198 1358.14714,34.14458 1356.92914,34.14458 C1355.71114,34.14458 1354.71854,33.15198 1354.71854,31.93398 C1354.71854,30.71598 1355.71114,29.72338 1356.92914,29.72338 M1356.92914,36.43918 C1359.41274,36.43918 1361.43434,34.41898 1361.43434,31.93398 C1361.43434,29.45038 1359.41274,27.42878 1356.92914,27.42878 C1354.44554,27.42878 1352.42394,29.45038 1352.42394,31.93398 C1352.42394,34.41898 1354.44554,36.43918 1356.92914,36.43918" id="Fill-3"></path>
            </g>
          </g>
        </svg>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

        <div class="dropdown-item disabled" (click)="stopPropagation($event)">
          <div class="text-center">{{ user?.username }}</div>
          <div class="text-center">({{ user?.group }})</div>
        </div>
        <div class="dropdown-divider" ></div>
        <a class="dropdown-item pointer" [routerLink]="['/profile', 'password']" >
          <div class="text-center">{{'MENU.CHANGE-PASSWORD' | translate}}</div>
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item pointer" (click)="onLogout()">
          <div class="text-center">{{'MENU.SIGN-OUT' | translate}}</div>
        </a>

      </div>
    </div>

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleNav = !toggleNav"
    >
      <fa-icon [icon]="['fas', 'bars']" size="lg"></fa-icon>
    </button>
  </div>


  <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="toggleNav">
    <ul class="navbar-nav me-auto flex-wrap">
      @if (isDashboardPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['/dashboard']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.DASHBOARD' | translate }}</a>
        </li>
      }
   
      @if (isEmailDashboardPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['/email-dashboard']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.EMAIL-DASHBOARD' | translate }}</a>
        </li>
      }
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active-li"
          *ngIf="isSCBBCMManageUser() || isSCBBCMViewer()"
          [routerLink]="['/user-management']"
        >{{'MENU.USER-MANAGEMENT' | translate}}</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active-li"
          *ngIf="isChecker() || isMaker() || isViewerOperation() || isViewerImpAndHelp()"
          [routerLink]="['/fee-debit-account']"
        >{{'FEE-DEBIT-ACCOUNT.FEE-DEBIT-ACCOUNT' | translate}}</a>
      </li>
      @if (isMonitoringPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['/dashboard-it']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.MONITORING' | translate }}</a>
        </li>
      }
      @if (isFeeEnginePermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['/fee-engine']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.FEE-ENGINE' | translate }}</a>
        </li>
      }
      @if (isDataPurgePermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['/purge', 'list']"
          >{{'NEW-TRANSLATE.NAV-MENU.DATA-PURGE' | translate}}</a>
        </li>
      }
      @if (isDocumentUploadPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['/document', 'upload']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.DOCUMENT-UPLOAD' | translate }}</a>
        </li>
      }
      @if (isDocumentSearchPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['/search']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.DOCUMENT-SEARCH' | translate }}</a>
        </li>
      }
      @if (isSalesTaxReportPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['/taxReport']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.SALES-TAX-REPORT' | translate }}</a>
        </li>
      }
      @if (isBulkTaxReportPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['/bulk-tax-report']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.BULK-TAX-REPORT' | translate }}</a>
        </li>
      }
      @if (isRevenueDocumentPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['/zipFile']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.REVENUE-DOCUMENTS' | translate }}</a>
        </li>
      }
      @if (isSubmitToRdPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['submit-to-rd']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.SUBMIT-TO-RD' | translate }}</a>
        </li>
      }
      @if (isRdSummaryPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['rd-summary']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.RD-SUMMARY' | translate }}</a>
        </li>
      }
      <li class="nav-item">
        <a
          class="nav-link"
          routerLinkActive="active-li"
          *ngIf="isChecker() || isSuperChecker()"
          [routerLink]="['/approval-history']"
        >{{'MENU.APPROVAL-HISTORY' | translate}}</a>
      </li>
      @if (isKeyinPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['create-document']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.KEYIN' | translate }} </a>
        </li>
      }
      @if (isAnnouncementPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['announcement']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.ANNOUNCEMENT' | translate }} </a>
        </li>
      }
      @if (isSettingPermission()) {
        <li class="nav-item">
          <a
            class="nav-link"
            routerLinkActive="active-li"
            [routerLink]="['setting']"
          >{{ 'NEW-TRANSLATE.NAV-MENU.SETTING' | translate }} </a>
        </li>
      }
    </ul>
    <ul class="navbar-nav ml-auto align-middle profile-nav">
      <div
        ngbDropdown
        class="gap-1 align-middle text-white btn-wrapper"
        display="dynamic"
      >
        <button
          class="btn p-0 align-middle"
          id="dropdownBasic1"
          ngbDropdownToggle
        >
          <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Nav" transform="translate(-1344.000000, -22.000000)" fill="#FFFFFF">
                <path d="M1351.2744,43.9996 C1351.3374,43.217 1351.6118,42.4764 1352.106,41.8464 C1352.8788,40.8566 1354.0338,40.2896 1355.2756,40.2896 L1358.5866,40.2896 C1359.3678,40.2896 1360.128,40.5178 1360.7846,40.9476 C1361.8402,41.6406 1362.4772,42.7718 1362.5738,44.008 C1360.933,45.0314 1359.0038,45.6334 1356.9318,45.6334 C1354.8542,45.6334 1352.9194,45.0286 1351.2744,43.9996 M1346.2288,34.9304 C1346.2288,29.0308 1351.0294,24.2288 1356.9318,24.2288 C1362.8328,24.2288 1367.6348,29.0308 1367.6348,34.9304 C1367.6348,37.7234 1366.5498,40.2602 1364.7928,42.1684 C1364.3224,40.816 1363.4264,39.6288 1362.186,38.8154 C1361.1122,38.1098 1359.8676,37.7374 1358.5866,37.7374 L1355.2756,37.7374 C1353.2414,37.7374 1351.3542,38.6614 1350.0928,40.2742 C1349.642,40.8524 1349.2976,41.488 1349.0624,42.1614 C1347.3082,40.2532 1346.2288,37.7192 1346.2288,34.9304 M1369.8622,34.9304 C1369.8622,27.8016 1364.062,22 1356.9318,22 C1349.8002,22 1344,27.8016 1344,34.9304 C1344,39.0744 1345.967,42.7606 1349.0092,45.1294 C1349.1436,45.289 1349.306,45.4206 1349.5034,45.499 C1351.6076,46.983 1354.1668,47.8622 1356.9318,47.8622 C1359.6968,47.8622 1362.2546,46.983 1364.3588,45.499 C1364.5562,45.4206 1364.72,45.289 1364.853,45.1294 C1367.8952,42.7606 1369.8622,39.0744 1369.8622,34.9304 M1356.92914,29.72338 C1358.14714,29.72338 1359.13974,30.71598 1359.13974,31.93398 C1359.13974,33.15198 1358.14714,34.14458 1356.92914,34.14458 C1355.71114,34.14458 1354.71854,33.15198 1354.71854,31.93398 C1354.71854,30.71598 1355.71114,29.72338 1356.92914,29.72338 M1356.92914,36.43918 C1359.41274,36.43918 1361.43434,34.41898 1361.43434,31.93398 C1361.43434,29.45038 1359.41274,27.42878 1356.92914,27.42878 C1354.44554,27.42878 1352.42394,29.45038 1352.42394,31.93398 C1352.42394,34.41898 1354.44554,36.43918 1356.92914,36.43918" id="Fill-3"></path>
              </g>
            </g>
          </svg>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">

          <div class="dropdown-item disabled" (click)="stopPropagation($event)">
            <div class="text-center">{{ user?.username }}</div>
            <div class="text-center">({{ user?.group }})</div>
          </div>
          <div class="dropdown-divider" ></div>
          <a class="dropdown-item pointer" [routerLink]="['/profile', 'password']" >
            <div class="text-center">{{'MENU.CHANGE-PASSWORD' | translate}}</div>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item pointer" (click)="onLogout()">
            <div class="text-center">{{'MENU.SIGN-OUT' | translate}}</div>
          </a>

        </div>
      </div>
    </ul>
    <div class="navbar-nav change-language-nav">
      <select
        class="form-control language-switcher text-center"
        (change)="onChangeLanguage(language)"
        [(ngModel)]="language"
      >
        <option value="en">EN</option>
        <option value="th">TH</option>
      </select>
    </div>
  </div>
</nav>
