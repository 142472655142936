import { Injectable } from '@angular/core';
import { DocumentTypeMasterDataModel } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  private documentType: DocumentTypeMasterDataModel[] = [];

  constructor() { }

  public setDocumentType(documentType: DocumentTypeMasterDataModel[]): void {
    this.documentType = [ ...documentType ];
  }

  public getDocumentType(): DocumentTypeMasterDataModel[] {
    return [ ...this.documentType ];
  }

  public findDocumentTypeWithType(type: string): DocumentTypeMasterDataModel | null {
    return [ ...this.documentType ].find(x => x.type === type) ?? null;
  }

}
