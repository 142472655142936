import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';

export const hasLoginGuard: CanActivateFn = (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);
  const isLoggedIn = authenticationService.isLoggedIn();
  if (!isLoggedIn) {
    return true;
  } else {
    router.navigate(['home']);
    return false;
  }
};
