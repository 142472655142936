import { Component, Input, SimpleChanges } from '@angular/core';
import { LanguageType, LookupModel } from '../../interfaces';
import { Subject, takeUntil } from 'rxjs';
import { STATUS } from '../../services/shared.constant';
import { HandleTranslateService } from '../../services';
;

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss'
})
export class StatusComponent {

  private unsubscribe$: Subject<void> = new Subject();

  @Input() public statusId: number | undefined;
  @Input() public statusWidth: string | undefined;
  @Input() public statusHeight: string | undefined;

  public status: LookupModel | undefined;

  public language: LanguageType;

  constructor(
    private handleTranslateService: HandleTranslateService
  ) {
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.setStatus(this.statusId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const status = changes['statusId'];
    if (status) {
      this.setStatus(status.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private setStatus(statusId?: number): void {
    this.status = STATUS.find(x => x.id === statusId);
  }

}
