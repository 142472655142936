import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'transformDate'
})
export class TransformDatePipe implements PipeTransform {

  transform(value: string, format?: string): string | null {
    if (!value) return null;
    return moment(value).utcOffset('+07:00').format(format);
  }

}
