import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinString'
})
export class JoinStringPipe implements PipeTransform {

  transform(obj: any, keys: string[], separator: string = ', '): string | null {
    if (!obj || !keys || !Array.isArray(keys)) return '';
    const values: string[] = keys.reduce((acc: string[], key) => {
      if (obj[key] !== null) { 
        acc.push(obj[key]);
      }
      return acc;
    }, []);
    const join = values.join(separator)
    return join === '' ? null : join;
  }

}
