<div
  [id]="modalID ? modalID : 'infomationModal'"
  [attr.data-testid]="modalID ? modalID : 'infomationModal'"
>
  <div class="modal-body">
    <div class="text-center">
      @if (iconSrc) {
        <div class="mb-2">
          <img
            class="mx-auto m-4"
            [ngStyle]="iconDimension ? { width: iconDimension.width, height: iconDimension.height } : undefined"
            [ngClass]="[iconClass ? iconClass : '']"
            [src]="iconSrc"
            alt="Modal-Icon"
          >
        </div>
      }
      <div class="modal-text-wrapper">
        @if (title) {
          <h3>{{ title | translate }}</h3>
        }
        @for (text of texts; track text) {
          @if (text.text | translate: { value: text.value }) {
            <p [ngStyle]="{ 'margin': text.margin ?? null }">{{ text.text | translate: { value: text.value } }}</p>
          }
        }
      </div>
      <div class="modal-footer">
        @if (cancelButtonOptions?.show) {
          <button
            [id]="cancelButtonOptions?.id ?? 'closeModalButton'"
            [attr.data-testid]="cancelButtonOptions?.id ?? 'closeModalButton'"
            class="btn"
            [title]="(cancelButtonOptions?.name ?? 'NEW-TRANSLATE.COMMON.CLOSE') | translate"
            [ngClass]="[ cancelButtonOptions?.class ?? 'btn-dv btn-clean btn-wide' ]"
            (click)="onClose('close')"
          >{{ (cancelButtonOptions?.name ?? 'NEW-TRANSLATE.COMMON.CLOSE') | translate }}</button>
        }
        @if (actionButtonOptions?.show) {
          <button
            [id]="actionButtonOptions?.id ?? 'actionModalButton'"
            [attr.data-testid]="actionButtonOptions?.id ?? 'actionModalButton'"
            class="btn"
            [title]="(actionButtonOptions?.name ?? 'NEW-TRANSLATE.COMMON.OK') | translate"
            [ngClass]="[ actionButtonOptions?.class ?? 'btn-dv btn-wide' ]"
            (click)="onClose('action')"
          >{{ (actionButtonOptions?.name ?? 'NEW-TRANSLATE.COMMON.OK') | translate }}</button>
        }
      </div>
    </div>
  </div>
</div>

