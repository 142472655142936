<div
  class="dropdown-label-group"
  [ngClass]="{ 
    'is-invalid': invalid,
    'date-picker-disabled': isDisabled || isLoading
  }"
>
  <p-calendar
    #timePicker
    [attr.data-testid]="inputId ?? 'timePicker'"
    class="form-control date-picker-custom datepicker-style-custom"
    [disabled]="isLoading || isDisabled"
    inputId="inputId ?? 'timePicker'"
    [id]="inputId ?? 'timePicker'"
    [iconDisplay]="'input'"
    [(ngModel)]="time"
    [placeholder]="placeholder ?? 'NEW-TRANSLATE.PLACEHOLDER.SELECT-TIME' | translate"
    [readonlyInput]="true"
    [showIcon]="true"
    [showClear]="clearable"
    [timeOnly]="true"
    (ngModelChange)="changeTime($event)"     
  >
    <ng-template
      pTemplate="inputicon"
      let-clickCallBack="clickCallBack"
    >
      <div class="date-picker-icon-custom">
        <fa-icon
          [icon]="['far', 'clock']"
          size="xl"
          (click)="clickCallBack($event)"
        ></fa-icon>
      </div>
    </ng-template>
    <ng-template
      pTemplate="clearicon"
    >
      <div class="date-picker-clear-icon-custom">
        <fa-icon
          [icon]="['fas', 'xmark']"
          sizr="sm"
        ></fa-icon>
      </div>
    </ng-template>
  </p-calendar>
  <label [for]="inputId ?? 'timePicker'">{{ label ?? 'NEW-TRANSLATE.COMMON.TIME' | translate }}</label>
</div>