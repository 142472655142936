import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from '../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { SharedComponentModule } from '../../shared/components/shared-component.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { BulkTaxReportRoutingModule } from './bulk-tax-report-routing.module';
import { BulkTaxReportComponent } from './bulk-tax-report.component';

@NgModule({
  declarations: [
    BulkTaxReportComponent
  ],
  imports: [
    CommonModule,
    BulkTaxReportRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CoreModule,
    TranslateModule,
    SharedComponentModule,
    DropdownModule,
    FontAwesomeModule
  ]
})
export class BulkTaxReportModule { }
