import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../../core/core.module';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageService } from 'primeng/api';

import { ApprovalHistoryRoutingModule } from './approval-history-routing.module';
import { ApprovalHistoryComponent } from './approval-history.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    ApprovalHistoryComponent
  ],
  imports: [
    CommonModule,
    ApprovalHistoryRoutingModule,
    TranslateModule,
    CoreModule,
    NgbPaginationModule,
    DropdownModule,
    FormsModule,
    TableModule,
    CheckboxModule,
    NgbModule,
    InputSwitchModule,
    FontAwesomeModule
  ],
  providers: [
    MessageService
  ]
})
export class ApprovalHistoryModule { }
