import { Injectable } from '@angular/core';
import { AddressModel, CountryModel, DistrictModel, ProvinceModel, SubdistrictModel } from './keyin.interface';
import { LanguageType } from '../../shared/interfaces';

@Injectable({
  providedIn: 'root'
})
export class KeyinService {

  constructor(
  ) { }

  // Address

  public transformAddress(address: AddressModel, language: LanguageType): string {
    const isCapital = this.isCapital(address.province?.code);
    const transformAddress = `${ address.house_no ?? '' } ${ this.getBuildingOrApartment(address.building_name, language) } ${ this.getMooNumber(address.moo_no, language) } ${ this.getFloor(address.floor, language) } ${ this.getAlley(address.alley, language) } ${ this.getRoad(address.road, language) } ${ this.getSubdistrictOrSubarea(isCapital, address.subdistrict, language) } ${ this.getDistricOrArea(isCapital, address.district, language) } ${ this.getProvince(isCapital, address.province, language) } ${ address.zip_code ?? '' }`;
    return transformAddress;
  }

  public isCapital(id: string | undefined): boolean {
    if (id === '10') {
      return true;
    } else {
      return false;
    }
  }

  public getCountry(country: CountryModel | undefined, language: LanguageType): string {
    if (country) {
      if (language === 'en') {
        return country.name_en;
      } else {
        return country.name_th;
      }
    }
    return '';
  }

  public getBuildingOrApartment(buildingOrApartment: string | undefined, language: LanguageType): string {
    if (buildingOrApartment) {
      if (language === 'en') {
        return `${ buildingOrApartment } Building,`;
      } else {
        return `อาคาร${ buildingOrApartment }`;
      }
    }
    return '';
  }

  getAlley(alley: string | undefined, language: LanguageType): string {
    if (alley) {
      if (language === 'en') {
        return `Soi ${ alley },`;
      } else {
        return `ซอย${ alley }`;
      }
    }
    return '';
  }

  public getMooNumber(mooNo: string | undefined, language: LanguageType): string {
    if (mooNo) {
      if (language === 'en') {
        return `Village No. ${ mooNo },`;
      } else {
        return `ม.${ mooNo }`;
      }
    }
    return '';
  }

  public getFloor(floorNo: string | undefined, language: LanguageType): string {
    if (floorNo) {
      if (language === 'en') {
        return `${ floorNo } Floor,`;
      } else {
        return `ชั้น ${ floorNo }`;
      }
    }
    return '';
  }

  public getRoad(road: string | undefined, language: LanguageType): string {
    if (road) {
      if (language === 'en') {
        return `${ road } Road,`;
      } else {
        return `ถนน${ road }`;
      }
    }
    return '';
  }

  public getProvince(isCapital: boolean, province: ProvinceModel | undefined, language: LanguageType): string | null {
    if (province) {
      if (language === 'en') {
        return `${ province.name_en },`;
      } else {
        if (isCapital) {
          return province.name_th;
        } else {
          return `จ.${ province.name_th }`;
        }
      }
    }
    return '';
  }

  public getDistricOrArea(isCapital: boolean, districtOrArea: DistrictModel | undefined, language: LanguageType): string {
    if (districtOrArea) {
      if (language === 'en') {
        return `${ districtOrArea.name_en },`;
      } else {
        if (isCapital) {
          return `${ districtOrArea.name_th?.match('เขต') ? '' : 'เขต' }${ districtOrArea.name_th }`;
        } else {
          return `อ.${ districtOrArea.name_th }`;
        }
      }
    }
    return '';
  }

  public getSubdistrictOrSubarea(isCapital: boolean, subdistrictOrSubarea: SubdistrictModel | undefined, language: LanguageType): string {
    if (subdistrictOrSubarea) {
      if (language === 'en') {
        return `${ subdistrictOrSubarea.name_en },`;
      } else {
        if (isCapital) {
          return `แขวง${ subdistrictOrSubarea.name_th }`;
        } else {
          return `ต.${ subdistrictOrSubarea.name_th }`;
        }
      }
    }
    return '';
  }

  // Validators
  
  validatorThaiID(value: string): boolean {
    if (!this.validatorDigit(value)) return false;
    if (value.length !== 13) return false;
    let sum = 0;
    for (let i = 0; i < 12; i++) {
        sum += parseInt(value.charAt(i)) * (13 - i);
    }
    return ((11 - sum % 11) % 10) === parseInt(value.charAt(12));
  }

  validatorDigit(value: string | number): boolean {
    const regEx = new RegExp(/^[0-9]+$/);
    if (regEx.test(value.toString())) {
      return true;
    } else {
      return false;
    }
  }

  // Ng Select
  
  customNgSelectSearchFn(term: string, item: any, keys: string[]): boolean {
    term = term.toLowerCase();
    for (const key of keys) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        const value = item[key];
        if (typeof value === 'string' && value.toLowerCase().includes(term)) {
          return true;
        }
      }
    }
    return false;
  }

}
