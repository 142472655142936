import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ChangepasswordModule } from './core/changepassword/changepassword.module';
import { ToastModule } from 'primeng/toast';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DefaultLanguage, HttpLoaderFactory } from './shared/services/translate-service';
import { BROADCAST_CHANNEL, FontawesomeService } from './shared/services';
import { ErrorInterceptor, NetworkInterceptor, RequestInterceptor } from './core/interceptors';

import { AppComponent } from './app.component';

import { MainModule } from './main/main.module';
import { AppRoutingModule } from './app-routing.module';

import { SharedComponentModule } from './shared/components/shared-component.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MainModule,
    NgbModule,
    FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: DefaultLanguage(),
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    }),
    ChangepasswordModule,
    ToastModule,
    NgIdleKeepaliveModule.forRoot(),
    FontAwesomeModule,
    NgxMaskDirective,
    SharedComponentModule
  ],
  providers: [
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NetworkInterceptor, multi: true },
    FontawesomeService,
    provideNgxMask(),
    {
      provide: BROADCAST_CHANNEL,
      useFactory: () => new BroadcastChannel('eventChannel'),
    },
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
