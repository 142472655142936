<div
  [id]="modalID ? modalID : 'confirmModal'"
  [attr.data-testid]="modalID ? modalID : 'confirmModal'"
>
  <div class="modal-body">
    <img
      *ngIf="iconSrc"
      class="mx-auto m-4"
      [ngStyle]="{
        width: iconWidth ? iconWidth : '',
        height: iconWidth ? iconHeight : ''
      }"
      [ngClass]="[iconClass ? iconClass : '']"
      [src]="iconSrc"
      alt="Modal-Icon"
    >
    <div class="text-center">
      <h4 *ngIf="title" class="text-break">{{ title | translate }}</h4>
      @for (text of texts; track text) {
        @if (text.text | translate: { value: text.value }) {
          <p [ngStyle]="{ 'margin': text.margin ? text.margin : null }">{{ text.text | translate: { value: text.value } }}</p>
        }
      }
    </div>
    @if (requiredReCaptcha) {
      <div class="re-captcha-wrapper">
        <re-captcha
          #recaptchaRef
          (resolved)="recaptChaResolved($event)"
          [siteKey]="reCaptchaSiteKey"
        ></re-captcha>
      </div>
    }
    <div class="modal-footer">
      <button
        [id]="cancelButtonOptions?.id ?? 'cancelModalButton'"
        [attr.data-testid]="cancelButtonOptions?.id ?? 'cancelModalButton'"
        class="btn"
        [ngClass]="[ cancelButtonOptions?.class ?? 'btn-dv btn-clean btn-wide' ]"
        [title]="(cancelButtonOptions?.name ?? 'NEW-TRANSLATE.COMMON.CANCEL') | translate"
        (click)="onDismiss()"
      >{{ (cancelButtonOptions?.name ?? 'NEW-TRANSLATE.COMMON.CANCEL') | translate }}</button>
      <button
        [id]="confirmButtonOptions?.id ?? 'confirmModalButton'"
        [attr.data-testid]="confirmButtonOptions?.id ?? 'confirmModalButton'"
        class="btn"
        [ngClass]="[ confirmButtonOptions?.class ?? 'btn-dv btn-wide' ]"
        [title]="(confirmButtonOptions?.name ?? 'NEW-TRANSLATE.COMMON.CONFIRM') | translate"
        [disabled]="!reCaptchaToken && requiredReCaptcha"
        (click)="onClose()"
      >{{ (confirmButtonOptions?.name ?? 'NEW-TRANSLATE.COMMON.CONFIRM') | translate }}</button>
    </div>
  </div>
</div>
