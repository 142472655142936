import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'primeng/api';

import { MainRoutingModule } from './main-routing.module';
import { LoginAdminComponent } from '../login-admin/login-admin.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { TranslateModule } from '@ngx-translate/core';
import { ToastModule } from 'primeng/toast';

import { FeaturesModule } from '../features/features.module';

import { ApiService } from '../service/api.service';
import { AuthenticationService } from '../service/authentication.service';
import { NotificationService } from '../service/notification.service';
import { UtilityService } from '../service/utility.service';
import { NavbarComponent } from '../core/navbar/navbar.component';
import { MainComponent } from './main.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FontawesomeService, SettingService } from '../shared/services';
import { SharedComponentModule } from '../shared/components/shared-component.module';

@NgModule({
  declarations: [
    MainComponent,
    LoginAdminComponent,
    NavbarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    MainRoutingModule,
    RecaptchaModule,
    TranslateModule,
    ToastModule,
    FeaturesModule,
    NgbModule,
    FontAwesomeModule,
    NgbDropdownModule,
    SharedComponentModule
  ],
  providers: [
    ApiService,
    AuthenticationService,
    NotificationService,
    UtilityService,
    MessageService,
    FontawesomeService,
    SettingService
  ]
})
export class MainModule { }
