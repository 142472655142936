<div
  #dropdownEl="ngbDropdown"
  ngbDropdown
  autoClose="outside"
  class="calendar-container"
  [container]="container"
  display="dynamic"
  [placement]="placement"
  (openChange)="dropdownChange($event)"
  [ngClass]="{ 'is-invalid': invalid }"
>
  <div class="calendar-wrapper">
    <div class="calendar-input-wrapper">
      <input
        #dateRangePicker
        ngbDropdownToggle
        autocomplete="off"
        class="calendar-input"
        id="calendarDropdown"
        [(ngModel)]="dateString"
        [placeholder]="placeholder | translate"
        [readonly]="true"
        type="text"
        [ngClass]="[
          'calendar-input',
          isLoading ? 'calendar-disabled' : ''
        ]"
      />
    </div>
    @if (date && clearable) {
      <div class="calendar-clear-wrapper">
        <button
          class="calendar-clear"
          [disabled]="isLoading"
          (click)="clearDate()"
        >
          <fa-icon [icon]="['fas', 'xmark']" size="sm"></fa-icon>
        </button>
      </div>
    }
    <div class="calendar-trigger-wrapper">
      <button
        class="calendar-trigger"
        [disabled]="isLoading"
        (click)="dropdownEl.toggle()"
      >
        <fa-icon [icon]="['far', 'calendar']" size="xl"></fa-icon>
      </button>
    </div>
    <label for="calendarDropdown">{{ label | translate }}</label>
  </div>
  <div
    class="dropdown-menu"
    ngbDropdownMenu
    aria-labelledby="calendarDropdown"
  >
    <div class="d-block d-lg-flex">
      <div class="calendar-inline-wrapper">
        <h6 class="calendar-title">
          {{ "NEW-TRANSLATE.COMMON.SINCE" | translate }}
        </h6>
        <p-calendar
          #calendarStart
          class="datepicker-style-custom"
          dateFormat="dd/mm/yy"
          [inline]="true"
          [disabled]="isLoading"
          [maxDate]="maxStartDate"
          [minDate]="minStartDate"
          readonlyInput="true"
          [showIcon]="true"
          [(ngModel)]="startDate"
          (onSelect)="changeDate(true, dropdownEl)"
        ></p-calendar>
      </div>
      <div class="calendar-inline-wrapper">
        <h6 class="calendar-title">
          {{ "NEW-TRANSLATE.COMMON.UNTIL" | translate }}
        </h6>
        <p-calendar
          #calendarEnd
          class="datepicker-style-custom"
          dateFormat="dd/mm/yy"
          [inline]="true"
          [disabled]="isLoading"
          [maxDate]="maxEndDate"
          [minDate]="minEndDate"
          readonlyInput="true"
          [showIcon]="true"
          [(ngModel)]="endDate"
          (onSelect)="changeDate(false, dropdownEl)"
        ></p-calendar>
      </div>
    </div>
  </div>
</div>
