import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubmitToRdRoutingModule } from './submit-to-rd-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../../core/core.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { SubmitToRdComponent } from './submit-to-rd.component';
import { SharedComponentModule } from '../../shared/components/shared-component.module';

@NgModule({
  declarations: [
    SubmitToRdComponent
  ],
  imports: [
    CommonModule,
    SubmitToRdRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CoreModule,
    FontAwesomeModule,
    CheckboxModule,
    DropdownModule,
    SharedComponentModule
  ]
})
export class SubmitToRdModule { }
