<p-toast position="top-right"></p-toast>

<div class="header">
  <h2>{{'PROFILE.PASSWORD.TITLE' | translate}}</h2>
  <hr>
</div>

<div class="m-t-20 form-center">
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{'PROFILE.PASSWORD.OLD-PASSWORD' | translate}}</label>
    <div class="col-sm-9">
      <div class="input-group mb-3 cursor-pointer">
        <input [type]="oldPasswordInputType ? 'password' : 'text'" class="form-control" placeholder="{{'PROFILE.PASSWORD.OLD-PASSWORD' | translate}}" [(ngModel)]="oldPassword">
        <div class="input-group-append clickable" (click)="toggleOldInputType()">
          <span class="input-group-text">
            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{'PROFILE.PASSWORD.NEW-PASSWORD' | translate}}</label>
    <div class="col-sm-9">
      <div class="input-group mb-3 cursor-pointer">
        <input [type]="newPasswordInputType ? 'password' : 'text'" class="form-control" placeholder="{{'PROFILE.PASSWORD.NEW-PASSWORD' | translate}}" [(ngModel)]="newPassword">
        <div class="input-group-append clickable" (click)="toggleNewInputType()">
          <span class="input-group-text">
            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{'PROFILE.PASSWORD.CONFIRM-NEW-PASSWORD' | translate}}</label>
    <div class="col-sm-9">
      <div class="input-group mb-3 cursor-pointer">
        <input [type]="newPasswordInputType ? 'password' : 'text'"
                class="form-control"
                placeholder="{{'PROFILE.PASSWORD.CONFIRM-NEW-PASSWORD' | translate}}"
                [(ngModel)]="confirmPassword"
                (keyup.enter)="submitclick()">
        <div class="input-group-append clickable" (click)="toggleNewInputType()">
          <span class="input-group-text">
            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
          </span>
        </div>
      </div>
      <p class="text-red pl-1" *ngIf="errorMessage">{{errorMessage | translate}}</p>
    </div>
  </div>
  <div class="text-left">
    <div class="text-red">
      **{{'PROFILE.PASSWORD.NOTE' | translate}}: {{'PROFILE.PASSWORD.WORD' | translate}}
      <div class="font-weight-bold">
        <u>{{'PROFILE.PASSWORD.PRECAUTION' | translate}}</u><span style="font-size:20px;">!</span> {{'PROFILE.PASSWORD.PRECAUTION_TEXT' | translate}}
      </div>
    </div>
    <br>
  </div>
  <div class="text-right">
    <re-captcha #recaptchaRef  size="invisible" (resolved)="resolve($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
    <button class="btn btn-primary" [disabled]="isLoading" (click)="submitclick()">
      {{'PROFILE.PASSWORD.CONFIRM' | translate}}
      <fa-icon *ngIf="isLoading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
    </button>
  </div>
</div>
