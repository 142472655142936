<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.REVENUE-DOCUMENTS.TITLE' | translate }}</h3>
    </div>
  </div>
</div>

<div class="submit-to-rd-container">
  <div class="criteria-search-container">
    <app-filter-header
      [loading]="isLoading || isLoadingRows || isLoadingOther"
      (submit)="onSearch()"
      (clear)="onClear()"
    />
    <div class="criteria-search-wrapper">
      <form [formGroup]="form" class="form-wrapper">
        <div class="row g-2">
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_id"
                selectId="companySelect"
                [options]="companies"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                (onChange)="filterBranches($event.value)"
                [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="companySelect">{{ 'NEW-TRANSLATE.COMMON.COMPANY' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_branch_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_branch_id"
                selectId="branchSelect"
                [options]="branches"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [placeholder]="'NEW-TRANSLATE.COMMON.BRANCH' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="branchSelect">{{ 'NEW-TRANSLATE.COMMON.BRANCH' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['rd_document_type'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="rd_document_type"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="documentType"
                optionValue="type"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
                selectId="documentTypeSelect"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="documentTypeSelect">{{ 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE' | translate }}</label>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['month'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="month"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="months"
                optionValue="id"
                selectId="monthSelect"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="monthSelect">{{ 'NEW-TRANSLATE.COMMON.MONTH' | translate }}</label>
            </div>
          </div>
          <div class="col-6 col-md-3 col-lg-2">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['year'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="year"
                selectId="yearSelect"
                [options]="years"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
              >
              </p-dropdown>
              <label for="yearSelect">{{ 'NEW-TRANSLATE.COMMON.YEAR' | translate }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['document_Status'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="can_download_zip"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="checkFromAccounts"
                optionValue="id"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
                selectId="checkFromAccountSelect"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="checkFromAccountSelect">{{ 'NEW-TRANSLATE.REVENUE-DOCUMENTS.CHECK-FROM-ACCOUNT' | translate }}</label>
            </div>
          </div>
          @if (!isViewer()) {
            <div class="col-12 col-md-6 col-lg-4 d-flex align-items-center gap-1">
              <p-checkbox
                binary="true"
                class="dv-checkbox"
                inputId="notBeenDownloadedCheckbox"
                formControlName="not_uploaded_only"
                [readonly]="isLoading || isLoadingRows || isLoadingOther"
              ></p-checkbox>
              <label for="notBeenDownloadedCheckbox">{{'NEW-TRANSLATE.REVENUE-DOCUMENTS.ONLY-ITEMS-THAT-HAVE-NOT-BEEN-DOWNLOADED' | translate }}</label>
            </div>
          }
        </div>
      </form>
    </div>
  </div>
  <div class="datatables-container">
    <app-custom-pagination
      [(currentPage)]="pages"
      [(itemsPerPage)]="limitRow"
      [totalItems]="collectionSize"
      [isLoading]="isLoading || isLoadingRows || isLoadingOther"
      (pageChange)="pageChanged()"
      (optionChangeValue)="limitRowChanged($event)"
    >
      @if (isViewer() || isDownloadAllDocumentPermission()) {
        <div class="col-auto d-flex gap-2 mt-3 mt-lg-0 align-items-center flex-wrap">
          @if (isDownloadAllDocumentPermission()) {
            <button
              class="btn btn-dv btn-wide"
              data-testid="downloadExcelButton"
              [disabled]="!rows || rows.length === 0 || !selectedRows || selectedRows.length === 0 || isLoadingRows || isLoadingOther || isLoading"
              id="downloadExcelButton"
              type="button"
              [title]="'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-EXCEL' | translate"
              (click)="onDownloadAllTaxReport()"
            >
              {{ 'NEW-TRANSLATE.COMMON.ALL-TAX-REPORT' | translate }}
            </button>
            <div class="v-divider"></div>
          }
          @if (!isViewer()) {
            <div class="progress-wrapper">
              {{ 'ZIP-FILE.DOWNLOADED' | translate }} : <span class="progress-text">{{ downloadProgress }}%</span>
            </div>
            <button
              class="btn btn-dv btn-wide"
              data-testid="downloadExcelButton"
              [disabled]="!rows || rows.length === 0 || isLoadingRows || isLoadingOther || isLoading"
              id="downloadExcelButton"
              type="button"
              [title]="'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-EXCEL' | translate"
              (click)="onDownloadExcel()"
            >
              {{ 'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-EXCEL' | translate }}
            </button>
          }
        </div>
      }
    </app-custom-pagination>
    <app-datatables
      #datatables
      [blockRenderingCheckboxKey]="blockRenderingCheckboxKey"
      [blockRenderingCheckboxBoolean]="blockRenderingCheckboxBoolean"
      [collectionSize]="collectionSize"
      [columns]="columns"
      [isLoadingRows]="isLoadingRows"
      [isLoadingOther]="isLoadingOther"
      [limitRow]="limitRow"
      [pages]="pages"
      [rows]="rows"
      [(selectedRows)]="selectedRows"
      [(sortBy)]="sortBy"
      [(sortMode)]="sortMode"
      tableId="revenueDocumentsDatatable"
      (onRowActionEvent)="onRowActionEvent($event)"
      (onSortEvent)="onSortEvent()"
    />
  </div>
</div>