import { Component, ViewChild } from '@angular/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../service/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../service/notification.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrl: './changepassword.component.scss'
})
export class ChangepasswordComponent {

  @ViewChild('recaptchaRef') recaptchaRef: RecaptchaComponent | undefined;

  public passwordDetail: any;
  public newPassword = null;
  public confirmNewPassword = null;
  public isLoading = false;
  public resetSuccess: any;
  public recaptchaResponse: boolean | null = false;
  public recaptchaSiteKey = environment['recaptChaSiteKey'];


  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
     private notificationService: NotificationService
  ) {
  }

  ngOnInit() {
    this.loadPasswordDetail();
  }

  resolve(token: any) {
    this.recaptchaResponse = token;
  }

  submitPassword() {
    if(this.newPassword != this.confirmNewPassword || !this.recaptchaResponse){
      return
    }
    this.isLoading = true;
    this.activatedRoute.params.subscribe(params => {
      this.passwordDetail = params['changepassword'];
      if (this.passwordDetail) {
        const header = new HttpHeaders().set('Authorization', this.passwordDetail);
        const data = {
          password: this.newPassword,
          recaptchaToken : this.recaptchaResponse,
        };
        this.apiService.putPasswordDetail(header, data).subscribe(
          res => {
            this.resetSuccess = res;
            this.newPassword = null;
            this.confirmNewPassword = null;
            this.isLoading = false;
            this.router.navigate(['/login']);
          },
          (error) => {
            this.isLoading = false;
            this.recaptchaRef?.reset();
            this.recaptchaResponse = null;
            this.notificationService.showCustomNotification(
              {
                severity: 'error',
                summary: 'Error',
                detail: error.error
              }
            )
          }
        );
      }
    });
  }

  loadPasswordDetail() {
    this.activatedRoute.params.subscribe(params => {
      this.passwordDetail = params['changepassword'];
      if (this.passwordDetail) {
        const header = new HttpHeaders().set('Authorization', this.passwordDetail);
        this.apiService.passwordDetail(header).subscribe(
          (detail: any) => {
          },
          (error) => {
            this.router.navigate(['/expire']);
          }
        );
      }
    });
  }

}
