import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { RecaptchaModule } from 'ng-recaptcha';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { CustomPaginationComponent } from './custom-pagination/custom-pagination.component';
import { FilterHeaderComponent } from './filter-header/filter-header.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { DeleteConfirmationModalComponent } from './delete-confirmation-modal/delete-confirmation-modal.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { PageExpiredComponent } from './page-expired/page-expired.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    ChangepasswordComponent,
    CustomPaginationComponent,
    FilterHeaderComponent,
    LoadingSpinnerComponent,
    DeleteConfirmationModalComponent,
    PageExpiredComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    DropdownModule,
    NgbPaginationModule,
    TranslateModule,
    ToastModule,
    RouterModule,
    RecaptchaModule,
    FontAwesomeModule
  ],
  exports: [
    CustomPaginationComponent,
    FilterHeaderComponent,
    LoadingSpinnerComponent,
    DeleteConfirmationModalComponent,
    ToastModule
  ]
})
export class CoreModule { }
