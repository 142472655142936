import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Observable, Subject, map, of, takeUntil, zip } from "rxjs";
import { BranchModel, DatatableCustomColumnModel, DocumentTypeModel, LanguageType, LookupModel, PayloadDownloadFileModel, PayloadYearAndMonthModel, ResponseMessageBranchModel, ResponseMessageCompanyModel, RowActionEventModel, SortType } from "../../shared/interfaces";
import { MONTHS } from "../../shared/services/shared.constant";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "../../service/api.service";
import { AuthenticationService } from "../../service/authentication.service";
import { HttpClientService } from "../../service/http-client.service";
import { HandleModalService, HandleTranslateService, MasterDataService, UtilitiesService } from "../../shared/services";
import { CHECK_FROM_ACCOUNT } from "./zip-file.contant";
import { environment } from "../../../environments/environment";
import { PayloadDownloadZipModel, PayloadRevenueDocumentsModel, ResponseMessageRevenueDocumentsModel, RevenueDocumentsModel } from "./zip-file.interface";
import { DatatablesComponent } from "../../shared/components/datatables/datatables.component";

@Component({
  selector: 'app-zip-file',
  templateUrl: './zip-file.component.html',
  styleUrl: './zip-file.component.scss'
})
export class ZipFileComponent implements OnInit, OnDestroy {

  @ViewChild('datatables') private datatablesElement: DatatablesComponent | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public allDocumentType: DocumentTypeModel = {
    type: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public branches: LookupModel[] = [];
  private defaultBranches: BranchModel[] = [];
  public documentType: DocumentTypeModel[] = [];
  public years: number[] = [];
  public months: LookupModel[] = [ ...MONTHS ];
  public checkFromAccounts = [ ...[ this.allSelect ], ...CHECK_FROM_ACCOUNT ];

  public downloadProgress: number = 0;

  private currentDate: Date = new Date();

  public pages: number = 1;
  public limitRow: number = 10;
  public collectionSize: number = 0;
  public sortBy: string | undefined;
  public sortMode: SortType | undefined;
  public rows: RevenueDocumentsModel[] = [];
  public selectedRows: RevenueDocumentsModel[] = [];
  public blockRenderingCheckboxKey: string = 'can_download_zip';
  public blockRenderingCheckboxBoolean: boolean = false;
  public columns: DatatableCustomColumnModel[] = [
    {
      id: 'checkbox',
      label: '',
      name: '',
      type: 'checkbox',
      width: '4rem',
      minWidth: '4rem'
    },
    {
      id: 'no',
      label: 'NEW-TRANSLATE.COMMON.NO',
      name: 'no',
      type: 'no',
      width: '80px',
      minWidth: '80px'
    },
    {
      id: 'branchName',
      label: 'NEW-TRANSLATE.COMMON.BRANCH-NAME',
      name: 'branch_fullname',
      type: 'string',
      width: '140px',
      minWidth: '140px',
      defaultValue: '-',
      sort: true
    },
    {
      id: 'documentType',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE',
      name: 'rd_document_type',
      type: 'document-type',
      width: '150px',
      minWidth: '150px',
      sort: true
    },
    {
      id: 'numberOfDocuments',
      label: 'NEW-TRANSLATE.COMMON.NUMBER-OF-DOCUMENTS',
      name: 'total_documents',
      type: 'number',
      width: '120px',
      minWidth: '120px',
      format: '0.0-0',
      defaultValue: '0',
      sort: true
    },
    {
      id: 'verifiedFromAccount',
      label: 'NEW-TRANSLATE.REVENUE-DOCUMENTS.VERIFIED-FROM-ACCOUNT',
      name: 'can_download_zip',
      type: 'boolean-icon',
      width: '100px',
      minWidth: '100px'
    },
    {
      id: 'downloadZipFile',
      label: 'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-ZIP',
      name: 'download-zip',
      type: 'button',
      format: 'download-zip',
      width: '130px',
      minWidth: '130px',
      lazyLoading: true
    },
    {
      id: 'downloadSuccessfully',
      label: 'NEW-TRANSLATE.COMMON.DOWNLOAD-SUCCESSFULLY',
      name: 'uploaded',
      type: 'boolean-icon',
      width: '100px',
      minWidth: '100px'
    }
  ];

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    corporate_id: new FormControl(null),
    corporate_branch_id: new FormControl(null),
    rd_document_type: new FormControl(null),
    month: new FormControl(this.currentDate.getMonth() + 1, [ Validators.required ]),
    year: new FormControl(this.currentDate.getFullYear(), [ Validators.required ]),
    can_download_zip: new FormControl(null),
    not_uploaded_only: new FormControl(false, [ Validators.required ])
  });

  public isLoading: boolean = true;
  public isLoadingRows: boolean = false;
  public isLoadingOther: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private handleTranslateService: HandleTranslateService,
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService,
    private masterDataService: MasterDataService
  ) {
    this.checkPermission();
    this.filterColumn();
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private checkPermission(): void {
    if (!this.authenticationService.isRevenueDocumentPermission()) {
      this.router.navigate(['/']);
    }
  }

  private filterColumn(): void {
    let columns = [...this.columns];
    const removeColumns = (colsToRemove: string[]) => columns = columns.filter(x => !colsToRemove.includes(x.id));
    if (!this.isDownloadAllDocumentPermission()) {
      removeColumns([ 'checkbox', 'downloadZipFile' ]);
    }
    this.columns = columns;
  }

  public isViewer(): boolean {
    return this.authenticationService.isViewerImpAndHelp() || this.authenticationService.isViewerOperation();
  }

  public isDownloadAllDocumentPermission(): boolean {
    return this.authenticationService.isAccountantAdmin() || this.authenticationService.isUIH();
  }

  private initialSetting(): void {
    this.isLoading = true;
    zip(
      this.loadCompany(),
      this.loadBranch(),
      this.loadYear(),
      this.loadDocumentType()
    )
    .pipe()
    .subscribe({
      next: ([ companies, branches, years, documentType ]) => {
        this.years = years;
        this.companies = companies;
        this.defaultBranches = branches.results;
        this.documentType = documentType;
        if (!this.authenticationService.isSCBBCM() && this.companies.length === 1) {
          const defaultCompany = this.companies[0].id;
          this.form.controls['corporate_id'].setValue(defaultCompany, { emitEvent: false });
          this.filterBranches(defaultCompany);
        } else {
          this.companies.unshift(this.allSelect);
          this.filterBranches();
        }
        this.loadRevenueDocuments();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    })
  }

  private loadYear(): Observable<number[]> {
    const currentYear = this.currentDate.getFullYear();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - i);
    return of(years);
  }

  public filterBranches(companyId?: number | string | null) {
    this.form.controls['corporate_branch_id'].setValue(null);
    this.branches = Object.assign([] as BranchModel[], this.defaultBranches)
                      .filter(x => x.corporate === companyId || !companyId)
                      .map(x => {
                        const filterName = x.full_name;
                        return { name_th: filterName, name_en: filterName, id: x.id };
                      });
    this.branches.unshift(this.allSelect);          
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.apiService
      .getCompany()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(res => {
          const newRes =  res as ResponseMessageCompanyModel;
          return [ ...newRes.results ].map(x => {
            return { name_th: x.name, name_en: x.name, id: x.id }
          });
        })
      );
  }

  private loadBranch(): Observable<ResponseMessageBranchModel> {
    return this.apiService
        .getBranch()
        .pipe(
          takeUntil(this.unsubscribe$),
          map(x => x as ResponseMessageBranchModel)
        );
  }

  private loadDocumentType(): Observable<DocumentTypeModel[]> {
    return of(this.masterDataService.getDocumentType())
            .pipe(
              map(res => {
                const newRes: DocumentTypeModel[] = res.filter(x => x.is_sent_to_rd).map(x => {
                        return { type: x.type, name_th: x.name_th, name_en: x.name_en }
                      });
                      newRes.unshift(this.allDocumentType);
                return newRes;
              })
            );
  }

  public onSearch(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.submitted = false;
    this.pages = 1;
    this.datatablesElement?.reset();
    this.loadRevenueDocuments();
  }

  public onClear(): void {
    const defaultCorportateId = this.companies.length === 1 ? this.companies[0].id : null;
    const formControl = this.form.controls;
          formControl['corporate_id'].setValue(defaultCorportateId);
          formControl['corporate_branch_id'].setValue(null);
          formControl['rd_document_type'].setValue(null);
          formControl['month'].setValue(this.currentDate.getMonth() + 1);
          formControl['year'].setValue(this.currentDate.getFullYear());
          formControl['can_download_zip'].setValue(null);
          formControl['not_uploaded_only'].setValue(false);
    this.filterBranches(defaultCorportateId);
    this.pages = 1;
    this.submitted = false;
    this.sortBy = undefined;
    this.sortMode = undefined;
    this.datatablesElement?.reset();
    this.loadRevenueDocuments();
  }

  public pageChanged(): void {
    this.loadRevenueDocuments();
  }

  public limitRowChanged(event: number): void {
    this.limitRow = event;
    this.pages = 1;
    this.loadRevenueDocuments();
  }

  public onSortEvent(): void {
    this.loadRevenueDocuments();
  }

  private loadRevenueDocuments(): void {
    this.rows = [];
    this.selectedRows = [];
    this.isLoadingRows = true;
    const formValue = this.form.value;
    const payload: PayloadRevenueDocumentsModel = {
      items_per_page: this.limitRow,
      corporate_id: formValue['corporate_id'] ?? undefined,
      corporate_branch_id: formValue['corporate_branch_id'] ?? undefined,
      year: formValue['year'],
      month: formValue['month'],
      rd_document_type: formValue['rd_document_type'] ?? undefined,
      can_download_zip: formValue['can_download_zip'] === null ?  undefined : formValue['can_download_zip'] === 1 ? true : false,
      not_uploaded_only: formValue['not_uploaded_only'],
      sort: this.sortBy,
      sort_type: this.sortMode
    };
    this.httpClientService
      .post(`${ environment.apiURL }/api/sales_tax_zip_views/?page=${ this.pages }`, payload)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(res => {
          const newRes = res as ResponseMessageRevenueDocumentsModel;
          const newResults = this.utilitiesService.insertUniqueIdToArrayForCheckboxTable([ ...newRes.results ]);
                newRes.results = newResults;
          return newRes;
        })
      )
      .subscribe({
        next: (res) => {
          this.downloadProgress =  Math.floor(res.count > 0 ? ((res.results.filter(x => x.uploaded).length / res.count) * 100.0) : 0);
          this.collectionSize = res.count;
          this.rows = res.results;
          this.isLoadingRows = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingRows = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onRowActionEvent(event: RowActionEventModel): void {
    if (event.action === 'download-zip') {
      this.onDownloadZip(event);
    }
  }

  private onDownloadZip(row: RowActionEventModel): void {
    this.isLoadingOther = true;
    this.utilitiesService.toggleLoadingCell(undefined, row.loadingElement);
    const formValue = this.form.value;
    const data = row.row as RevenueDocumentsModel;
    const payload: PayloadDownloadZipModel = {
      year: formValue['year'],
      month: formValue['month'],
      rd_document_type: data.rd_document_type,
      corporate_branch_id: data.corporate_branch
    };
    this.httpClientService
      .postBlob(`${ environment.apiURL }/api/zip_file_two_megabyte/`, payload)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(x => x as Blob)
      )
      .subscribe({
        next: (res) => {
          this.utilitiesService.downloadFile(res, this.getFilename(data, payload.year, payload.month))
          this.isLoadingOther = false;
          this.utilitiesService.toggleLoadingCell(undefined, row.loadingElement);
        },
        error: (err) => {
          console.error(err);
          this.utilitiesService.toggleLoadingCell(undefined, row.loadingElement);
          this.isLoadingOther = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            if (err.error instanceof Blob) {
              this.utilitiesService
                .convertBlobToJson(err.error)
                .subscribe({
                  next: (json) => {
                    const errorMessage = this.utilitiesService.transformErrorsToTextModal(json);
                    this.handleModalService.connectFailedModal(errorMessage);
                  },
                  error: () => {
                    this.handleModalService.connectFailedModal();
                  }
                });
            } else {
              const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
              this.handleModalService.connectFailedModal(errorMessage);
            }
          }
        }
      });
  }

  public onDownloadExcel(): void {
    this.isLoadingOther = true;
    const formValue = this.form.value;
    const params = {
      year: formValue['year'],
      month: formValue['month']
    };
    this.httpClientService
      .getBlob(`${ environment.apiURL }/api/export_excel_monthly_report/`, params)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(x => x as Blob)
      )
      .subscribe({
        next: (res) => {
          const filename = 'tax_report_' + params.year + '_' + params.month + '.xlsx';
          this.utilitiesService.downloadFile(res, filename);
          this.isLoadingOther = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingOther = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            if (err.error instanceof Blob) {
              this.utilitiesService
                .convertBlobToJson(err.error)
                .subscribe({
                  next: (json) => {
                    const errorMessage = this.utilitiesService.transformErrorsToTextModal(json);
                    this.handleModalService.connectFailedModal(errorMessage);
                  },
                  error: () => {
                    this.handleModalService.connectFailedModal();
                  }
                });
            } else {
              const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
              this.handleModalService.connectFailedModal(errorMessage);
            }
          }
        }
      });
  }

  public onDownloadAllTaxReport(): void {
    this.isLoadingOther = true;
    const formValue = this.form.value;
    const payloadYearAndMonth: PayloadYearAndMonthModel = {
      year: formValue['year'],
      month: formValue['month']
    };
    const downloads = Object.assign([] as RevenueDocumentsModel[], this.selectedRows)
      .map(x => {
        return {
          payload: {
             ...payloadYearAndMonth, 
            ...{
              rd_document_type: x.rd_document_type,
              corporate_branch_id: x.corporate_branch
            }
          },
          filename: this.getFilename(x, formValue['year'], formValue['month'])
        } as PayloadDownloadFileModel;
      });
    this.isLoadingOther = true;
    this.handleModalService
      .downloadFileModal(downloads, false)
      .result
      .then(() => {
        this.loadRevenueDocuments();
        this.isLoadingOther = false;
      })
      .catch(() => this.isLoadingOther = false)
    
  }

  private getFilename(data: RevenueDocumentsModel, year: number, month: number): string {
    return [
      month || '',
      year || '',
      data.internal_branch_code || '',
      data.branch_code || '',
      data.rd_document_type || ''
    ].join('_').replace(/([^a-z0-9]+)/gi, '_') + '.zip';
  }

}