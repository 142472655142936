import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfilePasswordComponent } from './profile-password/profile-password.component';

export const routes: Routes = [
  {
    path: 'profile',
    children: [
      {
        path: 'password',
        component: ProfilePasswordComponent
      },
      // {
      //   path: 'list',
      //   component: ProfileListComponent
      // }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
