import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forEach as lodashForEach } from 'lodash';
import { DocumentTypeModel } from '../shared/interfaces';
import { DOCUMENT_TYPE } from '../shared/services/shared.constant';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private documentTemplates = [ ...DOCUMENT_TYPE ];

  constructor(
    private translateService: TranslateService
  ) {
  }

  get(): DocumentTypeModel[] {
    return this.documentTemplates;
  }

  getTemplate(type: string | null): DocumentTypeModel {
    let template: any;
    lodashForEach(this.documentTemplates, (obj) => {
      if (obj.type === type) {
        template = obj;
      }
    })
    return template;
  }

  getName(type: string | null): string | null {
    const template = this.getTemplate(type);
    return template?.name_th ?? null;
  }

  getNameEN(type: any): string | null {
    const template = this.getTemplate(type)
    return template.name_en ?? null;
  }

  getChangeLang(type: any): string | null {
    if (this.translateService.currentLang !== 'en'){
      const template = this.getTemplate(type)
      return template?.name_th ?? null;
    } else {
      const template = this.getTemplate(type)
      return template?.name_en ?? null;
    }
  }

  getFullName(type: any): string | null {
    const template = this.getTemplate(type);
    if (template) {
      return (template.name_th + ' ('  + template.name_en + ')') ;
    }
    return null;
  }

  getDropdown() {
    let type: { label: string, value: any | null }[] = [{
      label: 'ทั้งหมด',
      value: null
    }];
   lodashForEach(this.documentTemplates, (obj) => {
      type.push({
        label: obj.name_th + ' (' + obj.name_en + ')',
        value: obj.type
      });
    });
    return type;
  }

}
