<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{'MENU.APPROVAL-HISTORY' | translate}}</h3>
    </div>
  </div>
</div>

<div class="box-filter position-relative z-2">
  <app-filter-header [loading]="loading" (submit)="searchButtonClicked()" (clear)="clearData()"></app-filter-header>

  <div class="row">
    <div class="col p-5px">
      <div class="dropdown-label-group">
        <p-dropdown class="dv-dropdown" selectId="status" [options]="statusList" [(ngModel)]="selectedStatus"
          [autoDisplayFirst]="false" placeholder="{{'EMAIL-DASHBOARD.STATUS' | translate}}" [style]="{'z-index': 3}">
        </p-dropdown>
        <label for="status">{{'EMAIL-DASHBOARD.STATUS' | translate}}</label>
      </div>
    </div>

    <div class="col p-5px">
      <div class="dropdown-label-group">
        <p-dropdown class="dv-dropdown" selectId="month" [options]="monthListDropdown" [(ngModel)]="selectedMonth"
          [autoDisplayFirst]="false" placeholder="{{'FILTER.MONTH' | translate}}" [style]="{'z-index': 3}">
        </p-dropdown>
        <label for="month">{{'FILTER.MONTH' | translate}}</label>
      </div>
    </div>

    <div class="col p-5px">
      <div class="dropdown-label-group">
        <p-dropdown class="dv-dropdown" selectId="year" [options]="yearList" [(ngModel)]="selectedYear"
          [autoDisplayFirst]="false" placeholder="{{'FILTER.YEAR' | translate}}" [style]="{'z-index': 3}">
        </p-dropdown>
        <label for="year">{{'FILTER.YEAR' | translate}}</label>
      </div>
    </div>
  </div>

</div>

<div class="box-parent position-relative z-1">
  <app-custom-pagination [(currentPage)]="currentPage" [(itemsPerPage)]="itemsPerPage" [totalItems]="totalItems"
    (pageChange)="pageChanged()" (optionChangeValue)="setPageSide($event)">
  </app-custom-pagination>

  <div class="m-t-20">
    <p-table class="table text-center" *ngIf="transactionDataList.length != 0 && !loading" [value]="transactionDataList"
      [autoLayout]="true" [columns]="tableCol" [loading]="loading">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th scope="col">{{ 'COMMON.TABLE.ORDER' | translate }}</th>
          <th scope="col">{{ 'TABLE.CREATE-DATE' | translate }}</th>
          <th scope="col">{{ 'TABLE.UPDATE-DATE' | translate }}</th>
          <th scope="col">{{ 'TABLE.USERNAME' | translate }}</th>
          <th scope="col">{{ 'TABLE.EMAIL' | translate }}</th>
          <th scope="col">{{ 'TEXT.ROLE' | translate }}</th>
          <th scope="col">{{ 'EMAIL-DASHBOARD.COMPANY' | translate }}</th>
          <th scope="col">{{ 'TABLE.REQUEST-TYPE' | translate }}</th>
          <th scope="col">{{ 'TABLE.REQUEST-STATUS' | translate }}</th>
          <th scope="col">{{ 'TEXT.ACCESSIBILITY' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td>{{index + 1 + ((currentPage - 1) * itemsPerPage)}}</td>
          <td>{{ data.created_date || '-'}}</td>
          <td>{{ data.updated_date || '-'}}</td>
          <td>{{ data.username || data.old_username || '-' }}</td>
          <td>{{ data.email || '-' }}</td>
          <td>{{ data.new_group || data.old_group }}</td>
          <td>{{ data.company_name || '-' }}</td>
          <td>{{ data.request_type || '-' }}</td>
          <td>{{ data.request_status || '-' }}</td>
          <td>
            <fa-icon *ngIf="data.is_active === false" [icon]="['fas', 'xmark']" aria-hidden="true"></fa-icon>
            <fa-icon *ngIf="data.is_active === true" [icon]="['fas', 'check']" aria-hidden="true"></fa-icon>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="row m-t-20" *ngIf="transactionDataList.length == 0 && !loading">
    <div class="col-12 text-center">
      <h5>{{'COMMON.DATA-NOT-FOUND' | translate}}</h5>
    </div>
  </div>

  <div class="row m-t-20" *ngIf="loading">
    <div class="col-12 text-center">
      <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']" size="2xl"></fa-icon>
    </div>
  </div>

</div>
