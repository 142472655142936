import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentUploadModule } from './document';
import { ReportModule } from './report/report.module';
import { SearchModule } from './search';
import { DashboardModule } from './dashboard/dashboard.module';
import { BillingModule } from './billing/billing.module';
import { ProfileModule } from './profile/profile.module';
import { TaxReportModule } from './tax-report/tax-report.module';
import { ZipFileModule } from './zip-file/zip-file.module';
import { EmailDashboardModule } from './email-dashboard/email-dashboard.module';
import { UserManagementModule } from './user-management/user-management.module';
import { DataPurgeModule } from './data-purge/data-purge.module';
import { FeeEngineModule } from './fee-engine/fee-engine.module';
import { FeeDebitAccountModule } from './fee-debit-account/fee-debit-account.module';
import { ApprovalHistoryModule } from './approval-history/approval-history.module';
import { TaxB2pModule } from './tax-b2p/tax-b2p.module';
import { KeyinModule } from './keyin';
import { SettingModule } from './setting';
import { SubmitToRdModule } from './submit-to-rd';
import { RdSummaryModule } from './rd-summary';
import { BulkTaxReportModule } from './bulk-tax-report';
import { AnnouncementModule } from './announcement';
import { MonitoringModule } from './monitoring';

import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-date-fns';
Chart.register(...registerables);

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    DocumentUploadModule,
    ReportModule,
    SearchModule,
    DashboardModule,
    BillingModule,
    ProfileModule,
    TaxReportModule,
    ZipFileModule,
    TaxB2pModule,
    EmailDashboardModule,
    UserManagementModule,
    DataPurgeModule,
    FeeEngineModule,
    FeeDebitAccountModule,
    ApprovalHistoryModule,
    KeyinModule,
    SettingModule,
    SubmitToRdModule,
    RdSummaryModule,
    BulkTaxReportModule,
    AnnouncementModule,
    MonitoringModule
  ]
})
export class FeaturesModule { }
