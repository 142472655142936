import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrl: './time-picker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    }
  ]
})
export class TimePickerComponent implements OnInit, OnDestroy {

  @ViewChild('timePicker') public timePicker!: Calendar;

  @Input() public placeholder: string | undefined;
  @Input() public label: string | undefined;
  @Input() public format: string | undefined;

  @Input() public maxTime: Date | undefined;
  @Input() public minTime: Date | undefined;
  @Input() public inputId: string | undefined;
  
  @Input() public hours: number = 0;
  @Input() public minutes: number = 0;
  
  @Input() public isLoading: boolean = false;
  @Input() public isDisabled: boolean = false;

  @Output() onChangeTime = new EventEmitter<Date | undefined>();
  private onTouched!: Function;
  private onChanged!: Function;
  
  @Input() public time: Date | undefined;
  @Input() public invalid: boolean = false;
  @Input() public clearable: boolean = false;

  private mutationObserver: MutationObserver | undefined;

  constructor(
    private elementRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.settingObservable();
  }

  ngAfterViewInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.mutationObserver?.disconnect();
  }

  private initialSetting(): void {
    const date = new Date();
          date.setHours(this.hours);
          date.setMinutes(this.minutes);
          date.setMilliseconds(0);
    this.timePicker.defaultDate = date;
  }

  private settingObservable(): void {
    this.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const currentEl = this.elementRef.nativeElement as HTMLElement;
        if (currentEl.classList.contains('is-invalid')) {
          this.invalid = true;
        } else {
          this.invalid = false;
        }
      });
    });

    this.mutationObserver.observe(this.elementRef.nativeElement, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  writeValue(date: Date | undefined): void {
    this.time = date;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public changeTime(event: Date | undefined): void {
    this.onChangeTime.emit(event);
    if(this.onTouched) {
      this.onTouched();
    }
    if(this.onChanged) {
      this.onChanged(this.time);
    }
  }

  public clearTime(): void {
    this.time = undefined;
    this.onChangeTime.emit(undefined);
    if(this.onTouched) {
      this.onTouched();
    }
    if(this.onChanged) {
      this.onChanged(undefined);
    }
  }

}

