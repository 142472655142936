import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { routes as MainRoutes } from './main/main-routing.module';
import { routes as ChangePasswordRoutes } from './core/changepassword/changepassword-routing.module';

const routes: Routes = [
  {
    path: '',
    children: [
      ...MainRoutes,
      ...ChangePasswordRoutes
    ]
  },
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
