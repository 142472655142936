<ng-template #modalTemplate>
  <div class="modal-header" style="background-color: #633ba5;color: #ffffff;">
    <h4 class="modal-title pull-left"style="background-color: #633ba5">{{title}}</h4>
  </div>
  <div class="modal-body box-parent" style="border:none;">
    <div class="row">
      <div class="col">
        <div class="form-label-group">
          <input type="text" id="un" class="form-control" [(ngModel)]="user.username" [disabled]="true" placeholder="ชื่อผู้ใช้">
          <label for="un">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</label>
        </div>
        <div class="dropdown-label-group">
          <p-dropdown
            class="dv-dropdown"
            selectId="group"
            [options]="groups"
            [(ngModel)]="user.group"
            [autoDisplayFirst]="false"
            placeholder="{{'PROFILE.LIST.FILTER.LICENSE' | translate}}"
          >
          </p-dropdown>
          <label for="group">{{'PROFILE.LIST.FILTER.LICENSE' | translate}}</label>
        </div>
        <div>
          <div *ngIf="!user.is_active" style="display: flex; align-items: center;">
            <div style="padding-left:12px; flex-grow: 1;"> {{'PROFILE.LIST.MODAL.CURRENT-STATUS' | translate}}: {{'PROFILE.LIST.MODAL.DISABLED' | translate}} </div>
            <button class="col-auto btn btn-dv btn-danger btn-wide" (click)="disableUser()">{{'PROFILE.LIST.MODAL.SUSPEND' | translate}}</button>
          </div>
          <div *ngIf="user.is_active" style="display: flex; align-items: center;">
            <div style="padding-left:12px; flex-grow: 1;"> {{'PROFILE.LIST.MODAL.CURRENT-STATUS' | translate}}: {{'PROFILE.LIST.TABLE.STATUS.ENABLED' | translate}} </div>
            <button class="col-auto btn btn-dv btn-clean btn-wide" (click)="enableUser()">{{'PROFILE.LIST.TABLE.STATUS.SUSPENDED' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer-alternative m-b-0">
      <div class="col">
        <div class="row m-b-5">
          <div class="col text-center text-red">{{ errorMessage ? errorMessage : '&nbsp;' }}</div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <button class="btn btn-dv btn-clean btn-wide m-r-20" (click)="modalRef?.hide()">{{'COMMON.CANCEL' | translate}}</button>
          </div>
          <button class="col-auto btn btn-dv btn-wide pull-right" (click)="submit()">{{ createMode ? ('PROFILE.PASSWORD.CONFIRM' | translate) : ('PROFILE.LIST.MODAL.MODIFY' | translate) }}</button>
        </div>
      </div>
  </div>
</ng-template>
