import { Component, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { KEYIN_VALIDATORS, VAT_LIST } from '../../keyin-constant';
import { LanguageType } from '../../../../shared/interfaces';
import { HandleTranslateService } from '../../../../shared/services';

@Component({
  selector: 'app-keyin-section-5',
  templateUrl: './keyin-section-5.component.html',
  styleUrls: [ './keyin-section-5.component.scss', '../../keyin.component.scss' ]
})
export class KeyinSection5Component implements OnDestroy {

  private unsubscribe$: Subject<void> = new Subject();
  public language: LanguageType;

  public validators = KEYIN_VALIDATORS;
  
  @Input() public form!: FormGroup;
  @Input() public submitted: boolean = false;
  @Input() public isLoading: boolean = false;
  @Input() public endOfBillOption: boolean = false;

  public vatList: string[] = VAT_LIST;

  constructor(
    private handleTranslateService: HandleTranslateService
  ) {
    this.subscribeToServices();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

}
