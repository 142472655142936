import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindByKeyPipe } from './find-by-key.pipe';
import { TotalPagesPipe } from './total-pages.pipe';
import { DocumentTypePipe } from './document-type.pipe';
import { JoinStringPipe } from './join-string.pipe';
import { ShowAndHideCheckboxPipe } from './show-and-hide-checkbox.pipe';
import { SplitStringPipe } from './split-string.pipe';
import { MonthPipe } from './month.pipe';
import { IsArrayPipe } from './is-array.pipe';
import { IsStringPipe } from './is-string.pipe';
import { TransformDatePipe } from './transform-date.pipe';
import { FormatBytePipe } from './format-byte.pipe';

@NgModule({
  declarations: [
    FindByKeyPipe,
    TotalPagesPipe,
    DocumentTypePipe,
    JoinStringPipe,
    ShowAndHideCheckboxPipe,
    SplitStringPipe,
    MonthPipe,
    IsArrayPipe,
    IsStringPipe,
    TransformDatePipe,
    FormatBytePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FindByKeyPipe,
    TotalPagesPipe,
    DocumentTypePipe,
    JoinStringPipe,
    ShowAndHideCheckboxPipe,
    SplitStringPipe,
    MonthPipe,
    IsArrayPipe,
    IsStringPipe,
    TransformDatePipe,
    FormatBytePipe
  ],
  providers: [
    FindByKeyPipe,
    TotalPagesPipe,
    DocumentTypePipe,
    JoinStringPipe,
    ShowAndHideCheckboxPipe,
    SplitStringPipe,
    MonthPipe,
    IsArrayPipe,
    IsStringPipe,
    TransformDatePipe,
    FormatBytePipe
  ]
})
export class SharedPipeModule { }
