<div class="header row align-items-center">
  <div class="col-auto text-left" *ngIf="viewing != null">
    <button class="btn btn-dv btn-clean btn-compact" (click)="viewSummary()">
      < {{'TAX-B2P.RETURN' | translate}}
    </button>
  </div>
  <div class="col">
    <h3 class="m-y-0">{{'TAX-B2P.TITLE' | translate}}</h3>
  </div>
</div>

<div class="box-filter position-relative z-2">
  <app-filter-header [loading]="loading" (submit)="search()" (clear)="clear()"></app-filter-header>
  <div *ngIf="viewing == null">
    <div class="row">
      <div class="col-md-4">
        <div class="dropdown-label-group">
          <p-dropdown
            class="dv-dropdown"
            selectId="company"
            [options]="company"
            [(ngModel)]="selectedCompany"
            [autoDisplayFirst]="false"
            placeholder="{{'FILTER.COMPANY' | translate}}"
            [style]="{'z-index': 3}"
          >
          </p-dropdown>
          <label for="company">{{'FILTER.COMPANY' | translate}}</label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="dropdown-label-group">
          <p-dropdown
            class="dv-dropdown"
            selectId="buyer"
            [options]="supplier"
            [(ngModel)]="selectedBuyer"
            [autoDisplayFirst]="false"
            placeholder="{{'TAX-B2P.FILTER.BUYER' | translate}}"
            [style]="{'z-index': 3}"
          >
          </p-dropdown>
          <label for="buyer">{{'TAX-B2P.FILTER.BUYER' | translate}}</label>
        </div>
      </div>
    </div>

    <div class="row m-t-10">
      <div class="col-md-4">
        <div class="dropdown-label-group">
          <p-dropdown
            class="dv-dropdown"
            selectId="document-type"
            [options]="documentType"
            [(ngModel)]="selectedDocumentType"
            [autoDisplayFirst]="false"
            placeholder="{{'COMMON.CATEGORY' | translate}}"
          >
          </p-dropdown>
          <label for="document-type">{{'COMMON.CATEGORY' | translate}}</label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="dropdown" #dd="ngbDropdown" ngbDropdown autoClose="outside">
          <div class="form-label-group">
            <input #ddi class="form-control" type="text" id="calendarDropdown" [value]="dateRange"
                   ngbDropdownToggle placeholder="Batch Date" readonly>
            <label for="calendarDropdown">Batch Date</label>
            <div class="calendar-trigger" (click)="$event.stopPropagation(); dd.toggle()">
              <span class="far fa-calendar"></span>
            </div>
          </div>
          <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="calendarDropdown">
            <div class="row">
              <div class="col" style="padding-left: 30px">{{'FILTER.CALENDAR.SINCE' | translate}}</div>
              <div class="col" style="padding-left: 30px">{{'FILTER.CALENDAR.UNTIL' | translate}}</div>
            </div>
            <div (click)="stopPropagation($event)">
              <p-calendar [(ngModel)]="startDate" [defaultDate]="startDate" dateFormat="dd/mm/yy" [maxDate]="maxDate"
                          readonlyInput="true" [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                          [yearRange]="yearRange" (onSelect)="setMinDate()" [inline]="true"
                          [locale]="calendarLocale"></p-calendar>
              <p-calendar [(ngModel)]="endDate" [defaultDate]="startDate" dateFormat="dd/mm/yy" [minDate]="minDate"
                          readonlyInput="true" [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true"
                          [yearRange]="yearRange" (onSelect)="setMaxDate(); dd.close()" [inline]="true"
                          [locale]="calendarLocale"></p-calendar>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="dropdown-label-group">
          <p-dropdown
            class="dv-dropdown"
            selectId="status"
            [options]="status"
            [(ngModel)]="selectedStatus"
            [autoDisplayFirst]="false"
            placeholder="{{'TAX-B2P.STATUS' | translate}}"
          >
          </p-dropdown>
          <label for="status">{{'TAX-B2P.STATUS' | translate}}</label>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="error">
      <div class="col text-center text-red">
        {{ error }}
      </div>
    </div>
  </div>
  <div *ngIf="viewing != null">
    <div class="row">
      <div class="col-md-4">
        <div class="dropdown-label-group">
          <p-dropdown
            class="dv-dropdown"
            selectId="detail-document-type"
            [options]="documentType"
            [(ngModel)]="selectedDetailDocumentType"
            [autoDisplayFirst]="false"
            placeholder="{{'COMMON.CATEGORY' | translate}}"
          >
          </p-dropdown>
          <label for="detail-document-type">{{'COMMON.CATEGORY' | translate}}</label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="dropdown-label-group">
          <p-dropdown
            class="dv-dropdown"
            selectId="detail-status"
            [options]="status"
            [(ngModel)]="selectedDetailStatus"
            [autoDisplayFirst]="false"
            placeholder="{{'TAX-B2P.STATUS' | translate}}"
          >
          </p-dropdown>
          <label for="detail-status">{{'TAX-B2P.STATUS' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="box-parent position-relative z-1" *ngIf="viewing == null">
  <app-custom-pagination
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    [totalItems]="totalItems"
    (pageChange)="pageChanged()"
    (optionChange)="search()"
    *ngIf="tableData && tableData.length > 0"
  >
  </app-custom-pagination>

  <div class="row m-t-20" *ngIf="(!tableData || tableData.length === 0) && loading === false">
    <div class="col-12 text-center">
      <h5>{{'COMMON.DATA-NOT-FOUND' | translate}}</h5>
    </div>
  </div>

  <div class="row m-t-20" *ngIf="(!tableData || tableData.length === 0) && loading === true">
    <div class="col-12 text-center">
      <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']" size="2xl"></fa-icon>
    </div>
  </div>

  <div class="m-t-20">
    <p-table #tt class="text-center" tableStyleClass="table" *ngIf="tableData && tableData.length > 0" [loading]="loading"
             [columns]="tableColumn" [value]="tableData" [customSort]="true" (onSort)="sort($event)" [autoLayout]="true"
             selectionMode="single" [(selection)]="viewing" (onRowSelect)="viewDetail($event)">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th scope="col">{{'COMMON.TABLE.ORDER' | translate}}</th>
          <th scope="col" *ngFor="let col of columns" [pSortableColumn]="col.field" (click)="pageChanged()">
            {{col.header}}
          </th>
          <th scope="col">Export</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td [pSelectableRow]="data">{{ index + 1 + ((currentPage - 1) * itemsPerPage )}}</td>
          <td [pSelectableRow]="data">{{ data.doc_date_display }}</td>
          <td [pSelectableRow]="data">{{ data.buyer_name }}</td>
          <td [pSelectableRow]="data">{{ data.seller_name }}</td>
          <td [pSelectableRow]="data">{{ data.doc_count_success }}</td>
          <td [pSelectableRow]="data">{{ data.doc_count_fail }}</td>
          <td [pSelectableRow]="data">{{ data.doc_count_all }}</td>
          <td>
            <button class="btn btn-dv btn-clean btn-compact" (click)="downloadExcel(index)">
              <fa-icon *ngIf="data.loadingExcel" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>Excel
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div class="position-relative z-1" *ngIf="viewing != null">
  <div class="box-middle position-relative z-2">
    <span class="text-bold">{{'TAX-B2P.LIST-DAILY-DOCUMENTS' | translate}} {{ viewingDateDisplay }}</span><br>
    <span class="text-bold">{{'FILTER.COMPANY' | translate}}:</span> {{ viewing.seller_name }}<br>
    <span class="text-bold">{{'TAX-B2P.FILTER.BUYER' | translate}}:</span> {{ viewing.buyer_name }}<br>
  </div>

  <div class="box-parent position-relative z-1">
    <app-custom-pagination
      [(currentPage)]="detailCurrentPage"
      [(itemsPerPage)]="detailItemsPerPage"
      [totalItems]="detailTotalItems"
      (pageChange)="detailPageChanged()"
      (optionChange)="search()"
      *ngIf="detailTableData && detailTableData.length > 0"
    >
    </app-custom-pagination>

    <div class="row m-t-20" *ngIf="(!detailTableData || detailTableData.length === 0) && loading === false">
      <div class="col-12 text-center">
        <h5>{{'COMMON.DATA-NOT-FOUND' | translate}}</h5>
      </div>
    </div>

    <div class="row m-t-20" *ngIf="(!detailTableData || detailTableData.length === 0) && loading === true">
      <div class="col-12 text-center">
        <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']" size="2xl"></fa-icon>
      </div>
    </div>

    <div class="m-t-20">
      <p-table #ttd class="text-center" tableStyleClass="table" *ngIf="detailTableData && detailTableData.length > 0" [loading]="loading"
               [columns]="detailTableColumn" [value]="detailTableData" [customSort]="true" (onSort)="detailSort($event)" [autoLayout]="true">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th scope="col">ลำดับ</th>
            <th scope="col" *ngFor="let col of columns" [pSortableColumn]="col.field" (click)="detailPageChanged()">
              {{col.header}}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-index="rowIndex">
          <tr>
            <td>{{ index + 1 + ((detailCurrentPage - 1) * detailItemsPerPage )}}</td>
            <td>{{ data.branch_tax_id }}</td>
            <td>{{ data.header_issue_date_display }}</td>
            <td>{{ data.invoice_number }}</td>
            <td>{{ data.doc_type_display }}</td>
            <td>{{ data.message }}</td>
            <td>
              <span class="chip success" *ngIf="data.status == 'success'">{{'TAX-B2P.SUCCESS' | translate}}</span>
              <span class="chip danger" *ngIf="data.status != 'success'">{{'TAX-B2P.UNSUCCESSFUL' | translate}}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
