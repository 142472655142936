import { Pipe, PipeTransform } from '@angular/core';
import { MONTHS } from '../services/shared.constant';
import { LookupModel } from '../interfaces';

@Pipe({
  name: 'month'
})
export class MonthPipe implements PipeTransform {

  transform(value?: number | string | null): LookupModel | null {
    if (value === null || value === undefined) return null;
    if (typeof value === 'number' || !isNaN(Number(value))) return  MONTHS.find(month => month.id === Number(value)) ?? null;
    return null;
  }

}
