import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent, InformationModalComponent } from './modals';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DatatablesComponent } from './datatables/datatables.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { CoreModule } from '../../core/core.module';
import { SharedPipeModule } from '../pipes/shared-pipe.module';
import { MessageModule } from 'primeng/message';
import { StatusComponent } from './status/status.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { CalendarModule } from 'primeng/calendar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaModule } from 'ng-recaptcha';
import { DownloadFileModalComponent } from './modals/download-file-modal/download-file-modal.component';
import { DirectivesModule } from '../directives';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { AnnouncementMessageComponent } from './announcement-message/announcement-message.component';

@NgModule({
  declarations: [
    InformationModalComponent,
    ConfirmModalComponent,
    DatatablesComponent,
    StatusComponent,
    DateRangePickerComponent,
    DownloadFileModalComponent,
    DatePickerComponent,
    TimePickerComponent,
    AnnouncementMessageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    CheckboxModule,
    TableModule,
    CoreModule,
    SharedPipeModule,
    CalendarModule,
    NgbModule,
    RecaptchaModule,
    DirectivesModule
  ],
  exports: [
    InformationModalComponent,
    ConfirmModalComponent,
    DatatablesComponent,
    CheckboxModule,
    TableModule,
    MessageModule,
    StatusComponent,
    DateRangePickerComponent,
    DatePickerComponent,
    TimePickerComponent,
    AnnouncementMessageComponent
  ],
  providers: [
  ]
})
export class SharedComponentModule { }
