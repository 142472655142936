<div class="setting-configuration-container">
  @if (!isLoading) {
    <h5>{{ 'NEW-TRANSLATE.SETTING.SET-HOW-TO-SEND-DOCUMENT-TO-REVENUE-DEPARTMENT' | translate }}</h5>
    <div class="setting-wrapper">
      <div class="setting-item">
        <div class="setting-item-label-wrapper">
          <p-radioButton 
            inputId="settingAutoRadio"
            [value]="2"
            [(ngModel)]="setting"
          />
          <label class="setting-item-label ms-2" for="settingAutoRadio">
            {{ 'NEW-TRANSLATE.SETTING.AUTO-SEND' | translate }}
          </label>
        </div>
        <p class="setting-item-description">{{ 'NEW-TRANSLATE.SETTING.AUTO-SEND-DESCRIPTION' | translate }}</p>
      </div>
      <div class="setting-item">
        <div class="setting-item-label-wrapper">
          <p-radioButton 
            inputId="settingManualRadio"
            [value]="1"
            [(ngModel)]="setting"
          />
          <label class="setting-item-label ms-2" for="settingManualRadio">
            {{ 'NEW-TRANSLATE.SETTING.MANUAL-SEND' | translate }}
          </label>
        </div>

        <p class="setting-item-description">{{ 'NEW-TRANSLATE.SETTING.MANUAL-SEND-DESCRIPTION' | translate }}</p>
      </div>
      <div class="setting-footer">
        <button
          id="saveButton"
          data-testid="saveButton"
          type="button"
          [title]="'TEXT.SAVE' | translate"
          class="btn btn-dv btn-wide"
          [disabled]="alreadySetting === setting"
          (click)="onSave()"
        >{{ 'NEW-TRANSLATE.COMMON.SAVE' | translate }}</button>
      </div>
    </div>
  } @else {
    <div class="text-center">
      <fa-icon *ngIf="isLoading" class="spinner-border m-r-5" [icon]="['fas','spinner']" size="2xl"></fa-icon>
    </div>
  }  
</div>