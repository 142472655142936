@if (form) {
  <div class="keyin-section-4-container box-parent section-box mb-2">
    <form [formGroup]="form" class="form-wrapper">
      <div class="row g-2 justify-content-between">
        @if (endOfBillOption) {
          <div class="col-12 col-md-6 col-lg-5">
            <div class="form-group d-block d-md-flex align-items-center">
              <div class="form-label form-label-inline mb-2 mb-md-0" for="originalTotalAmountInput">
                {{ 'NEW-TRANSLATE.COMMON.ORIGINAL-TOTAL-AMOUNT' | translate }}
              </div>
              <input
                currencyMask
                autocomplete="off"
                class="form-control"
                data-testid="originalTotalAmountInput"
                formControlName="original_total_amount"
                id="originalTotalAmountInput"
                [maxlength]="validators.end_of_bill.original_total_amount.maxLength + validators.end_of_bill.original_total_amount.decimal"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && form.controls['original_total_amount'].errors }"
              >
            </div>
            <div class="form-group d-block d-md-flex align-items-center">
              <div class="form-label form-label-inline mb-2 mb-md-0" for="newTotalAmountInput">
                {{ 'NEW-TRANSLATE.COMMON.NEW-TOTAL-AMOUNT' | translate }}
              </div>
              <input
                currencyMask
                autocomplete="off"
                class="form-control"
                data-testid="newTotalAmountInput"
                formControlName="new_total_amount"
                id="newTotalAmountInput"
                [maxlength]="validators.end_of_bill.new_total_amount.maxLength + validators.end_of_bill.new_total_amount.decimal"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && form.controls['new_total_amount'].errors }"
              >
            </div>
            <div class="form-group d-block d-md-flex align-items-center">
              <div class="form-label form-label-inline mb-2 mb-md-0" for="differentAmountInput">
                {{ 'NEW-TRANSLATE.COMMON.DIFFERENT-AMOUNT' | translate }}
              </div>
              <input
                currencyMask
                autocomplete="off"
                class="form-control"
                data-testid="differentAmountInput"
                formControlName="different_amount"
                id="differentAmountInput"
                [maxlength]="validators.end_of_bill.different_amount.maxLength + validators.end_of_bill.different_amount.decimal"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && form.controls['different_amount'].errors }"
              >
            </div>
          </div>
        }
        <div
          class="col-12 col-md-6 col-lg-5"
          [ngClass]="{ 'ms-md-auto': !endOfBillOption }"
        >
          <div class="form-group d-block d-md-flex align-items-center">
            <div class="form-label form-label-inline mb-2 mb-md-0" for="totalInput">
              {{ 'NEW-TRANSLATE.KEYIN.TOTAL' | translate }}
            </div>
            <input
              currencyMask
              autocomplete="off"
              class="form-control"
              data-testid="totalInput"
              formControlName="total"
              id="totalInput"
              [maxlength]="validators.end_of_bill.total.maxLength + validators.end_of_bill.total.decimal"
              type="text"
              [ngClass]="{ 'is-invalid': submitted && form.controls['total'].errors }"
            >
          </div>
          <div class="form-group d-block d-md-flex align-items-center">
            <div class="form-label form-label-inline mb-2 mb-md-0" for="discountOrChargeInput">
              {{ 'NEW-TRANSLATE.COMMON.DISCOUNT-OR-CHARGE' | translate }}
            </div>
            <input
              currencyMask
              autocomplete="off"
              class="form-control"
              data-testid="discountOrChargeInput"
              formControlName="discount_or_charge"
              id="discountOrChargeInput"
              [maxlength]="validators.end_of_bill.discount_or_charge.maxLength + validators.end_of_bill.discount_or_charge.decimal"
              type="text"
              [ngClass]="{ 'is-invalid': submitted && form.controls['discount_or_charge'].errors }"
            >
          </div>
          <div class="form-group d-block d-md-flex align-items-center">
            <div class="form-label form-label-inline mb-2 mb-md-0" for="totalAmountDiscrountOrChargeInput">
              {{ 'NEW-TRANSLATE.COMMON.TOTAL-AMOUNT-AFTER-DISCOUNT-OR-CHARGE' | translate }}
            </div>
            <input
              currencyMask
              autocomplete="off"
              class="form-control"
              data-testid="totalAmountDiscrountOrChargeInput"
              formControlName="total_amount_after_discount_or_charge"
              id="totalAmountDiscrountOrChargeInput"
              [maxlength]="validators.end_of_bill.total_amount_after_discount_or_charge.maxLength + validators.end_of_bill.total_amount_after_discount_or_charge.decimal"
              type="text"
              [ngClass]="{ 'is-invalid': submitted && form.controls['total_amount_after_discount_or_charge'].errors }"
            >
          </div>
          <div class="form-group d-block d-md-flex align-items-center">
            <div class="form-label form-label-inline mb-2 mb-md-0" for="baseAmountVatInput">
              {{ 'NEW-TRANSLATE.COMMON.BASE-AMOUNT-VAT' | translate }}
            </div>
            <input
              currencyMask
              autocomplete="off"
              class="form-control"
              data-testid="baseAmountVatInput"
              formControlName="base_amount_vat"
              [maxlength]="validators.end_of_bill.base_amount_vat.maxLength + validators.end_of_bill.base_amount_vat.decimal"
              id="baseAmountVatInput"
              type="text"
              [ngClass]="{ 'is-invalid': submitted && form.controls['base_amount_vat'].errors }"
            >
          </div>
          @if (form.controls['line_items'].value | valueInArray: 'vat_percentage' : vatList[0] ) {
            <div class="form-group d-block d-md-flex align-items-center">
              <div class="form-label-inline mb-2 mb-md-0">
                <div class="d-flex align-items-center">
                  <div class="form-label me-2 mb-0">
                    {{ 'NEW-TRANSLATE.COMMON.VAT-%' | translate }}
                  </div>
                  <input
                    class="form-control text-center"
                    data-testid="vatPercentage"
                    id="vatPercentageView"
                    type="text"
                    value="7"
                    readonly
                  >
                </div>
              </div>
              <input
                currencyMask
                autocomplete="off"
                class="form-control"
                data-testid="vat7Percentage"
                formControlName="vat_7_percentage"
                id="vat7PercentageInput"
                [maxlength]="validators.end_of_bill.vat_7_percentage.maxLength + validators.end_of_bill.vat_7_percentage.decimal"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && form.controls['vat_7_percentage'].errors }"
              >
            </div>
          }
          @if (form.controls['line_items'].value | valueInArray: 'vat_percentage' : vatList[1] ) {
            <div class="form-group d-block d-md-flex align-items-center">
              <div class="form-label form-label-inline mb-2 mb-md-0" for="baseAmountVat0PercentageInput">
                {{ 'NEW-TRANSLATE.COMMON.BASE-AMOUNT-VAT-0' | translate }}
              </div>
              <input
                currencyMask
                autocomplete="off"
                class="form-control"
                data-testid="baseAmountVat0PercentageInput"
                formControlName="base_amount_vat_0_percentage"
                [maxlength]="validators.end_of_bill.base_amount_vat_0_percentage.maxLength + validators.end_of_bill.base_amount_vat_0_percentage.decimal"
                id="baseAmountVat0PercentageInput"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && form.controls['base_amount_vat_0_percentage'].errors }"
              >
            </div>
          }
          @if (form.controls['line_items'].value | valueInArray: 'vat_percentage' : vatList[2] ) {
            <div class="form-group d-block d-md-flex align-items-center">
              <div class="form-label form-label-inline mb-2 mb-md-0" for="baseAmountNonVatInput">
                {{ 'NEW-TRANSLATE.COMMON.BASE-AMOUNT-NON-VAT' | translate }}
              </div>
              <input
                currencyMask
                autocomplete="off"
                class="form-control"
                data-testid="baseAmountVatNonVatInput"
                formControlName="base_amount_non_vat"
                id="baseAmountVatNonVatInput"
                [maxlength]="validators.end_of_bill.base_amount_non_vat.maxLength + validators.end_of_bill.base_amount_non_vat.decimal"
                type="text"
                [ngClass]="{ 'is-invalid': submitted && form.controls['base_amount_non_vat'].errors }"
              >
            </div>
          }
        </div>
      </div>
      <div class="row g-2 justify-content-between">
        <div class="col-12 col-md-5 offset-md-7">
          <div class="form-group d-block d-md-flex align-items-center">
            <div class="form-label form-label-inline mb-2 mb-md-0" for="totalAmountInput">
              {{ 'NEW-TRANSLATE.COMMON.TOTAL-AMOUNT' | translate }}
            </div>
            <input
              currencyMask
              autocomplete="off"
              class="form-control"
              data-testid="totalAmountInput"
              formControlName="total_amount"
              id="totalAmountInput"
              [maxlength]="validators.end_of_bill.total_amount.maxLength + validators.end_of_bill.total_amount.decimal"
              type="text"
              [ngClass]="{ 'is-invalid': submitted && form.controls['total_amount'].errors }"
            >
          </div>
        </div>
      </div>
    </form>
  </div>
}