import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RequireResetPasswordGuard } from '../../core/guards';
import { BulkTaxReportComponent } from './bulk-tax-report.component';

export const routes: Routes = [
  {
    path: 'bulk-tax-report',
    canActivate : [ RequireResetPasswordGuard ],
    component: BulkTaxReportComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BulkTaxReportRoutingModule { }
