import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../service/api.service';
import { TemplateService } from '../../service/template.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CALENDAR_LOCALE } from '../../service/constant';
import moment from 'moment';
import {
  forEach as lodashForEach,
  toString as lodashToString
} from 'lodash';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-tax-b2p',
  templateUrl: './tax-b2p.component.html',
  styleUrl: './tax-b2p.component.scss'
})
export class TaxB2pComponent implements OnInit {

  public itemsPerPage: number = 40;
  public detailItemsPerPage: number  = 40;

  public currentPage: number  = 1;
  public totalItems: number | undefined;
  public detailCurrentPage: number  = 1;
  public detailTotalItems: number | undefined;
  public loading: boolean = false;

  public sortBy: string | null | undefined;
  public sortMode: string | null | undefined;
  public detailSortBy: string | null | undefined;
  public detailSortMode: string | null | undefined;

  public tableData: any[] = [];
  public tableColumn: any[] = [];
  public detailTableData: object[] = [];
  public detailTableColumn: any[] = [];

  public minDate: Date | undefined;
  public maxDate: Date | undefined;
  public error: string | null | undefined;
  public viewing: any;

  public company: object[] = [];
  public supplier: object[] = [];
  public documentType: any[] = [];
  public status: any[] = [];
  public calendarLocale: any;

  public selectedBuyer: number | null = null;
  public selectedCompany: number | null = null;
  public startDate!: Date;
  public endDate!: Date;
  public selectedDocumentType: object | null = null;
  public selectedStatus: string | null = null;
  public selectedDetailDocumentType: object | null = null;
  public selectedDetailStatus: string | null = null;

  @ViewChild('tt') public table: any;
  @ViewChild('ttd') public detailTable: any;

  constructor(
    private apiService: ApiService,
    private templateService: TemplateService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.initialSetting();
  }

  initialSetting(): void {
    this.translateService
      .onLangChange
      .subscribe((event: LangChangeEvent) => {
        this.loadTexts();
        this.loadData();
      });
  }

  loadTexts(): void {
    if (this.translateService.currentLang === 'en') {
      this.calendarLocale = CALENDAR_LOCALE.EN;
    } else {
      this.calendarLocale = CALENDAR_LOCALE.TH;
    }

    this.detailTableColumn = [];
    this.tableColumn = [];
    this.status = [];
    this.translateService.get('COMMON.ALL').subscribe((res: string) => {
      this.populateDropdown();
      this.status?.push(
        { label: this.translateService.instant('COMMON.ALL'), value: null },
        { label: this.translateService.instant('TAX-B2P.ONLY-SUCCESSFUL'), value: 'success_only' },
        { label: this.translateService.instant('TAX-B2P.ONLY-FAILED'), value: 'failed_only' }
      );
      this.tableColumn?.push(
        { field: 'date', header: 'Batch Date' },
        { field: 'buyer', header: this.translateService.instant('TAX-B2P.TABLE.BUYER') },
        { field: 'seller', header: this.translateService.instant('TAX-B2P.TABLE.SELLER') },
        { field: 'doc_count_success', header: this.translateService.instant('TAX-B2P.TABLE.SUCCESSFUL-DOCS') },
        { field: 'doc_count_fail', header: this.translateService.instant('TAX-B2P.TABLE.FAILED-DOCS') },
        { field: 'doc_count_all', header: this.translateService.instant('TAX-B2P.TABLE.ALL-DOCS') },
      );
      this.detailTableColumn?.push(
        { field: 'branch', header: this.translateService.instant('FILTER.BRANCH') },
        { field: 'header_issue_date', header: this.translateService.instant('FILTER.DATE-OF-ISSUE') },
        { field: 'invoice_number', header: this.translateService.instant('TAX-B2P.TABLE.INVOICE-NUMBER') },
        { field: 'doc_type', header: this.translateService.instant('COMMON.CATEGORY') },
        { field: 'message', header: this.translateService.instant('TAX-B2P.TABLE.MESSAGE') },
        { field: 'status', header: this.translateService.instant('TAX-B2P.STATUS') }
      );
      const documentData = this.templateService.getDropdown();
      documentData[0] = {label: this.translateService.instant('COMMON.ALL'), value: null};
      this.documentType = documentData;
    });
  }

  get yearRange() {
    const thisYear = (new Date()).getFullYear();
    return (thisYear - 40) + ':' + thisYear;
  }

  get dateRange(): string {
    const start = moment(this.startDate).format('DD/MM/YYYY');
    const end = moment(this.endDate).format('DD/MM/YYYY');
    return start + ' - ' + end;
  }

  get viewingDateDisplay(): string | null {
    if (this.viewing == null) {
      return null;
    }
    const date = parseInt(this.viewing.doc_date.substr(8, 2), 10);
    const month = this.calendarLocale.monthNames[parseInt(this.viewing.doc_date.substr(5, 2), 10) - 1];
    const year = parseInt(this.viewing.doc_date.substr(0, 4), 10) + 543;
    return date + ' ' + month + ' ' + year;
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  populateDropdown() {
    // populate company list
    this.company = [{
      label: this.translateService.instant('COMMON.ALL'),
      value: null
    }];

    this.apiService
      .getCompany()
      .subscribe({
        next: (res: any) => {
          lodashForEach(res, (obj) => {
            this.company?.push({
              label: obj.name,
              value: obj.id
            });
          });
        }
      });

    // populate supplier list
    this.supplier = [{
      label: this.translateService.instant('COMMON.ALL'),
      value: null
    }];

    this.apiService
      .getBuyer({include_in_b2p: 'True'})
      .subscribe({
        next: (res: any) => {
          lodashForEach(res, (obj) => {
            this.supplier?.push({
              label: obj.name,
              value: obj.id
            });
          });
        }
      });

    // set default date range
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 60);
    this.endDate = new Date();
  }

  loadData(params?: any): void {
    this.loading = true;

    const data: { [key: string]: any }  = {
      company: this.selectedCompany,
      buyer: this.selectedBuyer,
      doc_type: this.selectedDocumentType,
      start_date: moment(this.startDate).format('YYYY-MM-DD'),
      end_date: moment(this.endDate).format('YYYY-MM-DD'),
      status: this.selectedStatus,
      items_per_page: this.itemsPerPage
    };

    // apply sort if any
    if (this.sortBy != null && this.sortMode != null) {
      data['sort'] = this.sortBy;
      data['sort_type'] = this.sortMode;
    }

    // remove page number if current page is 1
    if (this.currentPage === 1) {
      params = null;
    }

    this.apiService
      .postB2PDocumentSummary(data, params)
      .subscribe({
        next: (res: any) => {
          this.totalItems = res.count;
          this.tableData = res.results.map((obj: any) => ({
            ...obj,
            loadingExcel: false
          }));
          this.loading = false;
        },
        error: (err) => {
          this.error = this.translateService.instant('TAX-B2P.ERROR');
          this.loading = false;
        }
      });
  }

  search(): void {
    // check number of days > 60?
    if (moment(this.endDate).diff(moment(this.startDate), 'days') > 60) {
      this.error = this.translateService.instant('FILTER.ERROR.DIFF');
      return;
    }
    this.error = null;

    // separate page
    if (this.viewing == null) {  // summary page
      this.currentPage = 1;
      this.sortBy = null;
      this.sortMode = null;
      if (this.tableData && this.tableData.length > 0) {
        this.table.reset();
      }
      this.loadData();
    } else {  // detail page
      this.detailCurrentPage = 1;
      this.detailSortBy = null;
      this.detailSortMode = null;
      if (this.detailTableData && this.detailTableData.length > 0) {
        this.detailTable.reset();
      }
      this.loadDetailData();
    }
  }

  detailPageChanged(): void {
    const params = new HttpParams().set('page', lodashToString(this.detailCurrentPage));
    this.loadDetailData(params);
  }

  detailSort(event: any): void {
    this.detailSortBy = event.field;
    if (event.order === 1) {
      this.detailSortMode = 'asc';
    } else {
      this.detailSortMode = 'desc';
    }
  }

  loadDetailData(params?: any): void {
    this.loading = true;

    const data: { [key: string]: any } = {
      ...this.viewing,
      doc_type: this.selectedDetailDocumentType,
      status: this.selectedDetailStatus,
      items_per_page: this.detailItemsPerPage
    };

    // apply sort if any
    if (this.detailSortBy != null && this.detailSortMode != null) {
      data['sort'] = this.detailSortBy;
      data['sort_type'] = this.detailSortMode;
    }

    // remove page number if current page is 1
    if (this.detailCurrentPage === 1) {
      params = null;
    }

    this.apiService
      .postB2PDocumentSummaryDetail(data, params)
      .subscribe({
        next: (res: any) => {
          this.detailTotalItems = res.count;
          this.detailTableData = res.results;
          this.loading = false;
        },
        error: (err) => {
          this.error = this.translateService.instant('TAX-B2P.ERROR');
          this.loading = false;
        }
      });
  }

  clear(): void {
    // separate page
    if (this.viewing == null) {  // summary page
      this.selectedCompany = null;
      this.selectedBuyer = null;

      // set default date range
      this.startDate = new Date();
      this.startDate.setDate(this.startDate.getDate() - 60);
      this.endDate = new Date();

      this.selectedDocumentType = null;
      this.selectedStatus = null;
    } else {  // detail page
      this.selectedDetailDocumentType = null;
      this.selectedDetailStatus = null;
    }

    // reload data
    this.search();
  }

  pageChanged(): void {
    const params = new HttpParams().set('page', lodashToString(this.currentPage));
    this.loadData(params);
  }

  sort(event: any): void {
    this.sortBy = event.field;
    if (event.order === 1) {
      this.sortMode = 'asc';
    } else {
      this.sortMode = 'desc';
    }
  }

  downloadExcel(index: any): void {
    this.tableData[index].loadingExcel = true;

    const data = {
      ...this.tableData[index],
      doc_type: this.selectedDocumentType,
      status: this.selectedStatus
    };

    this.apiService
      .downloadB2PDocumentSummaryDetailExcel(data)
      .subscribe({
        next: (res :any) => {
          const file_name = 'documents' + moment().format('YYYY-MM-DD') + '.xlsx';
          this.apiService.createDownloadFile(res, file_name);
          this.tableData[index].loadingExcel = false;
        }
      });
  }

  setMinDate(): void {
    this.minDate = this.startDate;
    if (this.endDate < this.minDate) {
      this.endDate = this.minDate;
    }
  }

  setMaxDate(): void {
    this.maxDate = this.endDate;
    if (this.startDate > this.maxDate) {
      this.startDate = this.maxDate;
    }
  }

  viewDetail(event: any): void {
    // this.detailItemsPerPage = 40;
    // this.detailCurrentPage = 1;
    // this.detailSortBy = null;
    // this.detailSortMode = null;
    // this.detailTableData = [];
    // this.detailTotalItems = undefined;
    this.selectedDetailDocumentType = this.selectedDocumentType;
    this.selectedDetailStatus = this.selectedStatus;
    this.loadDetailData();
  }

  viewSummary(): void {
    // this.detailItemsPerPage = 40;
    this.detailCurrentPage = 1;
    this.detailSortBy = null;
    this.detailSortMode = null;
    this.detailTableData = [];
    this.detailTotalItems = undefined;
    // this.selectedDetailDocumentType = null;
    // this.selectedDetailStatus = null;

    this.viewing = null;
  }

}
