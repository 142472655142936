import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalPages'
})
export class TotalPagesPipe implements PipeTransform {

  transform(value: { limitRow: number, pages: number, collectionSize: number, rowCount: number }): number {
    if (value.limitRow > 0) {
      if ((value.limitRow * value.pages) < value.collectionSize) {
        return value.limitRow * value.pages;
      } else {
        return value.collectionSize;
      }
    } else {
      return value.rowCount;
    }
  }

}
