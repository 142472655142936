import { LookupModel } from "../../shared/interfaces";

export const CHECK_FROM_ACCOUNT: LookupModel[] = [
  {
    id: 1,
    name_th: 'เฉพาะที่ตรวจสอบจากบัญชีแล้ว',
    name_en: 'Verified from account'
  },
  {
    id: 2,
    name_th: 'เฉพาะที่ยังไม่ได้ตรวจสอบ',
    name_en: 'Unverified'
  }
];