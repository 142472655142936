import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingRoutingModule } from './setting-routing.module';
import { SettingComponent } from './setting.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '../../core/core.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { SharedComponentModule } from '../../shared/components/shared-component.module';

import {
  SettingConfigurationComponent,
  SettingReportComponent
} from './components';

@NgModule({
  declarations: [
    SettingComponent,
    SettingConfigurationComponent,
    SettingReportComponent
  ],
  imports: [
    CommonModule,
    SettingRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CoreModule,
    FontAwesomeModule,
    RadioButtonModule,
    DropdownModule,
    SharedComponentModule
  ],
  exports: [
    SettingConfigurationComponent,
    SettingReportComponent
  ]
})
export class SettingModule { }
