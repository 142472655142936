<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{ 'NEW-TRANSLATE.FEE-ENGINE.TITLE' | translate }}</h3>
    </div>
  </div>
</div>

<div class="fee-engine-container">
  <div class="criteria-search-container">
    <app-filter-header
      [loading]="isLoading || isLoadingRows || isLoadingOther"
      (submit)="onSearch()"
      (clear)="onClear()"
    />
    <div class="criteria-search-wrapper">
      <form [formGroup]="form" class="form-wrapper">
        <div class="row g-2">
          <div class="col-12 col-md-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['corporate_id'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="corporate_id"
                selectId="companySelect"
                [options]="companies"
                optionValue="id"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [placeholder]="'NEW-TRANSLATE.COMMON.COMPANY' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="companySelect">{{ 'NEW-TRANSLATE.COMMON.COMPANY' | translate }}</label>
            </div>
          </div>
          <div class="col-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['month'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="month"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [options]="months"
                optionValue="id"
                selectId="monthSelect"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                </ng-template>
              </p-dropdown>
              <label for="monthSelect">{{ 'NEW-TRANSLATE.COMMON.MONTH' | translate }}</label>
            </div>
          </div>
          <div class="col-6 col-md-4">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submitted && form.controls['year'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="year"
                selectId="yearSelect"
                [options]="years"
                [loading]="isLoading || isLoadingRows || isLoadingOther"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
              >
              </p-dropdown>
              <label for="yearSelect">{{ 'NEW-TRANSLATE.COMMON.YEAR' | translate }}</label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="datatables-container">
    <app-custom-pagination
      [(currentPage)]="pages"
      [(itemsPerPage)]="limitRow"
      [totalItems]="collectionSize"
      [isLoading]="isLoading || isLoadingRows || isLoadingOther"
      (pageChange)="pageChanged()"
      (optionChangeValue)="limitRowChanged($event)"
    >
      @if (isViewerOperation()) {
        <div class="col-auto d-flex gap-2 mt-3 mt-lg-0 align-items-center flex-wrap">
          <button
            class="btn btn-dv btn-wide"
            data-testid="downloadAllCsvButton"
            [disabled]="!rows || rows.length === 0 || isLoadingRows || isLoadingOther || isLoading"
            id="downloadAllCsvButton"
            type="button"
            [title]="'NEW-TRANSLATE.COMMON.ALL' | translate"
            (click)="onDownloadAllCsv()"
          >
            {{ 'NEW-TRANSLATE.COMMON.ALL' | translate }}
          </button>
          <button
            class="btn btn-dv btn-wide"
            data-testid="downloadCsvButton"
            [disabled]="!rows || rows.length === 0 || isLoadingRows || isLoadingOther || isLoading"
            id="downloadCsvButton"
            type="button"
            [title]="'NEW-TRANSLATE.DOWnLOAD.DOWNLOAD' | translate"
            (click)="onDownloadCsv()"
          >
            {{ 'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD' | translate }}
          </button>
        </div>
      }
    </app-custom-pagination>
    <app-datatables
      #datatables
      [collectionSize]="collectionSize"
      [columns]="columns"
      [isLoadingRows]="isLoadingRows"
      [isLoadingOther]="isLoadingOther"
      [limitRow]="limitRow"
      [pages]="pages"
      [rows]="rows"
      [(selectedRows)]="selectedRows"
      tableId="feeEngineDatatable"
    />
  </div>
</div>
