<div
  [id]="modalID ? modalID : 'previewPdfModal'"
  [attr.data-testid]="modalID ? modalID : 'previewPdfModal'"
>
  <div class="modal-header">
    <h4>{{ 'NEW-TRANSLATE.COMMON.PDF-PREVIEW' | translate }}</h4>
    <h6>{{ 'NEW-TRANSLATE.KEYIN.PLEASE-RECHECK-YOUR-DATA-THAT-ALL-CORRECT-ARE-YOU-SURE-TO-CREATE-THIS-DOCUMENT' | translate }}</h6>
  </div>
  <div class="modal-body">
    <div class="pdf-container">
      <div class="pdf-wrapper">
        <ngx-extended-pdf-viewer
          *ngIf="pdfSrc"
          [src]="pdfSrc"
          [height]="'100%'"
          [showToolbar]="false"
          [showBorders]="true"
          [textLayer]="renderText"
          [enableDragAndDrop]="false"
          [handTool]="false"
          [logLevel]="0"
          backgroundColor="transparent"
        ></ngx-extended-pdf-viewer>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="btn btn-dv btn-clean btn-wide m-r-5"
      data-testid="backModalButton"
      id="backModalButton"
      (click)="onDismiss()"
      [disabled]="isLoading"
      [title]="'NEW-TRANSLATE.COMMON.BACK' | translate"
    >
      <fa-icon [icon]="['fas', 'chevron-left']" class="m-r-5"></fa-icon>
      {{ 'NEW-TRANSLATE.COMMON.BACK' | translate }}
    </button>
    <button
      class="btn btn-dv btn-wide"
      data-testid="confirmModalButton"
      id="confirmModalButton"
      [disabled]="isLoading"
      (click)="onConfirm()"
      [title]="'NEW-TRANSLATE.COMMON.CONFIRM' | translate"
    >
      {{ 'NEW-TRANSLATE.COMMON.CONFIRM' | translate }}
    </button>
  </div>
</div>
  